import React, { Component, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, FlatList, View, Platform,
    Image, Dimensions, TouchableOpacity, platform } from 'react-native'
import { Text, Card, List, ListItem, 
  CheckBox,
  Button, Divider,IndexPath, Icon, Drawer, DrawerGroup, DrawerItem, Spinner } from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../../../utils/color';
import Label from '../../../components/Label';
import MobileHeader from '../../../components/MobileHeader';
import MenuSettings from '../../../components/MenuSettings';
import SideMenu from '../../../components/SideMenu';
import currencyJson from '../../../data/currency.json';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Iframe from 'react-iframe';
import { Analytics } from '../../../components/Analytics';
import { getProfile, updateProfile, createProfile } from '../../../services/Profile';
import { getCategories, getFeature, sendSubscription } from '../../../services/Feature';
import CryptoJS from 'crypto-js';
import { encode } from 'url-safe-base64';
import { Constants } from '../../../utils/constants';

const genderList = ['Male', 'FeMale', 'TransGender'];
const genderMap = {'M': 0, 'F': 1, 'T': 2};

const CalendarIcon = (props) => (
    <Icon {...props} name='calendar'/>
);


let baseUrl = '';
baseUrl = Constants.CUSTOMER_URL

let cartedItems = {};
export default class PurchaseScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isProfile: false,
            isProfileLoading: true,
            isError: false,
            profile: {},
            org_id:'',
            amount: '',
            currency: '',
            url: baseUrl,
            cartUrl: '',
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            isSubmitted: false,
            openFile : false,
            organization_id: null,
            myWidth: Dimensions.get("window").width - 150,
            originalWidth: Dimensions.get("window").width,
            fullName:null,
            userToken:'',
            email:null,
            organization_name: null,
        }
    }
    async componentWillMount() {

        const fullName = await AsyncStorage.getItem('fullName');
        const organization_id = await AsyncStorage.getItem('organizationID');
        const organization_name = await AsyncStorage.getItem('organizationName');
        const userToken = await AsyncStorage.getItem('userToken');
        const email = await AsyncStorage.getItem('email');
        this.setState({
            email,
            fullName,
            organization_id,
            org_id: organization_id,
            organization_name,
            userToken
        })

        const data = await getProfile({org_id: organization_id});
        if(data.isError) {
            this.setState({profile: {}, isProfile: false, isProfileLoading: false, isError: true});
        } else { 
            this.setState({profile: data.profile, isProfile: true, isProfileLoading: false});    
        }      
    }

    submitData =  async (amount, currency, country) => {
        const products = [];
        Object.keys(cartedItems).map((item) => {
            products.push({
                product_id: item
            })
        });
        if (amount > 0) {
            const data = {amount, currency, 
                org_id: this.state.organization_id, 
                name: this.state.fullName, 
                org_name: this.state.organization_name,
                email:this.state.email,
                customer_id: this.state.profile.stripe_customer_id,
                products
            };
            const enc_string = CryptoJS.AES.encrypt(JSON.stringify(data), "!no").toString();
            let cartUrl = this.state.url;
            cartUrl += `/org/pay/${encode(enc_string)}`;
            if(Platform.OS === 'web') {
                await AsyncStorage.setItem('cartedItems', JSON.stringify(cartedItems));
                await AsyncStorage.setItem('isPendingSubscription', 'true');
                window.location.href = cartUrl;
            } else {
                this.setState({cartUrl}); 
            }
        }
    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Profile" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Purchase</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../../assets/images/small_down.png")} />
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        /> 
                        { this.state.isSubmitted === false && 
                            <ScrollView>
                             <View style={{flexDirection:'row'}}>    
                             <View style={{flexDirection:'row', margin: 30, width:'60%'}}>
                             {this.state.isError === true && 
                                <Card><Label category='p1' status='danger'>
                                    There is a problem with profile Information.  
                                </Label></Card>
                            }
                              {this.state.isProfileLoading === true && <Spinner size={'30'} status={'danger'} ></Spinner> }
                              {this.state.userToken.length > 0 && this.state.isProfileLoading === false && 
                                <CartScreen  
                                  navigation={this.props.navigation}
                                  org_id={this.state.organization_id}
                                  isProfile={this.state.isProfile}
                                  profile={this.state.profile}
                                  submitData={this.submitData}
                                  userToken={this.state.userToken} />}
                              </View>
                              {this.state.cartUrl !== '' && <View>
                                <Card style={{ margin: 40, width: 800, height:400}}>
                                    <Iframe url={this.state.cartUrl} height={'350'} frameBorder={0} />
                                </Card>
                              </View>}
                              </View>
                            </ScrollView> 
                        }
                    </View>
                </View>
            </View >
        )
    }
}

PurchaseScreen.navigationOptions = {
    header: null,
};


const CartScreen = (props) => {
  
  const [categories, setCategory] = useState([]);
  const [features, setFeatures] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [amount, setAmount] = useState(0);
  
  useEffect(() => {
    (async () => {
      const cat = await getCategories(props.userToken);
      const fat = await getFeature(props.userToken, '*', true);
      /*
      fat.map((item) => {
        item.download_limits = 10000;
        item.limit_increment = 50;
      });
      */
      setCategory(cat);
      setFeatures(fat);
      completeSubscription(fat);
    })();
   },[]);
  
  const completeSubscription = async (fat) => {
    
    const url = window.location.href;
    if(url.includes('=false') === true) {
    await AsyncStorage.removeItem('cartedItems');
    await AsyncStorage.removeItem('isPendingSubscription');
    }
    let carts = await AsyncStorage.getItem('cartedItems');
    carts = JSON.parse(carts) || [];
    const isPendingSubscription = await AsyncStorage.getItem('isPendingSubscription');
    if(Object.keys(carts).length > 0 && isPendingSubscription) {
        Object.keys(carts).map(async (fa) => {
            const feature = queryFeature(fat, fa);
            if(feature) {
                await sendSubscription({
                    org_id: props.org_id,
                    feature_id: feature.id,
                    tr_price: feature.price,
                    tr_country_code: feature.country_code,
                    tr_currency_code: feature.currency_code
                });
            }
        });

        await AsyncStorage.removeItem('cartedItems');
        await AsyncStorage.removeItem('isPendingSubscription');
        props.navigation.navigate("Engage");
    }
  }

  const infoT = (obj) => {
    let amnt = 0
    cartedItems = {...cartedItems, ...obj};
    Object.keys(cartedItems).map(item => amnt += Number(cartedItems[item].price));
    setAmount(amnt && amnt.toFixed(2));
  }
  
  const SmartphoneIcon = (props) => (
    <Icon {...props} name='smartphone-outline'/>
  );
  
  const BrowserIcon = (props) => (
    <Icon {...props} name='browser-outline'/>
  );
  
  const ColorPaletteIcon = (props) => (
    <Icon {...props} name='color-palette-outline'/>
  );
  
  const StarIcon = (props) => (
    <Icon {...props} name='star'/>
  );

  const CheckboxPrice = (props) => {
  const [checked, setChecked] = useState(cartedItems[props.stripe_product_id] ? true : false);
    const setUp = (status) => {
        let tot = {};
        if(status === true) {
            tot[`${props.stripe_product_id}`] = { 
                price: (cartedItems[props.stripe_product_id] && cartedItems[props.stripe_product_id].price) || props.price, 
                limit: (cartedItems[props.stripe_product_id] && cartedItems[props.stripe_product_id].limit) || props.limit 
            }
            props.infoT(tot);
        } else if(status === false) {
            delete cartedItems[props.stripe_product_id];
            props.infoT(tot);
        }
        setChecked(status);
    }    
    
    return (
      <CheckBox
        checked={ checked === true ? true : false }
        onChange={nextChecked => setUp(nextChecked)}>
      </CheckBox>
    );
  };

  const TextPrice = (props) => {

      const cart = cartedItems[props.stripe_product_id];  
      const [limit, setLimit] = useState((cart && cart.limit) || props.limit);
      const [price, setPrice] = useState((cart && cart.price) || props.price);
      const [change, setChange] = useState(false);

      useEffect(() => {
        if(change === true) {
            props.infoT({
                [props.stripe_product_id] : { price: props.price * (limit / props.limit), limit }
            });
            setChange(false);
        }
      }, [change]);
      
      useEffect(() => {
        let pe = price * (limit / props.limit);
        setPrice(pe);
      }, [limit]);

      return (
        <>
        <Text style={{fontWeight:'600', fontSize: 13, paddingRight: 100}}>
          { props.price * (limit / props.limit) + ' ' + props.currency}
        </Text>

        { props.limit > 0 && 
            <>
            <Button 
            appearance='ghost'
            size={'small'}
            onPress={() => {
                    setLimit(limit === props.limit_increment ? limit : limit - props.limit_increment );
                    setChange(true); 
                }
            }
            >-</Button>
            <Text style={{fontWeight:'600', fontSize: 13, 
                textAlign:'center', alignItems:'center'}}>
            {limit}
            </Text>
            <Button 
            appearance='ghost'
            onPress={() =>  { 
                setLimit(limit + props.limit_increment);
                setChange(true); 
                }
            }
            size={'small'}>+</Button>
            </>
        }
        </>
      );
    };

  const TFooter = (props) => {
    const cartedItems = props.price + ' ' + props.currency;
    return (
        <>
        { props.isProfile === true && 
        <View style={{marginTop: 15, paddingRight: 30, flexDirection:'row', justifyContent:'flex-end'}}>
            <View>
                <Text style={{marginRight: 3, color:'red', fontWeight: 600}}>Total</Text>
            </View>
            <View>
                <Text style={{ color:'red', fontWeight: 600, marginLeft: 3}}>{cartedItems}</Text>
            </View>
        </View> }
        <View style={{marginTop: 15, alignItems:'center', width:'100%'}}>
            { props.isProfile === true && <Button style={{width:100, alignSelf:'center', padding: 10, margin:20}} 
                onPress={() => props.submitData(amount, props.currency, props.country)}>Checkout</Button> }
            { props.isProfile === false && <Text> Please complete the profile first </Text> }
        </View>
        </>
    )
  }

  const queryFeature = (fat, stripe_product_id) => {
        const fea = fat.filter((el) => el.stripe_product_id == stripe_product_id);
        return fea && fea[0];
  }

  
  return (
    <>
    { features.length === 0 && 
        categories.length === 0 && 
            <Card>
                <Spinner size={'30'} status={'primary'} ></Spinner>     
            </Card>
            }
    { features.length > 0 && 
        categories.length > 0 && <Card style={{width: '100%'}} 
    header={<View><Label Barlow_SemiBold style={{textAlign:'center'}}>Cart</Label></View>}
    footer={<TFooter price={amount} 
        isProfile={props.isProfile}
        country={props.profile && props.profile.country} 
        currency={currencyJson[props.profile && props.profile.country]} 
        submitData={props.submitData} />}>
    {
      categories.length > 0 && categories.filter(item => item.name.trim() !== 'Tools').map((category) => {
        return (
          <Drawer>
              <DrawerGroup title={category.name} accessoryLeft={SmartphoneIcon}>
                {
                  features.filter(function(item) {
                        return item.category_id === category.id && item.type === 'paid'
                    }).map(feature => {
                    return (
                      <DrawerItem title={feature.name} 
                        descriptor={feature.description}
                        accessoryLeft={<CheckboxPrice 
                                feature_id={feature.id} 
                                stripe_product_id={feature.stripe_product_id}
                                infoT={infoT} 
                                limit={feature.download_limits ? feature.download_limits : 1000}
                                limit_increment={feature.limit_increment ? feature.limit_increment : 200}
                                price={feature.price} />} 
                        accessoryRight={<TextPrice 
                            profile={props.profile} 
                            stripe_product_id={feature.stripe_product_id}
                            price={feature.price} 
                            limit={feature.download_limits ? feature.download_limits : 1000}
                            limit_increment={feature.limit_increment ? feature.limit_increment : 200}
                            infoT={infoT}
                            currency={feature.currency_code}
                        /> } />
                    ) 
                  })
                }
                
              </DrawerGroup>
          </Drawer>
        )
      })
    }
    </Card> }
    </>
  )
}

const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
