import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, FlatList, Text, View,  
    Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
    import AsyncStorage from '@react-native-async-storage/async-storage'    
    import {Picker} from '@react-native-picker/picker';    
import TextBox from './../components/textbox';
import { Color } from './../utils/color';
import MobileHeader from './../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from './../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../components/Label';
import { ScrollView } from 'react-native-gesture-handler';
import Button from './../components/button';
import { getProduct, getCategories, getSubCategories, updateProduct } from '../services/ProductService';
import { Analytics } from '../components/Analytics';
var moment = require('moment');

export default class ProductDetailScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            categories: [],
            category_id:null,
            subCategories : [],
            content : {},
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null
        }
    }
    async componentWillMount() {
        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName
        })

        const { cxs_product_id, organization_id} = this.props.navigation.state.params; 
        getProduct(organization_id, cxs_product_id).then(content => this.setState({content}))
        getCategories().then(categories => this.setState({categories}))
    }

    setUpdateProduct = () => {
           this.state.content.category = 1;
           this.state.content.sub_category = 2;
           const {organization_id} = this.props.navigation.state.params;
           this.state.content['organization_id'] = organization_id;
           updateProduct(this.state.content).then(arg => this.props.navigation.navigate("Product")) 
    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    handleText = (item, value) => {
        var content = this.state.content;
        content[item] = value;
        this.setState({
            content
        })
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Product" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Product</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <View>
                            <View>
                            <Text style={{ padding:10, fontSize: 30, color: Color.DarkGreen}}> Product Details </Text>
                            </View>
                            {Object.keys(this.state.content).map((item, key) =>             
                            <View style={{flexDirection:'row'}}>   
                            <View style={{paddingLeft:20, width:'13%'}}>
                                <Text style={{ paddingTop:15, 
                                    paddingBottom:5, 
                                    fontSize: 14, 
                                    color: '#000', 
                                    fontWeight:'600'}}> {item} </Text>
                            </View>
                            <View style={{ paddingTop:10, 
                                    paddingBottom:5, width:'60%'}}>    
                                <TextBox 
                                placeholder="Search" 
                                name={item}
                                onChangeText={(value) => this.handleText(item, value)} 
                                value={this.state.content[item]}/>
                            </View>
                            </View>
                            )}
                            {/*
                            <View style={{paddingLeft:20, width:'14%'}}>
                                <Text style={{ paddingTop:20, paddingBottom:10, fontSize: 12, color: Color.DarkGreen}}> Category </Text>
                                <Picker
                                    style={styles.textDropDownStyle}
                                    selectedValue={this.state.category_id}
                                    onValueChange={(itemValue, itemIndex) =>
                                        this.setState({category_id: itemValue})
                                    }>
                                    { this.state.categories.map((item, key) => {
                                        <Picker.Item label={item.category_name} value={item.cateogry_id} />
                                    })}
                                </Picker>    
                            </View>
                                */}

                    <View style={{ margin:20}}> 
                    <Button btnStyle={{ width: 120 }} onClick={() => this.setUpdateProduct() }
                            backgroundColor={Color.DarkGreen}
                            titleColor={Color.White}
                            title="Update">
                            </Button>
                    </View>
                        </View>                
                    </View>
                </View>
            </View >
        )
    }
}

ProductDetailScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    textDropDownStyle: {
        borderWidth: 1,
        borderColor: '#DEDEDE',
        width: '100%',
        borderRadius: 5,
        fontSize: 14,
        padding: 12,
        marginBottom: 12,
        fontFamily: 'Barlow-Regular',
        outline: "none"
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
        overflow:'scroll'
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);