// Server restart is required when data is added or changed in the .env file,
// the babel-loader version used doesn't update on changes made to .env files.
// Run 'yarn start' after making changes to .env file

// Newer version of babel-loader can be used for reloading on .env changes without server restart, but it takes way too long 
// to reload and to bundle the javascript for web on every little change made anywhere through out the code, increasing time 
// for development and debugging.

// 'rm -rf .expo/web/cache' is added to package.json to execute on yarn start.
// Run command 'rm -rf .expo/web/cache' in terminal if .env changes don't get reflected.

import {API_URL} from 'react-native-dotenv';

// const devEnvVars = {
//     TEST_API_DEV
// }

// const prodEnvVars = {
//     TEST_API_PROD
// }

// export default __DEV__? devEnvVars : prodEnvVars

export default {API_URL};