import React, {useState, useEffect} from 'react'
import { StyleSheet, FlatList, View,ScrollView,
    Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import { ActivityIndicator, DataTable } from 'react-native-paper';
import MobileHeader from '../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from '../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import { Select, SelectItem, Input, Button, Card, Text, Autocomplete, AutocompleteItem, CheckBox } from '@ui-kitten/components';
import InputDate from './../components/Input.web';
import Label from './../components/Label';
import CountryArrayJSON from '../data/country.json';
import countryJson from '../data/countryNames.json';
import countryIsoJson from '../data/countryIso.json';
import stateJson from '../data/states.json'
import yearsJson from '../data/years.json'
import languageJson from '../data/language.json';
import {postData} from '../services/RetailData';
import { Analytics } from '../components/Analytics';
import Iframe from 'react-iframe'
import DateTimePicker from "react-native-modal-datetime-picker";
import { getCitiesJson } from '../services/CitiesData';
import { Constants } from '../utils/constants';

var moment = require('moment');

const  sortByValue = (jsObj) => {
    let object = {};
    for(var i in jsObj) {
        object[jsObj[i]] = countryIsoJson[i];
    }
    return object;
}

const searchJson = ['Country', 'Region', 'City'];
const sortedCountryJSON = sortByValue(countryJson);
var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// const currentYear = (new Date()).getFullYear();
// const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
// let yearsList = range(currentYear, currentYear - 50, -1)
// console.log(yearsList.map(String))

export default class RetailCxScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            openFile : false,
            isReport: true,
            super_set_id:'',
            authCode:'',
            isSuperSet: false,
            organization_id: null,
            isGetData: false,
            download_limit: 0,
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null,
            geo_country: '',
            geo_state: '',
            regionCountry:'',
            stateCountry:'',
            cityCountry: '',
            country: '',
            states: [],
            state: '',
            cities: [],
            city: '',
            productCategories: ["Furniture", "Office Supply", "Technology" ],
            selectedProducts: [],
            monthSearch: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            month: '',
            years: yearsJson,
            yearSearch: yearsJson, 
            year: '',
            isFurniture: false,
            isOfficeSupplies: false,
            isTechnology: false,
            stores: ['Superstore 1', 'Superstore 2', 'Superstore 3'],
            store:'',
            citySearchByStateArray: [], //to prevent "erasure" of cities during search, due to manipulation of original json unlike countryJSON
            stateListByCountry: [], //to prevent "erasure" of states during search, due to manipulation of original json unlike countryJSON
            languageIso: 'English',
            resolution: 'Country',
            keyword: '',
            showConfirmation: false,
            queryCountry: CountryArrayJSON,
            queryState: stateJson,
            citiesLoaded: false,
            queryCity: [],
            queryLanguage: languageJson,
            startDate: "",
            endDate: "",
            isDateTimePickerVisible: false, 
            isDateTimePickerVisibleEnd: false,
            submitClicked: false
        }
    }


    async componentWillMount() {
        let stateCityArr=this.state.queryCity.filter(x=>x.state_name=='Texas')
        let citiesArray = stateCityArr.map(x=>x.name)

        const fullName = await AsyncStorage.getItem('fullName');
        const organization_id = await AsyncStorage.getItem('organizationID');
        const authCode = await AsyncStorage.getItem('authCode');
        const userToken = await AsyncStorage.getItem('userToken');
        const citiesQuery = await getCitiesJson();
        this.setState({
            fullName,
            organization_id,
            authCode,
            userToken,
        })

        if(citiesQuery){
            this.setState({citiesLoaded: true, queryCity: citiesQuery, state: ''})
        }
    }

    // async componentDidUpdate(prevProps, prevState){
    //     let statesArray
    //     if(this.state.country !== prevState.country){
    //         if(this.state.country !== ''){
    //             statesArray = this.state.queryState.find(x=>x.country==this.state.country).states                 
    //         } else return 

    //         this.setState({states: statesArray})
    //     }


    //     // if(prevState.states == this.state.states){
    //     //     this.setState({states: statesArray})
    //     // } else return

    //     // console.log('selected country states!!', this.state.states)

    // }

    // async componentDidUpdate(){
    //     console.log('Furniture',this.state.isFurniture)
    //     console.log('Office',this.state.isOfficeSupplies)
    //     console.log('Tech',this.state.isTechnology)

    //     console.log('selectedProducts',this.state.selectedProducts)
    // }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    openSystem = () => {
        this.setState({
            openFile:true
        })
    }

    
    onChangeTextCountry = (query) => {
        this.setState({
            queryCountry: CountryArrayJSON.filter(item => this.filter(item, query)),
            country: query,
            // state: ''
        })
    };

    onChangeTextState = (query) => {
        this.setState({
            states: this.state.stateListByCountry.filter(item => this.filter(item, query)),
            state: query,
            // city: ''
        })
    };

    onChangeTextCity = (query) => {
        this.setState({
            cities: this.state.citySearchByStateArray.filter(item=>this.filter(item, query)),
            city: query
        })

    }

    onChangeTextStore = (query) => {
        this.setState({
            cities: this.state.stores.filter(item=>this.filter(item, query)),
            city: query
        })

    }

    onChangeTextMonth = (query) => {
        this.setState({
            monthSearch: months.filter(item=>this.filter(item, query)),
            month: query
        })

    }

    onChangeTextYear = (query) => {
        this.setState({
            yearSearch: this.state.years.filter(item=>this.filter(item, query)),
            year: query
        })

    }

    // onChangeTextProduct = (query) => {
    //     this.setState({
    //         // cities: this.state.citySearchByStateArray.filter(item=>this.filter(item, query)),
    //         selectedProducts: selectedProducts.push[query]
    //     })

    // }


    renderOptionCountry = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };

    filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());

    onSelectCountry = (index) => {
        let country = this.state.queryCountry[index]
        let stateArr = []
        this.state.queryState.map(state=>{
            if(state.country_name == country){
                stateArr.push(state.name)
            } else return
        }
        )
        this.setState({
            country: country,
            states: stateArr,
            state: '',
            city: '',
            stateListByCountry: stateArr
        })

    };

    onSelectState = (index) => {
        let selectedState = this.state.states[index]
        let stateCityArr=this.state.queryCity.filter(x=>x.state_name==selectedState)
        let citiesArray = stateCityArr.map(x=>x.name)

        this.setState({
            state: this.state.states[index],
            cities: citiesArray,
            city: '',
            citySearchByStateArray: citiesArray
        })
    };

    onSelectCity=(index)=>{
        this.setState({
            city: this.state.cities[index]
        })
    }

    onSelectStore=(index)=>{
        this.setState({
            store: this.state.stores[index]
        })
    }

    onSelectProducts=(index)=>{
        this.setState({
            selectedProducts: this.state.productCategories[index]
        })
    }
    
    onSelectMonth=(index)=>{
        this.setState({
            month: months[index]
        })
    }

    onSelectYear=(index)=>{
        this.setState({
            year: this.state.years[index]
        })
    }

    renderOptionState = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };

    renderOptionCity = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };

    renderOptionStore = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };

    renderOptionYear = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };



    renderOptionMonth = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };


    showDateTimePicker = () => {
        this.setState({ isDateTimePickerVisible: true });
    };

    showDateTimePickerEnd = () => {
        this.setState({ isDateTimePickerVisibleEnd: true });
    };

    hideDateTimePicker = () => {
        this.setState({ isDateTimePickerVisible: false, isDateTimePickerVisibleEnd: false });
    };

    handleDatePickedStart = date => {
        this.setState({
            startDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    };

    handleDatePickedEnd = date => {
        this.setState({
            endDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    };

    setSuperSetId = (super_set_id) => {
        this.setState({super_set_id, isSuperSet: true, isReport: undefined});
    }
    
    resetReport = (status) => {
        this.setState({isReport: status});
    }

    submitData = async () => {
        //validation to make sure we have all the values
        if(!this.state.country || !this.state.state || !this.state.city || !this.state.store || this.state.selectedProducts == 0 || !this.state.year || !this.state.month){
           return
        }

        const response = await (await postData(this.state)).json();


        if(response.message === 'success') {
            // if( parseInt(this.state.download_limit) > 0 ) {
            // const Sresponse = await (await scraperData(this.state, response.search_id)).json();
            // }
            this.setState({showConfirmation: true});
        }
    }

    // API ::: kabir cassendra system integrated with this
    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Retail Pricing" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Retail Pricing</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />   
                        <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.props.navigation.navigate("Engage")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                            
                            <View style={{ alignContent:'center', alignItems:'center', justifyContent:'space-between'}}>
                            {
                            <View>
                                { <Card
                                disabled 
                                header={
                                    <View style={{flexDirection: 'row'}}>
                                    <Text category='h5'>Provide Information
                                    <Button
                                    style={{marginLeft: 20}}
                                    status={'success'}
                                    size={'tiny'} 
                                    onPress={() => this.props.navigation.navigate("Engage")}>Back</Button>
                                    </Text>
                                </View>
                                } 
                                style={{width: '50vw'}}
                                footer={<View>
                                    <Button 
                                    style={{width:100, alignSelf: 'center'}} onPress={()=>[this.submitData(), this.setState({submitClicked: true})]}>submit</Button></View>}>    
                                    
                                    
                                <View style={{flexDirection: 'column'}}>
                                    
                                    {/* Country */}
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Country</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10)
                                        }}>
                                        <Autocomplete
                                            placeholder='Select Country'
                                            value={this.state.country}
                                            onSelect={this.onSelectCountry}
                                            onChangeText={this.onChangeTextCountry}
                                            >
                                            {this.state.queryCountry.map(this.renderOptionCountry)}
                                        </Autocomplete>
                                        {this.state.submitClicked && !this.state.country && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}
                                        </View>
                                    </View>

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>State</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                        <Autocomplete
                                            placeholder='State'
                                            value={this.state.state}
                                            onSelect={this.onSelectState}
                                            onChangeText={this.onChangeTextState}>    
                                            {this.state.states.map(this.renderOptionState)}
                                        </Autocomplete>
                                        {this.state.submitClicked && !this.state.state && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}
                                        {/* <Text>{this.state.queryState.find(x=>{return x.country=="Afghanistan"}).states}</Text> */}
                                        </View>
                                    </View>
                                    </View>

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                        }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>City</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        <Autocomplete
                                            placeholder='City'
                                            value={this.state.city}
                                            onSelect={index => this.onSelectCity(index)}
                                            onChangeText={this.onChangeTextCity}>    
                                            {this.state.citiesLoaded ? this.state.cities.map(this.renderOptionCity) : <View style={{height: 50, alignItems: 'center', justifyContent:'center'}}><ActivityIndicator/></View>}    
                                        </Autocomplete>
                                        {this.state.submitClicked && !this.state.city && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}

                                        </View>
                                    </View>
                                    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Store Name</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                             {/* <Input
                                                placeholder='Superstore'
                                                value={this.state.keyword}
                                                onChangeText={keyword => this.setState({keyword})}
                                                /> */}

                                            <Autocomplete
                                                placeholder='Store Name'
                                                value={this.state.store}
                                                onSelect={index => this.onSelectStore(index)}>
                                                {this.state.stores.map(this.renderOptionStore)}    
                                            </Autocomplete>
                                            {this.state.submitClicked && !this.state.store && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}
                                            </View>
                                    </View>

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Product Category</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                             <Autocomplete
                                                placeholder='Product(s)'
                                                value={this.state.selectedProducts.map(x=>{return x})}
                                                // onSelect={index => this.onSelectProducts(index)}
                                                // onChangeText={this.onChangeTextProduct}
                                                cursorColor={'white'}
                                                >    
                                                {/* {this.state.productCategories.map(this.renderOptionProduct)}     */}
                                                <AutocompleteItem style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                                                    <CheckBox
                                                    style={{margin: 5}}
                                                    checked={this.state.isFurniture}
                                                    onChange={check => [this.setState({isFurniture: check}), this.state.isFurniture == false ? this.state.selectedProducts.push('Furniture') :  this.setState({selectedProducts : this.state.selectedProducts.filter(e => e !== 'Furniture')})]} //if true(checked) then remove
                                                    >Furniture</CheckBox>
                                                     <CheckBox
                                                     style={{margin: 5}}
                                                    checked={this.state.isOfficeSupplies}
                                                    onChange={b => [this.setState({isOfficeSupplies: b}), this.state.isOfficeSupplies == false ? this.state.selectedProducts.push('Office Supplies') : this.setState({selectedProducts : this.state.selectedProducts.filter(e => e !== 'Office Supplies')})]}
                                                    >Office Supplies</CheckBox>
                                                     <CheckBox
                                                     style={{margin: 5}}
                                                    checked={this.state.isTechnology}
                                                    onChange={e => [this.setState({isTechnology: e}), this.state.isTechnology == false ? this.state.selectedProducts.push('Technology') : this.setState({selectedProducts : this.state.selectedProducts.filter(e => e !== 'Technology')})]}
                                                    >Technology</CheckBox>
                                                </AutocompleteItem>
                                            </Autocomplete>
                                            {this.state.submitClicked && this.state.selectedProducts.length == 0 && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}
                                        </View>
                                    </View>

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Year</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                             <Autocomplete
                                                placeholder='Year'
                                                value={this.state.year}
                                                onSelect={index => this.onSelectYear(index)}
                                                onChangeText={this.onChangeTextYear}
                                                >    
                                                {this.state.yearSearch.map(this.renderOptionYear)}    
                                            </Autocomplete>
                                            {this.state.submitClicked && !this.state.year && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}
                                        </View>
                                    </View>

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Month</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'column'),
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                             <Autocomplete
                                                placeholder='Month'
                                                value={this.state.month}
                                                onSelect={index => this.onSelectMonth(index)}
                                                onChangeText={this.onChangeTextMonth}>    
                                                {this.state.monthSearch.map(this.renderOptionStore)}    
                                            </Autocomplete>
                                            {this.state.submitClicked && !this.state.month && <Text style={{color: 'red', fontSize: 10, alignSelf: 'flex-start', marginTop: 5}}>This field is required</Text>}
                                        </View>
                                    </View>
                                    
                                    {/* { false && <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Option</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                            <CheckBox
                                                checked={this.state.isGetData}
                                                onChange={nextChecked => this.setState({isGetData: nextChecked})}>
                                                Do You want to Set the Limit?
                                            </CheckBox>
                                        </View>
                                    </View> } */}

                                    {this.state.isGetData === true && <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Download Limit</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                             <Input
                                                placeholder='Size'
                                                value={this.state.download_limit}
                                                onChangeText={download_limit => this.setState({download_limit})}
                                                />
                                        </View>
                                    </View>}
                                </Card> }
                                { this.state.showConfirmation === true && <Card
                                disabled 
                                header={<Text category='h5'>Request Submitted Successfully</Text>} 
                                style={{ marginTop: 40, width: '100%'}}
                                footer={<View><Button style={{width:100}} onPress={() => this.setState({showConfirmation: false})}>Go Back</Button></View>}>    
                                </Card> }   
                            </View>}  
                            { this.state.isSuperSet === true && 
                            <ScrollView scrollEnabled={false} style={base.universalScrollView}>
                            {/* This View displays the Superset Dashboard */}
                            <View style={{alignItems: 'center', height: "100%"}}>
                            {/* <Text>{`https://analytics.cxsphere.com/login` + "?auth_code=" + this.props.authCode + "&redirect=" + `https://analytics.cxsphere.com/superset/dashboard/66/?standalone=3&org_id=${this.props.org_id}&search_id=${this.props.super_set_id}`}</Text> */}
                            <Card
                            style={{maxHeight: '85vh', minHeight: '85vh', width: '100%', alignSelf: 'center', alignContent: 'center'}} 
                            disabled //clickable
                            header={<View style={{flexDirection:'row'}}>
                                <Text category='h6'>SuperSet View
                                <Button
                                style={{marginLeft: 30}}
                                status={'success'}
                                size={'tiny'} 
                                onPress={() => { this.resetReport(true); this.setState({isSuperSet: false})}}>Back</Button>
                                </Text>
                                </View>}  
                            >

                                <ReportSuperSetView 
                                    authCode={this.state.authCode} 
                                    super_set_id={this.state.super_set_id} 
                                    {...this.props} 
                                    {...this.state} 
                                    org_id={this.state.organization_id} />

                            </Card>
                            </View> 
                        </ScrollView>}
                            </View>
                                            
        </View>
                </View>
            </View >
        )
    }
}

RetailCxScreen.navigationOptions = {
    header: null,
};

export const ReportView = (props) => {

    const [show, setShow] = useState([]);
    const [data, setData] = useState([]);
    useEffect(() => {
        (async() => {
            try {
                const org_id = await AsyncStorage.getItem('organizationID');
                const userToken = await AsyncStorage.getItem('userToken');
                const data = await getReportData({org_id, userToken});
                setData(data);
                const arr = [];
                data.map((item, index) => arr.push({
                    [index] : false
                }));
                setShow(arr);
            } catch(e) {
            }
        })(); 
    }, []);


    //mutating data array to be sorted by date
    if(data.length > 0){
        function sortByDate(a, b) {
            return new Date(a.search_date).getTime() - new Date(b.search_date).getTime();
        }
        data.sort(sortByDate)
        data.reverse()
      
    }
    
    const BulbIcon = (props) => (
        <Icon {...props} name={'bulb'} fill={'#005961'} style={{width: 20,
            height: 20}}  />
    );

    const _connectCampaignData = async (options) => {
        await AsyncStorage.setItem('Context2Campaign', JSON.stringify(options));
        props.navigation.navigate("CampaignData");
    }

    if (data.length === 0) { 
        return <View style={{alignItems:'center'}}><Text status='danger'>No Reports Created</Text></View>
    }

    if (data.length > 0) {
        return (
            <DataTable>
            <DataTable.Header>
                {/* <DataTable.Title>#</DataTable.Title> */}
                {/* <DataTable.Title>Name</DataTable.Title> */}
                <DataTable.Title>Keyword</DataTable.Title>
                <DataTable.Title>Date</DataTable.Title>
                <DataTable.Title style={{alignItems:'center'}}></DataTable.Title>
                <DataTable.Title style={{alignItems:'center'}}></DataTable.Title>
            </DataTable.Header>
            { data.map((item, index) => 
            <>
            <DataTable.Row>
                {/* <DataTable.Cell>{item.search_id}</DataTable.Cell> */}
                {/* <DataTable.Cell>{item.search_name}</DataTable.Cell> */}
                <DataTable.Cell>{item.search_query}</DataTable.Cell>
                <DataTable.Cell>{item.search_date}</DataTable.Cell>
                <DataTable.Cell style={{alignItems:'center', justifyContent:'center'}}>
                    <Button 
                        size='tiny'
                        style={{backgroundColor: Color.DarkGreen}} 
                        onPress={() => props.setSuperSetId(item.search_id)}> view </Button>
                </DataTable.Cell>
                <DataTable.Cell style={{alignItems:'center', justifyContent:'center'}}>
                    <Button 
                        size='tiny'
                        style={{backgroundColor: Color.DarkBlack}} 
                        onPress={() => _connectCampaignData(item)}> Get Campaign Data </Button>
                </DataTable.Cell>
            </DataTable.Row>
            </>
            )}
            </DataTable>
        );
    }
    
};

const ReportSuperSetView = (props) => {
    return (
        <Iframe
            url={`${Constants.CONTEXT_API}/login` + "?auth_code=" + props.authCode + "&redirect=" + `https://analytics.cxsphere.com/superset/dashboard/66/?standalone=3&org_id=${props.org_id}&search_id=${props.super_set_id}`}  
            width={'95%'}
            id="super-set-id"
            className="super-review-cx"
            height={600}
            frameBorder={0}
        />
    )
}

const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },

    universalScrollView: {
        backgroundColor: 'transparent',
        height: '90vh',
        width: "80vw"
    }


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
