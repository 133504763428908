import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import Label from '../components/Label'
import TextBox from '../components/textbox';
import TextBoxNoAutofill from '../components/textboxNoAutofill';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Color } from '../utils/color';
import { Constants } from '../utils/constants';
import { Analytics } from '../components/Analytics';
import { validateCharacter, validateEmail, validName, validatePassword, isSamePassword, occupationName } from '../utils/validation';
//robert.pars@mailinator.com
//Robert@123
export default class LoginScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRegister: true,
      username: "",
      password: "",

      fullName: "",
      emailID: "",
      rPassword: "",
      rConfirmPassword: "",
      isLoadingSignIn: false,
      isLoadingSignUp: false,
      isWide: Platform.select({
        web: false,
        default: true,
      }),
      originalWidth: Dimensions.get("window").width,

    }
  }

  handleText = (name) => {
    return (text) => {
      this.setState({ [name]: text.replace(/\s/g, '') })
    }
  }

  handleTextFullName = (name) => {
    return (text) => {
      this.setState({ [name]: text })
    }
  }

  loginValidation = () => {

    var message = "";
    if (this.state.username.length == 0) {
      message = "Emailid can not be empty."
    } else if ((!validateEmail(this.state.username))) {
      message = "Please enter a valid email address"
    } else if (this.state.password == 0) {
      message = "Please enter password"
    } else if (this.state.password.length < 8) {
      message = "Password length must be between 8 and 128"
    }

    return message;
  }

  signUpValidation = () => {
    var message = "";
    if (this.state.fullName.length == 0) {
      message = "Please enter a full name"
    } else if (!validName(this.state.fullName)) {
      message = "Please enter a only alphabetic"
    } else if (this.state.emailID.length == 0) {
      message = "Emailid can not be empty."
    } else if (!validateEmail(this.state.emailID)) {
      message = "Please enter a valid email address"
    } else if (this.state.rPassword.length == 0) {
      message = "Please enter a Password"
    } else if (this.state.rPassword.length < 8) {
      message = "Password length must be between 8 and 128"
    } else if (this.state.rConfirmPassword < 8) {
      message = "Confirm Password length must be between 8 and 128"
    } else if (!isSamePassword(this.state.rConfirmPassword, this.state.rPassword)) {
      message = "Password & confirm Password doesn't match"
    }

    return message;

  }



  authCode = async (organizationID, userToken) => {


    if (organizationID == "" && organizationID == null && organizationID == "undefined") {
      return;
    }

    this.setState({
      isLoadingSignIn: true
    })

    try {

      let url = `${Constants.BASE_URL}${Constants.ORGANIZATIONS}/${organizationID}/generate-auth-code`

      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken

        },
        body: JSON.stringify({
          "appName": 'Superset'
        }),
      });
      let responseJson = await response.json();
      this.setState({
        isLoadingSignIn: false
      })

      if (typeof (responseJson.authCode) != "undefined") {

        await AsyncStorage.setItem('authCode', responseJson.authCode);
        global.authCode = responseJson.authCode
        this.props.navigation.navigate("Dashboard")
      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }
    } catch (error) {
      this.setState({
        isLoadingSignIn: false
      })
    }
  }






  loginAccout = async () => {

    //this.props.navigation.navigate("Dashboard")
    //return;

    let messageAlert = this.loginValidation()

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        alert(alertText)
      } else {
        Alert.alert(alertTitle, alertText)
      }
      return;
    }

    this.setState({
      isLoadingSignIn: true
    })


    try {

      let url = `${Constants.BASE_URL}${Constants.LOGIN}`

      let response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": this.state.username,
          "password": this.state.password
        }),
      });
      let responseJson = await response.json();

      this.setState({
        isLoadingSignIn: false
      })

      if (typeof (responseJson.access_token) != "undefined") {

        await AsyncStorage.setItem('userToken', responseJson.access_token);
        await AsyncStorage.setItem('fullName', responseJson.fullName);
        await AsyncStorage.setItem('organizationID', responseJson.organization.id.toString());
        await AsyncStorage.setItem('organizationName', responseJson.organization.organization_name);
        await AsyncStorage.setItem('email', this.state.username);

        global.bearerToken = responseJson.access_token
        global.organizationID = responseJson.organization.id
        global.organizationName = responseJson.organization.organization_name

        this.authCode(responseJson.organization.id, responseJson.access_token)

      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }
    } catch (error) {
      this.setState({
        isLoadingSignIn: false
      })

      console.error("====== error =====");
    }
  }

  createAccount = async () => {


    let messageAlert = this.signUpValidation()

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        alert(alertText)
      } else {
        Alert.alert(alertTitle, alertText)
      }

      return;

    }

    this.setState({
      isLoadingSignUp: true
    })

    try {

      let url = `${Constants.BASE_URL}${Constants.USER}`
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "fullName": this.state.fullName,
          "email": this.state.emailID,
          "password": this.state.rPassword,
        }),
      });
      let responseJson = await response.json();

      this.setState({
        isLoadingSignUp: false
      })


      if (typeof responseJson.token != "undefined") {

        this.setState({
          fullName: "",
          emailID: "",
          rPassword: "",
          rConfirmPassword: ""
        }, () => {
          this.props.navigation.navigate("Organization", { token: responseJson.token })
        })


        // if (Platform.OS === 'web') {
        //   alert("Signup Successfully.")
        // } else {
        //   Alert.alert("CXSphere", "Signup Successfully.")
        // }
      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }
    } catch (error) {
      //console.error("====== error =====", error);
      // if (Platform.OS === 'web') {
      //   alert(responseJson.message)
      // } else {
      //   Alert.alert("CXSphere", responseJson.message)
      // }
      this.setState({
        isLoadingSignUp: false
      })

    }

  }

  _handleLayout = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;
    this.setState({
      originalWidth: width
    })
    this.setState(() => ({ isWide: width >= 991 }));
  };


  render() {
    return (
      <SafeAreaView onLayout={this._handleLayout} style={{ flex: 1 }}>
        <Analytics />
        <View style={[styles.Container, { backgroundColor: this.state.originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
          {this.state.originalWidth > 991 && <View style={{
            width: '100%',
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: '10%',
          }}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}
          {this.state.originalWidth < 991 && <View style={styles.mobileHeader}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}

          <KeyboardAwareScrollView
            enableOnAndroid={true} extraHeight={50} extraScrollHeight={50}
            innerRef={ref => {
              this.scroll = ref
            }}>

            <View style={{
              flexDirection: 'row-reverse',
              alignItems: this.state.originalWidth >= 991 ? 'center' : 'flex-start',
              justifyContent: 'center',
              paddingTop: this.state.originalWidth >= 991 ? 0 : 20,
              height: (Platform.OS == 'android' ? Dimensions.get("window").height - 40 : Dimensions.get("window").height - 120),
              width: '100%',
            }}>

              {(this.state.originalWidth >= 991 || !this.state.isRegister) && <View style={styles.login}>
                <Label Barlow_SemiBold style={[styles.title, { display: (this.state.originalWidth < 991 ? 'none' : 'flex'), alignSelf: "center" }]}>Login</Label>
                <TextBox
                  textStyle={styles.inputBox}
                  value={this.state.username}
                  onChangeText={this.handleText("username")}
                  placeholder="Email" />
                <TextBox
                  textStyle={styles.inputBox}
                  value={this.state.password}
                  onChangeText={this.handleText("password")}
                  secureTextEntry={true}
                  onSubmitEditing={() => {
                    this.loginAccout()
                  }}
                  placeholder="Password" />
                <TouchableOpacity
                  style={{ width: 130, alignSelf: "flex-end" }}
                  onPress={() => {
                    this.props.navigation.navigate("ForgotPassword")
                  }}  >
                  <Label font14Normal style={styles.forgotPasswordText}> Forgot Password ?</Label>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.loginAccout} style={styles.button} >
                  {!this.state.isLoadingSignIn && <Label style={styles.btnText}>Sign in</Label>}
                  {this.state.isLoadingSignIn && <ActivityIndicator size="small" color="white" />}
                </TouchableOpacity>
                <View font14Normal Barlow_SemiBold style={[styles.loginOrLineMain, {
                  marginTop: this.state.originalWidth < 991 ? 35 : 135,
                  marginBottom: this.state.originalWidth < 991 ? 16 : 16,
                }]}>
                  {/* <View style={styles.borderLine}></View>
                  <Label font14Normal Barlow_Regular style={styles.textOr}>Or</Label>
                  <View style={styles.borderLine}></View> */}
                </View>
                {/* <TouchableOpacity style={[styles.button, { marginBottom: 10, backgroundColor: '#F34A38', flexDirection: 'row', justifyContent: 'center' }]} >
                  <Image style={styles.iconImg} source={require("../assets/images/google-plus.png")} />
                  <Text style={styles.btnText}>Sign in with Google</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, { marginBottom: 10, backgroundColor: '#007AAA', flexDirection: 'row', justifyContent: 'center' }]}>
                  <Image style={styles.iconImgSecond} source={require("../assets/images/linkedin.png")} />
                  <Text style={styles.btnText}>Sign in with Linkedin</Text>
                </TouchableOpacity> */}
                {this.state.originalWidth < 991 && <View style={styles.textAccountMain}>
                  <Text style={styles.textAccount}>You don't have an account?</Text>
                  <TouchableOpacity onPress={() => {
                    this.scroll.props.scrollToPosition(0, 0)
                    this.setState({
                      isRegister: true
                    })
                  }}><Text style={{ fontSize: 14, color: '#005961', marginLeft: 2, }}>Sign Up</Text></TouchableOpacity>
                </View>}
              </View>}

              {(this.state.originalWidth >= 991 || !this.state.isRegister) && <View style={{
                width: 2,
                height: '80%',
                backgroundColor: Color.DarkGreen,
                marginLeft: this.state.originalWidth < 991 ? 0 : 50,
                marginRight: this.state.originalWidth < 991 ? 0 : 50,
                display: (this.state.originalWidth < 991 ? 'none' : 'flex')
              }}></View>}
              
              {(this.state.originalWidth >= 991 || this.state.isRegister) && <View style={styles.registration}>
                <Label Barlow_SemiBold style={[styles.title, {
                  display: (this.state.originalWidth < 991 ? 'none' : 'flex'), textAlign: 'center', alignSelf: "center",
                }]}>Registration</Label>
                <TextBoxNoAutofill
                  textStyle={styles.inputBox}
                  value={this.state.fullName}
                  autoCapitalize={"words"}
                  onChangeText={this.handleTextFullName("fullName")}
                  placeholder="Full Name" />
                <TextBoxNoAutofill
                  textStyle={styles.inputBox}
                  value={this.state.emailID}
                  onChangeText={this.handleText("emailID")}
                  placeholder="Email"
                  />
                <TextBoxNoAutofill
                  textStyle={styles.inputBox}
                  value={this.state.rPassword}
                  onChangeText={this.handleText("rPassword")}
                  secureTextEntry={true}
                  placeholder="Password" />
                <TextBoxNoAutofill
                  textStyle={styles.inputBox}
                  value={this.state.rConfirmPassword}
                  onChangeText={this.handleText("rConfirmPassword")}
                  secureTextEntry={true}
                  onSubmitEditing={() => {
                    this.createAccount()
                  }}
                  placeholder="Confirm Password" />
                <TouchableOpacity onPress={this.createAccount} style={[styles.button, { borderWidth: 1, backgroundColor: Color.White, borderColor: Color.DarkGreen, }]} >
                  {!this.state.isLoadingSignUp && <Label style={[styles.btnText, { color: Color.DarkGreen, }]}>Create Account</Label>}
                  {this.state.isLoadingSignUp && <ActivityIndicator size="small" color={Color.DarkGreen} />}
                </TouchableOpacity>
                <View style={[styles.orLineMain, {
                  marginTop: this.state.originalWidth < 991 ? 20 : 60,
                  marginBottom: this.state.originalWidth < 991 ? 16 : 16,
                }]}>
                  {/* <View style={styles.borderLine}></View>
                  <Label font14Normal Barlow_Regular style={styles.textOr}>Or</Label>
                  <View style={styles.borderLine}></View> */}
                </View>
                {/* <TouchableOpacity style={[styles.button, { marginBottom: 10, backgroundColor: '#F34A38', flexDirection: 'row', justifyContent: 'center' }]} >
                  <Image style={styles.iconImg} source={require("./../assets/images/google-plus.png")} />
                  <Text style={styles.btnText}>Sign in with Google</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, { marginBottom: 10, backgroundColor: '#007AAA', flexDirection: 'row', justifyContent: 'center' }]}>
                  <Image style={styles.iconImgSecond} source={require("./../assets/images/linkedin.png")} />
                  <Text style={styles.btnText}>Sign in with Linkedin</Text>
                </TouchableOpacity> */}
                <View style={[styles.textAccountMain, {
                  display: (this.state.originalWidth < 991 ? 'flex' : 'none'),
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }]}>
                  <Text style={styles.textAccount}>You have an account?</Text>
                  <TouchableOpacity onPress={() => {
                    this.scroll.props.scrollToPosition(0, 0)
                    this.setState({
                      isRegister: false
                    })
                  }}><Text style={{ fontSize: 14, color: '#005961', marginLeft: 2, }}>Login</Text></TouchableOpacity>
                </View>
              </View>}

            </View>

          </KeyboardAwareScrollView>


        </View>
      </SafeAreaView >
    );
  }

}

LoginScreen.navigationOptions = {
  header: null,
};
const base = StyleSheet.create({
  Container: {
    flex: 1,
    alignItems: 'center',
  },
  inputBox: {
    backgroundColor: Color.White,
  },
  mobileHeader: {
    paddingTop: (Platform.OS == 'ios' ? 20 : 40),
    paddingBottom: 20,
    width: '100%',
    justifyContent: 'center',
    alignItems: "center"
  },
  forgotPasswordText: {
    color: '#959596',
    alignSelf: 'flex-end',
    fontFamily: 'Barlow-Medium',
    color: Color.DarkGrey,
    marginBottom: 20,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#005961',
    borderRadius: 5,
    height: 45
  },
  btnText: {
    color: '#ffffff',
    fontSize: 14,
  },
  orLineMain: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginOrLineMain: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textOr: {
    color: '#595A5A',
    width: '20%',
    textAlign: 'center'
  },
  borderLine: {
    width: '40%',
    height: 1,
    backgroundColor: '#DEDEDE'
  },
  iconImg: {
    marginRight: 10,
    resizeMode: 'contain',
    width: 19,
    height: 12,
  },
  iconImgSecond: {
    marginRight: 10,
    resizeMode: 'contain',
    width: 13,
    height: 12,
  },
  textAccountMain: {
    //display: "none",
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
  },
  login: {
    width: 400,
    paddingLeft: 0,
  },
  registration: {
    width: 400,
  },
  middleLine: {
    width: 2,
    height: '100%',
    backgroundColor: Color.DarkGreen,
    position: 'absolute',
    right: '50%',
    top: 0,
    display: "flex"
  },
  title: {
    fontSize: 36,
    color: Color.DarkGreen,
    textAlign: 'center',
    marginBottom: 50,
  },
  boxRow: {
    alignItems: 'center',
  },
  scrollMain: {
    alignItems: "center",
    justifyContent: 'center',
    flex: 1,
  }

});

const styles = createStyles(
  base,
  maxWidth(1400, {
    title: {
      fontSize: 30,
      color: Color.DarkGreen,
      textAlign: 'center',
      marginBottom: 25,
    },

  }),
  maxWidth(1024, {
    title: {
      fontSize: 30,
      color: Color.DarkGreen,
      textAlign: 'center',
      marginBottom: 32,
    },

  }),
  maxWidth(991, {

    scrollMain: {
      alignItems: "center",
      justifyContent: 'center',
      flex: 0,
    },

    login: {
      width: Dimensions.get("window").width - 30,
      paddingLeft: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    registration: {
      width: Dimensions.get("window").width - 30,
      paddingRight: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    middleLine: {
      backgroundColor: Color.TRANSPARENT,
    },
    loginOrLineMain: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    orLineMain: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textAccountMain: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 15,
      marginBottom: 15,
      //display: 'flex'
    },
    textAccount: {
      fontSize: 14,
      color: 'red',
    },
    textOr: {
      color: '#595A5A',
      fontSize: 14,
      width: '10%',
      textAlign: 'center'
    },
    borderLine: {
      width: '45%',
      height: 1,
      backgroundColor: '#DEDEDE'
    },
  }),
  maxWidth(480, {
    scrollMain: {
      alignItems: "center",
      justifyContent: "flex-start",
      height: Dimensions.get("window").height - 140,
    },

  })
);