import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState, useEffect } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { ApplicationProvider, IconRegistry, Layout, Text } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import * as eva from '@eva-design/eva';
import AppNavigator from './navigation/AppNavigator';
import { default as mapping } from './mapping.json';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalytics from '@analytics/google-analytics'
import { AnalyticsContext } from './AnalyticsContext';


const analytics = Analytics({
  app: 'Organization App',
  plugins: [
    googleTagManager({
      containerId: 'GTM-5GM3ZQV'
    }),
    googleAnalytics({
      trackingId: 'UA-194996623-3'
    })
  ]
})


export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickEvent, false);
    return () => {
      document.removeEventListener("click", handleClickEvent, false);
    };
  }, []);

  const handleClickEvent = event => {
    analytics.track('clickEvents', {
      buttonName: event.target.innerText
    })
  };

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <View style={styles.container}>
        <IconRegistry icons={EvaIconsPack} />
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <AnalyticsContext.Provider value={analytics}>
          <ApplicationProvider {...eva} theme={{ ...eva.light }} customMapping={mapping}>
            <AppNavigator />
          </ApplicationProvider>
        </AnalyticsContext.Provider>
      </View>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      require('./assets/images/robot-dev.png'),
      require('./assets/images/robot-prod.png'),
    ]),
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      ...Ionicons.font,
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
      'Barlow-Black': require('./assets/fonts/Barlow-Black.otf'),
      'Barlow-Bold': require('./assets/fonts/Barlow-Bold.otf'),
      'Barlow-Light': require('./assets/fonts/Barlow-Light.otf'),
      'Barlow-Medium': require('./assets/fonts/Barlow-Medium.otf'),
      'Barlow-Regular': require('./assets/fonts/Barlow-Regular.otf'),
      'Barlow-SemiBold': require('./assets/fonts/Barlow-SemiBold.otf')

    }),
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});