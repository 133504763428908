import { Constants } from "../utils/constants";

export const postScrapData = async (options) => {
    const userToken = options.userToken;
    delete options.userToken;
    const urls = [];
    for (let index = 0; index < options.urls.length; index++) {
        urls.push(options.urls[index].name);
    }
    options.urls = urls;
    let response = await fetch(`${Constants.B2B_API}/compete/get-competition/${options.org_id}`, {
        method: 'POST',
        body: JSON.stringify(options),
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + userToken
        }
    });
    let responseJson = await response.json();
    return responseJson;
}

export const getReportData = async (options) => {
    const userToken = options.userToken;
    delete options.userToken;
    let response = await fetch(`${Constants.B2B_API}/compete/get-competition/${options.org_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + userToken
        }
    });
    let responseJson = await response.json();
    return responseJson;
}
