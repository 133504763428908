import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export class Chatbot extends Component {
    render () {
        return (
            <div className="cxspherechat" style={{position:'fixed', bottom: 0, right: 0}}>
                <Helmet>
                    {/* index21 standard before clear status logic change */}
                {/* <script type='module' src="https://cdn.jsdelivr.net/gh/faizan124a/chf/index21.js" async defer ></script> */}
                <script type='module' src="https://cdn.jsdelivr.net/gh/faizan124a/chf/indexcds9.js" async defer ></script>
                </Helmet>
            </div>
        );
      }
}

export default Chatbot