import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, FlatList, Text, View, Image, Dimensions, TouchableOpacity, Platform, Keyboard, Alert } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../../utils/color';
import MobileHeader from './../../components/MobileHeader';
import MenuSettings from './../../components/MenuSettings';
import SideMenu from './../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../components/Label';
import TextBox from '../../components/textbox';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Constants } from './../../utils/constants';
import { Analytics } from '../../components/Analytics';
export default class OrganizationData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),

            navWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width - 30,
            }),
            isOpen: false,
            product: "",
            account: "",
            location: "",

            isLocationType: false,
            isLocationList: true,

            regionsData: [],
            countryData: [],
            locationData: [],
            data: [],
            locationType: "",
            locationSelected: "",
            originalWidth: Dimensions.get("window").width,
            fullName: "",
            organizationName: "",



        }
    }

    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');

        const organizationName = await AsyncStorage.getItem('organizationName');

        this.setState({
            organizationName: organizationName
        })

        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.getRegions()
            this.getCountryData()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName: fullName
        })

    }

    // async componentDidMount() {

    //     const userToken = await AsyncStorage.getItem('userToken');
    //     if (userToken != "null") {
    //         this.getRegions()
    //         this.getCountryData()
    //     }
    // }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;
        this.setState({
            originalWidth: width
        })
        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width - 30,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width - 30,
                    })
            })
        }

        if (width >= 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };



    getRegions = async () => {

        const userToken = await AsyncStorage.getItem('userToken');

        try {
            let url = `${Constants.BASE_URL}${Constants.REGIONS}`
            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            if (typeof responseJson != "undefined") {
                if (responseJson.length > 0) {
                    this.setState({
                        regionsData: responseJson
                    }, () => {
                        // console.log("******** regionsData ********", this.state.regionsData)
                    })
                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }
    }

    getCountryData = async () => {

        const userToken = await AsyncStorage.getItem('userToken');

        try {

            let url = `${Constants.BASE_URL}${Constants.INFO_REQUEST}`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            if (typeof responseJson != "undefined") {

                this.setState({
                    countryData: responseJson.countries
                }, () => {
                    // console.log("******** countryData ********", this.state.countryData)
                })

            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }
    }



    saveLocation = async () => {


        if (this.state.locationType.length == 0 || this.state.locationSelected.length == 0) {

            const alertTitle = 'CXSphere'
            const alertText = "Please enter valid location."
            if (Platform.OS === 'web') {
                alert(alertText)
            } else {
                Alert.alert(alertTitle, alertText)
            }
            return;
        }


        var locationId = 0
        var locationType = ""

        if (this.state.locationType == "Country") {

            let locations = this.state.countryData.filter((item) => {
                return item.name == this.state.locationSelected
            })


            if (locations.length > 0) {
                locationId = locations[0].id
            }

            locationType = "country"
        } else {

            let locations = this.state.regionsData.filter((item) => {
                return item.name == this.state.locationSelected
            })


            if (locations.length > 0) {
                locationId = locations[0].id
            }
            locationType = "state"
        }


        if (locationId == 0) {
            if (Platform.OS === 'web') {
                alert("Please enter valid location")
            } else {
                Alert.alert("CXSphere", "Please enter valid location")
            }

            return;
        }


        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/locations`

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify({
                    "locationId": locationId,
                    "locationType": locationType,

                }),
            });
            let responseJson = await response.json();

            if (responseJson == 200) {

                this.setState({
                    locationType: "",
                    locationSelected: ""
                })

                if (Platform.OS === 'web') {
                    alert("Data has been added successfully.")
                } else {
                    Alert.alert("CXSphere", "Data has been added successfully.")
                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }
        } catch (error) {
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }
    }




    saveOrganizationData = async (name) => {

        let messageAlert = ""
        var textFieldName = ""

        if (name == "products") {

            if (this.state.product.length == 0) {
                const alertTitle = 'CXSphere'
                const alertText = "Please enter product"
                if (Platform.OS === 'web') {
                    alert(alertText)
                } else {
                    Alert.alert(alertTitle, alertText)
                }
                return;
            }

            textFieldName = this.state.product

        } else if (name == "accounts") {

            if (this.state.account.length == 0) {
                const alertTitle = 'CXSphere'
                const alertText = "Please enter account"
                if (Platform.OS === 'web') {
                    alert(alertText)
                } else {
                    Alert.alert(alertTitle, alertText)
                }
                return;
            }

            textFieldName = this.state.account

        }

        else if (name == "locations") {

            if (this.state.account.length == 0) {
                const alertTitle = 'CXSphere'
                const alertText = "Please enter location"
                if (Platform.OS === 'web') {
                    alert(alertText)
                } else {
                    Alert.alert(alertTitle, alertText)
                }
                return;
            }

            textFieldName = this.state.location

        }

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');


        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/${name}`

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify({
                    "name": textFieldName,
                }),
            });
            let responseJson = await response.json();


            if (responseJson == 200) {
                this.setState({
                    product: "",
                    account: ""
                })
                if (Platform.OS === 'web') {
                    alert("Data has been added successfully.")
                } else {
                    Alert.alert("CXSphere", "Data has been added successfully.")
                }
            } else if (typeof (responseJson.id) != "undefined") {
                this.setState({
                    product: "",
                    account: ""
                })

                if (Platform.OS === 'web') {
                    alert("Data has been added successfully.")
                } else {
                    Alert.alert("CXSphere", "Data has been added successfully.")
                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }
        } catch (error) {
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }
    }

    handleText = (name) => {
        return (text) => {
            this.setState({ [name]: text.replace(/\s/g, '') })
        }
    }
    searchText = (e) => {


        var dropdownData = [];

        if (this.state.locationType == "Country") {
            dropdownData = this.state.countryData || []
        } else {
            dropdownData = this.state.regionsData || []
        }

        let text = e.toLowerCase()
        let trucks = dropdownData
        let filteredName = trucks.filter((item) => {
            return item.name.toLowerCase().match(text)
        })

        if (!text || text === '') {
            this.setState({
                data: dropdownData
            })
        } else if (!Array.isArray(filteredName) && !filteredName.length) {
            // set no data flag to true so as to render flatlist conditionally
            this.setState({
                noData: true
            })
        } else if (Array.isArray(filteredName)) {
            this.setState({
                noData: false,
                data: filteredName
            })
        }
    }
    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title={this.state.organizationName} />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>{this.state.organizationName}</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <KeyboardAwareScrollView
                            contentInsetAdjustmentBehavior="automatic"
                            contentContainerStyle={[styles.scrollMain, { width: this.state.myWidth, height: '100%' }]} >

                            <View style={[styles.boxRow, {
                                marginTop: (this.state.originalWidth < 767 ? 20 : 30),
                                width: this.state.myWidth, flexDirection: this.state.myWidth < 768 ? 'column' : 'row'
                            }]}>
                                < View style={[styles.columnBox, { paddingLeft: (this.state.originalWidth < 767 ? 15 : 10), paddingRight: (this.state.originalWidth < 767 ? 15 : 10), marginBottom: (this.state.originalWidth < 767 ? 15 : 10), width: this.state.myWidth < 768 ? this.state.myWidth : this.state.myWidth * 0.27 }]} >
                                    <View style={styles.bgBox}>
                                        <Label font15Large style={styles.boxTitle}>Product</Label>
                                        <TextBox
                                            value={this.state.product}
                                            boxStyle={styles.inputBox}
                                            onChangeText={this.handleText("product")}
                                            placeholder="Product" />

                                        <View style={styles.btnMain}>
                                            <TouchableOpacity onPress={() => this.saveOrganizationData("products")} style={styles.btnBox}>
                                                <Label font14Normal color={Color.White}>Save</Label>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                                <View style={[styles.columnBox, { paddingLeft: (this.state.originalWidth < 767 ? 15 : 10), paddingRight: (this.state.originalWidth < 767 ? 15 : 10), marginBottom: (this.state.originalWidth < 767 ? 15 : 10), width: this.state.myWidth < 768 ? this.state.myWidth : this.state.myWidth * 0.27 }]}>
                                    <View style={styles.bgBox}>
                                        <Label font15Large style={styles.boxTitle}>Location</Label>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                            <View style={{ width: "50%", justifyContent: "center" }} >

                                                <TextBox
                                                    boxStyle={styles.inputBox}
                                                    value={this.state.locationType}
                                                    placeholder="Location Type" />

                                                <Image
                                                    style={{ height: 7, width: 11, position: "absolute", right: 15, alignSelf: 'center' }}
                                                    source={require("./../../assets/images/small_down.png")} />


                                                <TouchableOpacity onPress={() => {
                                                    // this.setState({
                                                    //     isLocationType: true
                                                    // })


                                                    this.setState((prevState) => {
                                                        return {
                                                            isLocationType: !prevState.isLocationType,
                                                            locationSelected: ""
                                                        }
                                                    })
                                                }} style={{ position: "absolute", height: '100%', width: '100%', }}>

                                                </TouchableOpacity>

                                                {this.state.isLocationType && <FlatList
                                                    style={{
                                                        position: "absolute", top: 64, left: 0, width: '92%', backgroundColor: Color.White,
                                                        zIndex: 9999, marginLeft: '4%', marginRight: 10, marginBottom: 0, marginTop: -10, marginRight: "4%", borderWidth: 1, borderColor: Color.BORDERCOLOR
                                                    }}
                                                    data={["Country", "Region"]}
                                                    renderItem={({ item }) => {
                                                        return (
                                                            <TouchableOpacity
                                                                style={{ borderBottomWidth: 1, borderBottomColor: Color.BORDERCOLOR }}
                                                                onPress={() => {
                                                                    this.setState({
                                                                        locationType: item,
                                                                        isLocationType: false
                                                                    }, () => {
                                                                        if (this.state.locationSelected.length != 0) {

                                                                            // if (this.state.locationSelected == "Country") {
                                                                            //     this.setState({
                                                                            //         locationData: this.state.countryData,
                                                                            //         data: this.state.countryData
                                                                            //     })
                                                                            // } else {
                                                                            //     this.setState({
                                                                            //         locationData: this.state.regionsData,
                                                                            //         data: this.state.countryData
                                                                            //     })
                                                                            // }

                                                                        }
                                                                    })
                                                                }}>
                                                                <Label font15Large style={styles.boxTitle}>{item}</Label>
                                                            </TouchableOpacity>
                                                        )
                                                    }}
                                                    keyExtractor={item => item.id}
                                                />}

                                            </View>


                                            <View style={{ width: "50%", justifyContent: "center" }}>
                                                <TextBox
                                                    boxStyle={styles.inputBox}
                                                    textStyle={{ paddingRight: 20 }}
                                                    value={this.state.locationSelected}
                                                    onChangeText={(text) => {
                                                        this.setState({
                                                            locationSelected: text
                                                        }, () => {
                                                            this.searchText(text)
                                                        })
                                                    }}
                                                    onFocus={() => {

                                                        if (this.state.locationType.length == 0) {
                                                            Keyboard.dismiss()
                                                            const alertTitle = 'CXSphere'
                                                            const alertText = "Please select location type."
                                                            if (Platform.OS === 'web') {
                                                                alert(alertText)
                                                            } else {
                                                                Alert.alert(alertTitle, alertText)
                                                            }
                                                            return;
                                                        }

                                                        this.setState({
                                                            isLocationList: true
                                                        })
                                                    }}
                                                    placeholder="Location" />

                                                <Image
                                                    style={{ height: 7, width: 11, position: "absolute", right: 15, alignSelf: 'center' }}
                                                    source={require("./../../assets/images/small_down.png")} />


                                                {(this.state.locationSelected.length > 0 && this.state.isLocationList) && <FlatList
                                                    style={{
                                                        marginLeft: '4%',
                                                        marginBottom: 10,
                                                        marginTop: -10,
                                                        marginRight: '4%',
                                                        borderWidth: 1,
                                                        position: "absolute",
                                                        width: "92%",
                                                        top: 64,
                                                        right: 0,
                                                        backgroundColor: Color.White,
                                                        zIndex: 9999,
                                                        borderColor: (this.state.data.length == 0 ? Color.White : Color.BORDERCOLOR),
                                                        height: (this.state.data.length > 5 ? 200 : this.state.data.length * 40)
                                                    }}
                                                    data={this.state.data}
                                                    renderItem={({ item }) => {
                                                        return (
                                                            <TouchableOpacity
                                                                style={{ borderBottomWidth: 1, borderBottomColor: Color.BORDERCOLOR }}
                                                                onPress={() => {
                                                                    this.setState({
                                                                        locationSelected: item.name,
                                                                        isLocationList: false
                                                                    })
                                                                }}>
                                                                <Label font15Large style={styles.boxTitle}>{item.name}</Label>
                                                            </TouchableOpacity>
                                                        )
                                                    }}
                                                    keyExtractor={item => item.id}
                                                />}

                                            </View>
                                        </View>


                                        <View style={styles.btnMain}>
                                            <TouchableOpacity onPress={() => this.saveLocation()} style={styles.btnBox}>
                                                <Label font14Normal color={Color.White}>Save</Label>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                                <View style={[styles.columnBox, {
                                    paddingLeft: (this.state.originalWidth < 767 ? 15 : 10), paddingRight: (this.state.originalWidth < 767 ? 15 : 10),
                                    marginBottom: (this.state.originalWidth < 767 ? 15 : 10), width: this.state.myWidth < 768 ? this.state.myWidth : this.state.myWidth * 0.27, position: "relative", zIndex: -1
                                }]}>
                                    <View style={styles.bgBox}>
                                        <Label font15Large style={styles.boxTitle}>Account</Label>
                                        <TextBox
                                            value={this.state.account}
                                            boxStyle={styles.inputBox}
                                            onChangeText={this.handleText("account")}
                                            placeholder="Account" />

                                        <View style={styles.btnMain}>
                                            <TouchableOpacity onPress={() => this.saveOrganizationData("accounts")} style={styles.btnBox}>
                                                <Label font14Normal color={Color.White}>Save</Label>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </KeyboardAwareScrollView>
                    </View>
                </View>
            </View >

        )
    }
}

OrganizationData.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxTitle: {
        textAlign: "center",
        borderBottomWidth: 1,
        borderColor: Color.BORDERCOLOR,
        width: "100%",
        padding: 10,

    },
    boxRow: {
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: "row",
        justifyContent: "center",
    },
    columnBox: {
        justifyContent: "space-between",
    },
    bgBox: {
        backgroundColor: Color.White,
        borderRadius: 5
    },
    inputBox: {
        width: "100%",
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
    },
    btnMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 15,
        zIndex: -1,
    },
    btnBox: {
        backgroundColor: Color.DarkGreen,
        width: 100,
        height: 40,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: "center",
    },
    sideBerLeft: {
        width: 230,
    },
    containBox: {
        width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },



});
const styles = createStyles(
    base,
    maxWidth(767, {
        scrollMain: {
            width: Dimensions.get("window").width,
        },
        btnBox: {
            backgroundColor: Color.DarkGreen,
            width: 80,
            height: 36,
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: "center",
        },
        containBox: {
            width: Dimensions.get("window").width,
        },

    }),

    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);





