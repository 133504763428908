import { Constants } from "../utils/constants";

export const getInsightData = async (options) => {
  const userToken = options.userToken;
  delete options.userToken;
  let response = await fetch(`${Constants.B2B_API}/insights/${options.org_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + userToken
    }
  }).catch((res)=>{console.log(res, 'fetch error')})

  if(response == undefined){
    console.log('failed to fetch')
    return
  }

  let responseJson = await response.json();
  return responseJson;
}
