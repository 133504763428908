import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  Text,
  Image, TouchableOpacity,
  Platform,
  Dimensions
} from 'react-native';
import { Color } from '../utils/color';
import PropTypes from 'prop-types';
import Label from '../components/Label';
import Button from './button';
import { createStyles, minWidth } from 'react-native-media-queries';
import { isIphoneX } from '../utils/isIphone-x'
const HEADER_SIZE = isIphoneX() ? 100 : 70;

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWeb: Platform.select({
        web: true,
        default: false,
      })
    }
  }
  render() {
    let { title, mainStyle } = this.props
    return (
      <SafeAreaView forceInset={{ top: 'always' }} style={[styles.mainView, { height: HEADER_SIZE }]}>
        <View style={{
          width: Dimensions.get("window").width - 20,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: 15
        }}>

          <Image style={{ height: 37, width: 35, alignSelf: "center", position: "absolute", left: 15 }} source={require("../assets/images/mobile_logo.png")} />
          <Label color={Color.DarkGreen} Barlow_SemiBold style={styles.innerView}>{title}</Label>

          {!this.props.isHide && <TouchableOpacity onPress={() => {
            this.props.navigation.openDrawer()
          }} style={{ height: 40, width: 40, alignItems: "center", justifyContent: "center", position: "absolute", alignSelf: "center", right: 10 }}>
            <Image style={{ height: 12, width: 18 }} source={require("../assets/images/menu_open.png")} />
          </TouchableOpacity>}
        </View>
      </SafeAreaView >

    );
  }
}

MobileHeader.defaultProps = {
  isHide: false
}
const base = StyleSheet.create({
  mainView: {
    height: HEADER_SIZE,
    backgroundColor: Color.White,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    shadowColor: '#000',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
    backgroundColor: "white"
  },
  innerView: {
    fontSize: 20,
  },
  logo: {
    height: HEADER_SIZE,
    width: HEADER_SIZE,
    alignSelf: "center",
  }
});

const styles = createStyles(
  base,
);
export default MobileHeader;
