import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, Alert, Text, View, Image, Dimensions, TouchableOpacity, Platform, Linking, ScrollView } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../utils/color';
import { Constants } from './../utils/constants';
import featureJson from '../data/feature.json';
import MobileHeader from './../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from './../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../components/Label';
import * as Localization from 'expo-localization';
import { isValidSubscription, getFeature, getCategories } from '../services/Feature';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { WebView } from 'react-native-webview';
import Iframe from 'react-iframe'
import { Analytics } from '../components/Analytics';
import { Icon, Card, IndexPath, Layout, Select, SelectItem, Button } from '@ui-kitten/components';
import { FlatGrid } from 'react-native-super-grid';

export default class CampaignSourceScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            userToken:'',
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),
            isOpen: false,
            originalWidth: Dimensions.get("window").width,
            fullName: "",
            email:'',
            category_id:'',
            categories: [],
            arrEngage: [],
            engage_types: [],
            openFrame: false,
            openFrameurl: "",
            org_id:'',
            fullScreen: false
        }
    }
    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');
        let categories = [];
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            categories = await getCategories(userToken);
            this.getCampaignList();
            this.getEngageList()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        const email = await AsyncStorage.getItem('email');
        this.setState({
            fullName: fullName,
            org_id: organizationID,
            userToken,
            categories,
            email
        })

    }

    async componentDidUpdate(){
        console.log('FullScreen state',this.state.fullScreen)
    }
    
    getCampaignList = async () => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/data`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();
            if (typeof responseJson != "undefined") {
                if (typeof responseJson.engage_types != "undefined") {
                    this.setState({
                        engage_types: responseJson.engage_types
                    })
                }
            } 
        } catch (error) {
            console.error("====== error =====");
        }
    }

    getEngageList = async () => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');
        const email = await AsyncStorage.getItem('email');
        try {
            const data = await getFeature(userToken, Localization.region, true);     
            let arrEngage = [];
            for (let index = 0; index < data.length; index++) {
                let item = data[index];
                item.status = await this.getStatus(item.code);
            }
            this.setState({arrEngage:data});
        } catch (error) {
            console.error("====== error =====", error);
        }
    }

    setFromCategoryFooter = (object) => {
        console.log(this.setState);
    } 

    goTo = (url, isOpenFrame, navigation_name) => {
        if (isOpenFrame !== true) {
            this.setState({
                openFrame: isOpenFrame
            })
            this.props.navigation.navigate(navigation_name.trim());
        } else {
            this.setState({
                openFrame: isOpenFrame,
                openFrameurl: url,
                fullScreen: true
            })
        }
        return false;
    };
    
    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })
        if (width >= 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }
        this.setState(() => ({ isWide: width >= 767 }));

    };

    async getStatus(code) {
        const status =  await isValidSubscription({
            code, 
            org_id: this.state.org_id, 
            userToken: this.state.userToken,
            email: this.state.email
        });
        return status || false;
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {/* {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Engage" />} */}
                <View style={{ flexDirection: 'row', flex: 1, backgroundColor: 'transparent' }}>
                    {this.state.originalWidth >= 767 && this.state.fullScreen == false && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.fullScreen == true ? '100%' : this.state.myWidth, backgroundColor: 'transparent' }]}>
                        {this.state.originalWidth >= 767 && 
                        <View style={[styles.logoDesktop, {
                            width: this.state.fullScreen == true ? '100%' : this.state.myWidth,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }]}>
                             {this.state.fullScreen == true && <TouchableOpacity //supplement logo when SideMenu is hidden
                             onPress={() => {
                                this.props.navigation.navigate("Dashboard")
                              }}>
                            <Image style={{width: 40, height: 40, resizeMode: 'contain', backgroundColor: 'white', marginLeft: 55 }} source={require("../assets/images/logo.png")} />
                            </TouchableOpacity>}
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                                marginLeft: (this.state.myWidth / 2) - 100
                                
                            }]}>Campaign Source</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />

                        {this.state.openFrame === false && 
                            <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.props.navigation.navigate("Engage")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                        }

                        {this.state.openFrame === true && <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.setState({ openFrame: false, fullScreen: false })}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>}

                        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} scrollEnabled={!this.state.openFrame}
                        style={{width: '100vw'}}
                        >

                            {!this.state.openFrame && <View style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: "100%",
                                alignItems: (this.state.originalWidth < 767 ? "center" : 'flex-start'),
                                justifyContent: (this.state.originalWidth < 767 ? "flex-start" : 'flex-start'),
                                paddingTop: 2,
                                paddingBottom: 10,
                                paddingLeft: (this.state.originalWidth < 767 ? 0 : 10)
                            }}>

                            { this.state.engage_types.length > 0 && <FlatGrid
                                    itemDimension={300}
                                    data={this.state.engage_types}
                                    spacing={10}
                                    renderItem={({ item }) => (
                                        <Card style={{margin: 20,
                                            width: 300, 
                                            borderRadius: 10, shadowRadius: 10, shadowColor: 'grey', shadowOpacity: 5}}>
                                            <Card onClick={() => this.goTo(item.url, true, false)}
                                                style={{ marginBottom: 10 }}
                                                header={<CategoryHeader image={item.name} />}>
                                                {/* <Label ml={10} mr={3} sigleLine={false} style={{ alignSelf: 'center', textAlign: "center" }} font15Large Barlow_SemiBold color={Color.DarkGreen} >{item.application_name.slice(0, 8)}</Label> */}
                                                <Label ml={10} mr={3} sigleLine={false} style={{ alignSelf: 'center', textAlign: "center" }} font15Large Barlow_SemiBold color={Color.DarkGreen} >{item.application_name}</Label>
                                            </Card>
                                        </Card>    
                                    )}
                                />
                            }

                            { this.state.engage_types.length === 0 &&
                                        <Card style={{margin: 20,
                                            width: 300, 
                                            borderRadius: 10, 
                                            shadowRadius: 10, 
                                            shadowColor: 'grey', 
                                            shadowOpacity: 2}}>
                                                <Text>No Campaign Found</Text>
                                        </Card>    
                            }

                            </View>}

                            {this.state.openFrame && <View style={{
                                width: this.state.fullScreen == true ? '100%' : this.state.myWidth,
                                paddingTop: 0,
                                paddingBottom: 10,
                                paddingLeft: (this.state.originalWidth < 767 ? 0 : 0),
                                height: (this.state.originalWidth < 767 ? Dimensions.get("window").height - 120 : Dimensions.get("window").height - 145)
                            }}>


                                <View style={{
                                    width: this.state.myWidth,
                                    height: (this.state.originalWidth < 767 ? '100%' : Dimensions.get("window").height - 145),
                                    flexDirection: "column"
                                }}>

                                    {/* height={(this.state.originalWidth < 767 ? Dimensions.get("window").height - 120 : Dimensions.get("window").height - 150)} */}

                                    {(Platform.OS !== 'ios' && Platform.OS != 'android') && <Iframe
                                        onload="setInterval(document.getElementById('testFrame').contentWindow.location.reload(), 100);"
                                        url={this.state.openFrameurl}
                                        position="fixed"
                                        width={this.state.fullScreen == true ? '100%' : this.state.myWidth}
                                        id="myId"
                                        className="myClassname"
                                        height={"100%"}
                                        styles={{ marginTop: '40px' }} />}

                                    {(Platform.OS == 'ios' || Platform.OS == 'android') && <WebView
                                        useWebKit={true}
                                        javaScriptEnabled={true}
                                        source={{ uri: this.state.openFrameurl }}
                                        style={{ width: '100%', height: '100%' }}
                                        startInLoadingState={true}
                                    />}

                                </View>
                            </View>}

                        </ScrollView>

                    </View>

                </View>
            </View >

        )
    }
}

const Header = (props) => {
    try {
        return <View style={{alignItems:'center', padding: 10}}>
        <Image 
            style={{ height: 60, width: 60, marginLeft: 10, marginTop: 10, }} 
            source={require(`./../assets/images/${props.image}`)} />  
        </View>
    } catch (error) {
        return <View style={{alignItems:'center', padding: 10}}>
        <Image 
        style={{ height: 100, width: 100, marginLeft: 10, marginTop: 10, }} 
        source={require(`./../assets/images/financial.png`)} />  
        </View>
    }
}

const CategoryHeader = (props) => {
    const image = props.image.trim().toLowerCase();
    try {
        return <View style={{alignItems:'center', padding: 10}}>
            <Icon name={'star'} fill='#005961' style={{width: 20, height: 20 }} />  
        </View>
    } catch (error) {
        return <View style={{alignItems:'center', padding: 10}}>
            <Icon name={'star'} fill='#005961' style={{width: 20, height: 20 }} />
        </View>
    }
}

const CategoryFooter = (props) => {
    const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
    const options = props.menus.filter(section => section.category_id === props.category_id && section.status === true);
    const _openPage = (item) => {    
        /*
        const goTo = (url, isOpenFrame, navigation_name) => {
            if (isOpenFrame !== true) {
                props.setFromCategoryFooter({
                    openFrame: isOpenFrame
                })
                props.navigation.navigate(navigation_name.trim());
            } else {
                props.setFromCategoryFooter({
                    openFrame: isOpenFrame,
                    openFrameurl: url
                })
            }
            return false;
        };
        */
        props.goTo(item.options.url, item.options.is_iframe, item.navigation_name.trim());
    }
    const Body = () => {
        return (
             <Select 
                placeholder={'Select Menu'}
                style={{border:0, padding:0, margin:0}}  
                size={'small'}
                status={'warning'}
                value = {'Select Menu'} 
                selectedIndex={selectedIndex}
                onSelect={index => _openPage(options[index.row])}>  
                    { options.map(item =>
                            <SelectItem title={item.name} />     
                    )}
            </Select>
        )
    }

    return (
        <View style={{border:0, padding:0, margin:0}}>
            <Body />
        </View>
    )
    
}

const Footer = (props) => {
    return props.status === true ?
        <View style={{alignItems:'flex-end', padding: 10}}>
            <Icon name={'star'} fill='#005961' style={{width: 20, height: 20 }} />
        </View> : 
        <View style={{alignItems:'flex-end', padding: 10}}>
            <Icon name={'slash'} fill='#005961' style={{width: 20, height: 20 }} />
        </View>

}

CampaignSourceScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },

    boxIcon: {
        height: 62,
        width: 62,
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        width: Dimensions.get("window").width - 150,
    },
    boxColumn: {
        backgroundColor: Color.White,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 1,
        alignItems: 'center',
        justifyContent: "center"
    }



});
const styles = createStyles(
    base,
    maxWidth(991, {
        title: {
            fontSize: 36,
            color: Color.DarkGreen,
            textAlign: 'left',
            marginLeft: 10,
            width: '100%',
            marginTop: 20,
        },


        boxIcon: {
            height: 62,
            width: 62,
        },
    }),
    maxWidth(767, {
        Container: {
            flex: 1,
            backgroundColor: Color.WhiteLight,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        },

        boxIcon: {
            height: 62,
            width: 62,
        },
        containBox: {
            width: '100%',
        }

    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);