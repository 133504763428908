import React, { Component } from "react";
import { TouchableOpacity, Text, StyleSheet, ActivityIndicator } from "react-native";
import PropTypes from 'prop-types';
import { Color } from "../utils/color";

export default class Button extends Component {
    render() {
        const { title, titleColor, borderColor, borderWidth, borderRadius, backgroundColor, height,
            disabled, btnStyle, txtStyle, onClick } = this.props;
        return (
            <TouchableOpacity
                style={[styles.buttonStyle, btnStyle,
                { borderColor, borderWidth, borderRadius, backgroundColor, height }]}
                disabled={disabled}
                onPress={onClick}>
                {!this.props.isLoading && <Text
                    style={[txtStyle, { color: titleColor }]}>
                    {title}
                </Text>}
                {this.props.isLoading && <ActivityIndicator size="small" color="white" />}

            </TouchableOpacity>
        );
    }
}

Button.defaultProps = {
    title: "Sign in",
    titleColor: Color.DarkGrey,
    borderColor: Color.DarkGreen,
    borderWidth: 1,
    borderRadius: 4,
    height: 44,
    width: '100%',
    backgroundColor: Color.transparent,
    disabled: false,
    isLoading: false
};

Button.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
};

const styles = StyleSheet.create({
    buttonStyle: {
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        width: '100%'
    },
});