import { Constants } from "../utils/constants";

let profileUrl = Constants.PROFILE_API;

export const getProfile = async(data) => {
  let response;
     try {
      response = await fetch(`${profileUrl}/profile/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      let responseJson = await response.json();  
      return responseJson;
    } catch (error) {
      return {isError: true}
    }
  }
  
  export const createProfile = async(params) => {
    const {org_id, dob, gov_id, gender, line1, line2, city, state, country, postal_code} = params;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({org_id, dob, gov_id, gender, line1, line2, city, state, country, postal_code})
    };
    
    let response;
    try {
      response = await fetch(`${profileUrl}/profile/insert`, requestOptions)
    } catch(error) {
      response = {isError: true}
    }
    return response
  }
  
  export const updateProfile = async(data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
    };
    
    let response;
    try {
      response = await fetch(`${profileUrl}/profile/update`, requestOptions);
    } catch (error) {
      response = {isError: true};
    }
    return response   
  }