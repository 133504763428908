import React, { useState, useCallback, useContext, useEffect, useRef } from "react";
import { View, Image, Dimensions, Button, Text, TouchableOpacity, StyleSheet, TextInput, ActivityIndicator, Platform } from 'react-native'
import * as DocumentPicker from 'expo-document-picker';
import { Color } from './../utils/color';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Constants } from './../utils/constants';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { CheckBox, Autocomplete, AutocompleteItem } from '@ui-kitten/components';



const Upload = ({ type, isTranscription = false, authCode, getData }) => {
  
    const [success, setSuccess] = useState('')
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')
    const [fileSize, setFileSize] = useState('')
    const [fileDuration, setFileDuration] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [selectedOptions, setSelectedOptions] = useState(['transcription'])
    const [transcription, setTranscription] = useState(true)

    const [loading, setLoading] = useState(false)
    const [gettingFile, setGettingFile] = useState(false)

    const [sentimentAnalysis, setSentimentAnalysis] = useState(false)
    const [topicIdentification, setTopicIdentitification] = useState(false)

    const languageArr = [
      { "code": "en", "name": "English" },
      { "code": "fr", "name": "French" },
      { "code": "de", "name": "German" },
      { "code": "es", "name": "Spanish" },
      { "code": "it", "name": "Italian" },
      { "code": "ar", "name": "Arabic" },
      { "code": "hi", "name": "Hindi" },
      { "code": "ta", "name": "Tamil" },
      { "code": "si", "name": "Sinhala" },
      { "code": "ur", "name": "Urdu" },
      { "code": "km", "name": "Central Khmer" }
  ]

    const [languages, setLanguages] = useState(languageArr)
    const [validLang, setValidLang] = useState(null)
    const [selectedLanguage, setSelectedLanguage] = useState({ "code": "en", "name": "English" })
    const [langIndex, setLangIndex] = useState(null)

    const fileInputRef = useRef(null);

    const getFile = async()=>{
      setSuccess('')
      setErrorMessage('')
      let result = await DocumentPicker.getDocumentAsync({
        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv']
        })

      if(result.file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || result.file.type == 'text/csv'){
        setFile(()=>result)
      } else {
        setErrorMessage('Error file type not supported.')
        return
      }
    }

    // For picking files on ios and android
    const getAudioVideoFiles = async()=>{
      // setGettingFile(true) expo document picker does not return promise on 'cancel'
      setSuccess('')
      setErrorMessage('')
      let result = await DocumentPicker.getDocumentAsync({
        type: [
          'audio/mp3', 
          // 'audio/m4a',
          // 'video/mp4'
      ]
        })

      setFile(()=>result)
      // setGettingFile(false)
    }

  // Function to simulate file input click
  const triggerFileInputClick = () => {
    fileInputRef.current.click();
  };

  // Handle audio file upload on web with browser APIs
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      getAudioDuration(file); // Calculate the duration
    }
  };

  // Calculate audio duration
  const getAudioDuration = (file) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);

    // Wait for the audio to load and then get the duration
    audio.onloadedmetadata = () => {
      setFile(file)
      setFileDuration(audio.duration)
    };
  };

  // Format the duration
  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.round(durationInSeconds % 60);

    // If less than 1 minute, show seconds
    if (durationInSeconds < 60) {
      return `${seconds}s`;
    }

    // If less than 1 hour, show minutes and seconds
    if (durationInSeconds < 3600) {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}m`;
    }

    // If more than 1 hour, show hours, minutes, and seconds
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}h`;
  };

    const removeFile = async()=>{
      setSuccess('')
      setFile()
      setFileName('')
      setFileSize('')
      setErrorMessage('')
      setSelectedOptions([])
      setTranscription(false)
      setSentimentAnalysis(false)
      setTopicIdentitification(false)
    }

    useEffect(()=>{
     if(file){
      setFileName(file.name)

      const MB = 1024 * 1024
      const fileSizeinMB = (file.size / MB).toFixed(2)

      setFileSize(fileSizeinMB)
     } return
    },[file])

    const _pickDocument = async () => {
      //product uploader
        let result = file
        const data = new FormData();
        let organization_id =  await AsyncStorage.getItem('organizationID');
        data.append('files', result.file);
        let res = await fetch(
            `${Constants.B2B_API}/organization/upload-multiple-product?organization_id=${organization_id}`,
            {
              method: 'POST',
              body: data
            }
          ).then(async(res)=>{
            let clean = true
            let response = await res.json()
              // if(response.success == "200"){
              //   setSuccess('File uploaded successfully')
              //   setFile()
              //   setFileName('')
              // } else setErrorMessage('Upload error')
              response.messages.map(m=>{
                if(m.message == "Product added successfully"){
                  return
                } else if(m.message !== "Product added successfully") {
                  setErrorMessage('Error in Data')
                  clean = false
                }
              })

              if(clean == true){
                setSuccess('File uploaded successfully')
                setFile()
                setFileName('')
              }
          })
          .catch(()=>{
            console.log('fetch request not made')
          })
    }
    
    const _pickRetail = async () => {
      let result = file
      const data = new FormData();
      let organization_id =  await AsyncStorage.getItem('organizationID');
      data.append('files', result.file);
      let res = await fetch(
          `http://127.0.0.1:5000/organization/upload-analytics?organization_id=${organization_id}`,
          {
            method: 'POST',
            body: data
          }
        ).then(async(res)=>{
          let response = await res.json()
            if(response.success == "200"){
              setSuccess('File uploaded successfully')
              setFile()
              setFileName('')
            } else setErrorMessage('Upload error')
        })
        .catch(()=>{
          console.log('fetch request not made')
          setErrorMessage('Error: File was not uploaded')
        })
    }

    const _pickRevenue = async () => {
      let result = file
      const data = new FormData();
      let organization_id =  await AsyncStorage.getItem('organizationID');
      data.append('files', result.file);
      let res = await fetch(
          `http://127.0.0.1:5000/organization/upload-revenue?organization_id=${organization_id}`,
          {
            method: 'POST',
            body: data
          }
        ).then(async(res)=>{
          let response = await res.json()
            if(response.success == "200"){
              setSuccess('File uploaded successfully')
              setFile()
              setFileName('')
            } else setErrorMessage('Upload error')
        })
        .catch(()=>{
          console.log('fetch request not made')
          setErrorMessage('Error: File was not uploaded')
        })
    }

    const _pickSubscription = async () => {
      let result = file
      const data = new FormData();
      let organization_id =  await AsyncStorage.getItem('organizationID');
      data.append('files', result.file);
      let res = await fetch(
          `${Constants.B2B_API}/organization/upload-analytics-subscription?organization_id=${organization_id}`,
          {
            method: 'POST',
            body: data
          }
        ).then(async(res)=>{
          let response = await res.json()
            if(response.success == "200"){
              setSuccess('File uploaded successfully')
              setFile()
              setFileName('')
            } else setErrorMessage('Upload error')
        })
        .catch(()=>{
          console.log('fetch request not made')
          setErrorMessage('Error: File was not uploaded')
        })
        // let responseJson = await res.json();
        // console.log('here',responseJson)
        // if(responseJson.success == "200"){
        //   setSuccess('File uploaded successfully')
        //   setFile()
        //   setFileName('')
        // } else return
    }

    const _pickTranscriptionFile = async () => {

        setLoading(true)

        let result = {file : file }
        
        const data = new FormData();
        data.append('audio_input', result.file);
        data.append('audio_lenght', fileDuration);
        data.append('language', selectedLanguage.code);

        fetch(`https://cxml.cxsphere.com/transcribe?diarize=true`, {
          method: "POST",
          headers: {
            'Authorization': authCode
          },
          body: data
        })
        .then(async(res) => res.json())
        .then((result)=>{
          getData({result, fileName})
          setSuccess('')
          setFile()
          setFileName('')
          setLoading(false)
        })
        .catch(()=>{
          console.log('fetch request not made')
          setLoading(false)
        })
    }

    const findType = (type) => {
        if(type === 'product') {
            return _pickDocument();
        } else if(type === 'retail-data-uploader') {
          return _pickRetail();
        } else if(type === 'subscription-data-uploader') {
          return _pickSubscription();
        } else if(type === 'revenue-forecasting-uploader') {
          return _pickRevenue();
        } else if(type == 'transcription-uploader'){
          return _pickTranscriptionFile()
        }
    }

  
    const filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());



  useEffect(()=>{    
   const valid = languageArr.some((item)=>(selectedLanguage == item.name || selectedLanguage.name == item.name))
   if(!valid){ setValidLang(false)}
  },[selectedLanguage])

  const onSelectLanguage = (index) => {
    const language = languages[index]
    setSelectedLanguage(language)
    setLangIndex(index)
  };


 const onChangeTextLanguage = (query) => {
      setLanguages(languageArr.filter(item => filter(item.name, query)))
      setSelectedLanguage(query) // sets the search term
  };

   const renderOptionLanguage = (item, index) => {
      return (
      <AutocompleteItem
        key={index}
        title={item.name}
      />
      );
  };

    return (
      <View style={{ 
      width: Dimensions.get('window').width * 0.9,
      maxWidth: 700,
      marginLeft:15,
      marginTop: 15, 
      marginBottom: 15, 
      height: file ? 350 : 100, 
      borderStyle: 'dashed',
      borderColor: Color.DarkGreen,
      backgroundColor:'#fff', 
      borderRadius:10,
      borderRightWidth:1, 
      borderLeftWidth:1, 
      borderBottomWidth:1, 
      borderTopWidth:1,
      justifyContent: (file ? 'flex-start' : 'center'),
      alignItems:(file ? 'center' : 'center'),      
    }}>

      {loading && 
      <View style={{flexDirection: 'row', marginTop: 10}}>
        <ActivityIndicator/>
        <Text style={{marginLeft: 10}}>Please wait while your data is being processed</Text>
      </View>}

          <View>  
            {!file ? 
            (<View style={{width: 150, alignItems: 'center', justifyContent: 'center'}}>
            {Platform.OS !== 'ios' || Platform.OS !== 'android' ? 
          
              <>
                <input type="file" accept=".mp3, .m4a" ref={fileInputRef} onChange={handleFileChange} style={{display: 'none'}} />
                <Button onPress={() => [isTranscription ? triggerFileInputClick() :  null]}
                    disabled={gettingFile}
                    backgroundColor={Color.DarkGreen}
                    color={Color.DarkGreen}
                    title={gettingFile ? "Loading..." : "Add a new File"}>
                  </Button>
              </>
              
            :

              <Button onPress={() => [isTranscription ? getAudioVideoFiles() :  getFile()]}
                disabled={gettingFile}
                backgroundColor={Color.DarkGreen}
                color={Color.DarkGreen}
                title={gettingFile ? "Loading..." : "Add a new File"}>
              </Button>
            }
              <Text style={{ fontSize: 10, color: 'black'}}>file format {isTranscription ? ".mp3, .m4a" : ".csv or .xlsx"}</Text>
            </View> ) :
            (<View style={{justifyContent: 'space-between'}}>
              <TouchableOpacity style={styles.card} activeOpacity={.6}>
                <Text style={{maxWidth: '40%', fontWeight: '500', marginRight: 30}} lineBreakMode='tail' numberOfLines={1}>{fileName}</Text>
                <Text style={{fontWeight: '500', width: 100, textAlign: 'center'}} numberOfLines={1}>{formatDuration(fileDuration)}</Text>
                <Text style={{fontWeight: '500', width: 100}} numberOfLines={1}>{fileSize} MB</Text>
                <View style={{flexDirection: 'row'}}>
                  <TouchableOpacity 
                  disabled={loading}
                  onPress={()=>removeFile()}>
                    <MaterialCommunityIcons name="delete" size={20} color="black" style={{marginLeft: 20}} />
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            </View>)}
          </View>

            {file && 
            <View style={{alignSelf: 'center', marginTop: 20}}>
              {isTranscription && 
                <View style={{flexDirection: 'column', alignItems: 'flex-start', marginBottom: 20}}>
                <Text style={{marginBottom: 5}}>Primary language in audio file: </Text>
                {/* Dropdown */}
                <Autocomplete
                  e
                  style={{marginBottom: 10}}
                  placeholder='Primary Language'
                  value={selectedLanguage.name}
                  onSelect={onSelectLanguage}
                  // onChangeText={onChangeTextLanguage}
                  >
                  {languages.map(renderOptionLanguage)}
              </Autocomplete>


                <CheckBox
                  style={{margin: 5}}
                  checked={transcription}
                  onChange={check => [setTranscription(check), transcription == false ? selectedOptions.push('transcription') :  setSelectedOptions(selectedOptions.filter(e => e !== 'transcription'))]} //if true(checked) then remove
                  >Transcription
                </CheckBox>
                <CheckBox
                  disabled={true}
                  style={{margin: 5}}
                  checked={sentimentAnalysis}
                  onChange={check => [setSentimentAnalysis(check), sentimentAnalysis == false ? selectedOptions.push('sentiment_analysis') :  setSelectedOptions(selectedOptions.filter(e => e !== 'sentiment_analysis'))]} //if true(checked) then remove
                  >Sentiment Analysis
                </CheckBox>
                <CheckBox
                  disabled={true}
                  style={{margin: 5}}
                  checked={topicIdentification}
                  onChange={check => [setTopicIdentitification(check), topicIdentification == false ? selectedOptions.push('topic_identification') :  setSelectedOptions(selectedOptions.filter(e => e !== 'topic_identification'))]} //if true(checked) then remove
                  >Topic Identification
                </CheckBox>
              </View>
              }

              <Button
                disabled={isTranscription && selectedOptions.length == 0 && !validLang || loading} 
                onPress={() => findType(type)}
                backgroundColor={Color.DarkGreen}
                color={Color.DarkGreen}
                title="Upload File">
              </Button>
            </View>
            }

            {!file && success.length > 0 &&
            <View style={{backgroundColor: '#e3e3e3', position: 'absolute', bottom: 200, padding: 10, borderRadius: 8}}>
            <Text>{success}</Text>
            </View>}

            {errorMessage.length > 0 && 
            <View style={{backgroundColor: '#e3e3e3', bottom: 200, padding: 10, borderRadius: 8, borderWidth: 1, borderColor: 'red', alignSelf: 'center', opacity: .8}}>
            <Text>{errorMessage}</Text>
            </View>}
        </View>
    );
    
  };

  export default Upload;

  const styles = StyleSheet.create({
    card:{
      flexDirection: 'row', 
      padding: 10, 
      justifyContent: 'space-between', 
      backgroundColor: 'white', 
      borderRadius: 4, 
      borderWidth: 1, 
      borderColor: '#e3e3e3',
      margin: 10, 
      maxWidth: 400, 
      // maxWidth: '90%',
      minHeight: 50,
      alignItems: 'center'
    }
  })
