import React, { Component, useEffect, useState } from 'react'
import {
    ScrollView, StyleSheet, View, FlatList,
    Image, Dimensions, TouchableOpacity, Platform
} from 'react-native';
import { DataTable } from 'react-native-paper';
import {
    Input, List, ListItem, Divider, Button,
    Card, Text, Toggle, Drawer, DrawerGroup, DrawerItem, Icon,
    Autocomplete, AutocompleteItem, Layout
} from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import MobileHeader from '../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from '../components/SideMenu';
import ReviewCxResultComponents from '../components/ReviewCxResultComponents';
import Label from '../components/Label';
import countryJson from '../data/countryNames.json';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import { Entypo } from '@expo/vector-icons';
import { Analytics } from '../components/Analytics';
import { postScrapData, getReportData } from '../services/ScrapData';
import Iframe from 'react-iframe'
import { Constants } from '../utils/constants';

const countryNamesJson = [];
Object.keys(countryJson).forEach(myFunction)
function myFunction(item, index, arr) {
    countryNamesJson.push(
        {
            name: countryJson[item],
            code: item
        });
}

export default class ExtractcxScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            isSubmitted: false,
            isReport: true,
            search_name: '',
            super_set_id: '',
            isSuperSet: false,
            type: true,
            type_name: 'By content',
            authCode: '',
            openFile: false,
            organization_id: null,
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName: null,
            scrap: null,
            geo_location: '',
            keywords: '',
            industry: '',
            review_samples: '',
            queryCountry: countryNamesJson,
            // industries: ['products', 'travel'],
            industries: ['products'],
            reviewcx: {
                scrap: {
                    list: []
                }
            }
        }
    }
    async componentWillMount() {

        const fullName = await AsyncStorage.getItem('fullName');
        const organization_id = await AsyncStorage.getItem('organizationID');
        const authCode = await AsyncStorage.getItem('authCode');
        this.setState({
            fullName,
            organization_id,
            authCode
        })

    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    handleScrapChange = (e) => {
        this.setState({ scrap: e.target.value });
    };

    handleScrapClick = () => {
        if (this.state.scrap.trim() !== '') {
            const list = [...this.state.reviewcx.scrap.list];
            const object = {
                name: this.state.scrap
            };
            list.push(object);
            this.state.reviewcx.scrap.list = list;
            this.setState({
                scrap: '',
                reviewcx: this.state.reviewcx
            });
        }
    };

    onActiveCheckedChange = (isChecked) => {
        this.setState({
            type: isChecked,
            type_name: isChecked === true ? 'By Content' : 'By URLs'
        });
    };

    renderScrapItemAccessory = (index) => (
        <Entypo name="trash" size={24} color="black" onPress={() => this.handleScrapRemove(index)} />
    )

    renderScrapItem = (props) => {
        const { item, index } = props;
        return (
            <ListItem
                title={`${item.name}`}
                accessoryRight={() => this.renderScrapItemAccessory(index)}
            />
        );
    }

    handleScrapRemove = (index) => {
        const { reviewcx } = this.state;
        let list = [...reviewcx.scrap.list];
        list.splice(index, 1);
        reviewcx.scrap.list = list;
        this.setState({
            scrap: '',
            reviewcx
        });
    }

    submitData = async () => {
        const userToken = await AsyncStorage.getItem('userToken');
        const response = await postScrapData({
            org_id: this.state.organization_id,
            urls: this.state.reviewcx.scrap.list,
            userToken,
            search_name: this.state.search_name,
            geo_location: this.state.geo_location,
            review_samples: Number(this.state.review_samples),
            keywords: this.state.keywords,
            industry: this.state.industry
        });
        if (response.message) {
            this.setState({ isSubmitted: true });
        }
    }

    onSelectGeoCountry = (index) => {
        this.setState({
            geo_location: this.state.queryCountry[index].code
        })
    };

    onSelectIndustry = (index) => {
        this.setState({
            industry: this.state.industries[index]
        })
    };

    filter = (item, query) => item.name.toLowerCase().includes(query.toLowerCase());

    onChangeTextGeoCountry = (query) => {
        this.setState({
            queryCountry: countryNamesJson.filter(item => this.filter(item, query)),
            geo_location: query
        })
    };

    onChangeTextIndustry = (query) => {
        this.setState({
            industry: query
        })
    };

    renderOptionGeoCountry = (item, index) => {
        return (
            <AutocompleteItem
                key={index}
                title={item.name}
            />
        );
    };

    renderOptionIndustry = (item, index) => {
        return (
            <AutocompleteItem
                key={index}
                title={item}
            />
        );
    };

    resetReport = (status) => {
        this.setState({ isReport: status });
    }

    setSuperSetId = (super_set_id) => {
        this.setState({ super_set_id, isSuperSet: true, isReport: undefined });
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        const { reviewcx } = this.state;
        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Reviewcx" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>

                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Competitive Analysis</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings
                            {...this.props}
                            isOpen={this.state.isOpen}
                            myWidth={this.state.myWidth}
                            onClick={() => this.setState({ isOpen: false })}
                        />
                        <View style={{ margin: 15, width: 100 }}>
                            <Button size={'tiny'}
                                onPress={() => this.props.navigation.navigate("Engage")}
                                style={{ backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                        {this.state.isReport === false && <View>
                            {this.state.isSubmitted === false &&
                                <ScrollView>
                                    <View style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Card
                                            header={<Text category='h5'>Provide Information</Text>}
                                            style={{ marginTop: 40, width: '30%' }}
                                            footer={<View>
                                                <Button
                                                    style={{ width: 100 }} onPress={this.submitData}>submit</Button></View>}>
                                            <View>
                                                <View style={{ marginBottom: 10 }}>
                                                    <Input
                                                        placeholder='Name'
                                                        value={this.state.search_name}
                                                        onChangeText={search_name => this.setState({ search_name })}
                                                    />
                                                </View>
                                                <View style={{ marginBottom: 10, alignItems: 'flex-end' }}>
                                                    <Toggle
                                                        status={'success'}
                                                        style={styles.toggle}
                                                        checked={this.state.type}
                                                        onChange={this.onActiveCheckedChange}>
                                                        {this.state.type_name}
                                                    </Toggle>
                                                </View>
                                                {
                                                    this.state.type === true && (
                                                        <>
                                                            <View style={{ marginBottom: 10 }}>
                                                                <Input
                                                                    placeholder='Keyword'
                                                                    value={this.state.keywords}
                                                                    onChangeText={keywords => this.setState({ keywords })}
                                                                />
                                                            </View>
                                                            <View style={{ marginBottom: 10 }}>
                                                                <Autocomplete
                                                                    placeholder='Select Country'
                                                                    value={this.state.geo_location}
                                                                    onSelect={this.onSelectGeoCountry}
                                                                    onChangeText={this.onChangeTextGeoCountry}>
                                                                    {this.state.queryCountry.map(this.renderOptionGeoCountry)}
                                                                </Autocomplete>
                                                            </View>
                                                            <View style={{ marginBottom: 10 }}>
                                                                <Autocomplete
                                                                    placeholder='Industry'
                                                                    value={this.state.industry}
                                                                    onSelect={this.onSelectIndustry}
                                                                    onChangeText={this.onChangeTextIndustry}>
                                                                    {this.state.industries.map(this.renderOptionIndustry)}
                                                                </Autocomplete>
                                                            </View>
                                                        </>
                                                    )}
                                                {this.state.type === false && (
                                                    <>
                                                        <Text style={{ marginBottom: 10 }}>Please suggest the list of URLs </Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Input size={35} value={this.state.scrap} onChange={this.handleScrapChange} placeholder={'URL'} />
                                                            <Entypo name="add-to-list" size={24} color="black" style={{ padding: 10 }} onPress={this.handleScrapClick} />
                                                        </View>
                                                        <View>
                                                            <List
                                                                data={reviewcx.scrap.list}
                                                                ItemSeparatorComponent={Divider}
                                                                renderItem={this.renderScrapItem}
                                                            />
                                                        </View>
                                                    </>
                                                )}
                                            </View>
                                            <View style={{ marginBottom: 10 }}>
                                                <Input
                                                    placeholder='Review Samples'
                                                    value={this.state.review_samples}
                                                    onChangeText={review_samples => this.setState({ review_samples })}
                                                />
                                            </View>
                                        </Card>
                                    </View>
                                </ScrollView>}
                            {this.state.isSubmitted === true && <ScrollView>
                                <ReviewCxResultComponents resetReport={this.resetReport} />
                            </ScrollView>}
                        </View>}
                        {this.state.isReport === true && <ScrollView>
                            <View style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Card
                                    header={<Text category='h6'>Previous Competitive Search(s)</Text>}
                                    style={{ marginTop: 40, width: '60%' }}
                                >
                                    <View style={{ padding: 5, alignItems: 'flex-end' }}>
                                        <Button
                                            style={{ width: 200 }} onPress={() => this.setState({ isReport: false })}>New Search</Button></View>
                                    <ReportView {...this.props} setSuperSetId={this.setSuperSetId} />
                                </Card>
                            </View>
                        </ScrollView>}
                        {this.state.isSuperSet === true && <ScrollView>
                            <View style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Card
                                    header={<Text category='h6'>SuperSet View</Text>}
                                    style={{ marginTop: 40, width: '100%' }}
                                    footer={<View>
                                        <Button
                                            style={{ width: 200 }} onPress={() => { this.resetReport(true); this.setState({ isSuperSet: false }) }}>Back</Button></View>}>
                                    <ReportSuperSetView {...this.props} {...this.state} />
                                </Card>
                            </View>
                        </ScrollView>}
                    </View>
                </View>
            </View >
        )
    }
}

export const ReportView = (props) => {

    const [show, setShow] = useState([]);
    const [data, setData] = useState([]);
    useEffect(() => {
        (async () => {
            try {
                const org_id = await AsyncStorage.getItem('organizationID');
                const userToken = await AsyncStorage.getItem('userToken');
                const data = await getReportData({ org_id, userToken });
                setData(data);
                const arr = [];
                data.map((item, index) => arr.push({
                    [index]: false
                }));
                setShow(arr);
            } catch (e) {
            }
        })();
    }, []);

    const BulbIcon = (props) => (
        <Icon {...props} name={'bulb'} fill={'#005961'} style={{
            width: 20,
            height: 20
        }} />
    );

    if (data.length === 0) {
        return <View style={{ alignItems: 'center' }}><Text status='danger'>No Reports Created</Text></View>
    }

    if (data.length > 0) {
        return (
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>#</DataTable.Title>
                    <DataTable.Title>Name</DataTable.Title>
                    <DataTable.Title>Date</DataTable.Title>
                    <DataTable.Title style={{ alignItems: 'center' }}></DataTable.Title>
                </DataTable.Header>
                {data.map((item, index) =>
                    <>
                        <DataTable.Row>
                            <DataTable.Cell>{item.search_id}</DataTable.Cell>
                            <DataTable.Cell>{item.search_name}</DataTable.Cell>
                            <DataTable.Cell>{item.search_date}</DataTable.Cell>
                            <DataTable.Cell style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    size='tiny'
                                    style={{ backgroundColor: Color.DarkGreen }}
                                    onPress={() => props.setSuperSetId(item.search_id)}> view </Button>
                            </DataTable.Cell>
                        </DataTable.Row>
                    </>
                )}
            </DataTable>
        );
    }

};

const ReportSuperSetView = (props) => {
    return (
        <Iframe
            url={`${Constants.SUPERSET_URL}/login` + "?auth_code=" + props.authCode + "&redirect=" + `${Constants.SUPERSET_URL}/superset/dashboard/61/?standalone=3&search_id=${props.super_set_id}`}
            width={'95%'}
            id="super-set-id"
            className="super-review-cx"
            height={600}
            frameBorder={0}
        />
    )
}

ExtractcxScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    buttonContainer: {
        flex: 1,
        minHeight: 360,
        padding: 10,
        paddingRight: 40,
        alignSelf: 'flex-start',
        alignContent: 'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
    text: { margin: 6 },
    row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin: 5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
