import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, FlatList, Text, View,
    Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import MobileHeader from '../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from '../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../components/Label';
import Upload from '../components/Upload';
import * as FileSystem from 'expo-file-system';
import { ScrollView } from 'react-native-gesture-handler';
import {Button} from '@ui-kitten/components';
import { Analytics } from '../components/Analytics';
var moment = require('moment');
import { FontAwesome } from '@expo/vector-icons'; 

export default class RevenueForcastUploaderScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            openFile : false,
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null
        }
    }
    async componentWillMount() {

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName
        })

    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    openSystem = () => {
        this.setState({
            openFile:true
        })
    }

    // API ::: kabir cassendra system integrated with this
    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Revenue Forecast Data Uploader" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Revenue Forecast Data Uploader</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.props.navigation.navigate("Engage")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                        <View style={styles.buttonContainer}>
                            <Text style={{ paddingLeft: 30, fontSize: 30, color: Color.DarkGreen}}> Upload Files </Text>
                            <Upload type={'revenue-forecasting-uploader'} />
                            <View style={{ padding: 20, width:500, flex:1, flexDirection:'column'}}>
                            {/* <Text style={{color: 'blue'}}
                                onPress={this.openSystem}>
                                Download Template
                            </Text>
                            {this.state.openFile && (
                            <>     
                            <Text>CSV Header given below</Text>
                            <Text style={{padding:10, margin:10, width:500}}> 
                                "transaction_id","store_name","store_phone","store_email","customer_id","first_name","last_name","email","phone","billing_address_1","billing_address_2","billing_city","billing_state","billing_country","billing_postcode","date_profile_updated","date_logged_in","date_interaction","customer_id_interacted_with","date_plan_purchased","plan_id","date_plan_activation","date_plan_expiry"
                            </Text>
                            </>    
                            )} */}
                             <a href={require("../assets/template/rvfctemplate.csv")} download="CXSphere_revenue_forecasting_data_uploader_template" style={{display:'flex', fontFamily:'sans-serif', textDecorationLine:'none', fontSize: 13, backgroundColor: '#e3e3e3', padding: 10, borderRadius: 8, flexDirection: 'row', maxWidth: 200, alignItems: 'center', justifyContent: 'center', borderColor: 'lightgray', borderWidth: 1, borderStyle: 'solid'}}>
                                <span>Download Template</span>
                                <FontAwesome name="download" size={13} color="black" style={{marginLeft: 5}} />
                                </a>
                            </View>
                        </View>                
        </View>
                </View>
            </View >
        )
    }
}

RevenueForcastUploaderScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
