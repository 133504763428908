import React, { Component } from "react";
import { View, Text, TextInput, StyleSheet, TouchableOpacity } from "react-native";
import PropTypes from 'prop-types';
import { createStyles, minWidth } from 'react-native-media-queries';
import { Color } from "../utils/color.js";

export default class TextBox extends Component {
    render() {
        const { viewStyle, boxStyle, textStyle,
            placeholder, placeholderTextColor } = this.props;
        return (
            <View style={viewStyle}>
                <View style={[styles.boxStyle, boxStyle]}>
                    <TextInput
                        style={[styles.textStyle, textStyle]}
                        placeholder={placeholder}
                        placeholderTextColor={placeholderTextColor}
                        onChangeText={this.props.onChangeText}
                        secureTextEntry={this.props.secureTextEntry}
                        value={this.props.value}
                        onFocus={this.props.onFocus}
                        onSubmitEditing={this.props.onSubmitEditing}
                        autoCapitalize={this.props.autoCapitalize}
                        keyboardType={this.props.keyboardType}
                    />
                </View>

            </View >
        );
    }
}
TextBox.defaultProps = {
    autoCapitalize: 'none'
}

const base = StyleSheet.create({
    textStyle: {
        borderWidth: 1,
        borderColor: '#DEDEDE',
        width: '100%',
        borderRadius: 5,
        fontSize: 14,
        padding: 12,
        marginBottom: 12,
        fontFamily: 'Barlow-Regular',
        outline: "none"
    },
});
const styles = createStyles(
    base,
    minWidth(480, {

    })
);