import React from 'react';
import { Tab, TabBar, Text, Button, Icon, Layout,TabView } from '@ui-kitten/components';

const CustomerProfile = () => {
  return (
    <Layout style={{padding: 20}}>
      <Text>
      Know where your customers are searching, their life stage, buy stage, product affinity and more
      </Text>
      <Button style={{margin: 20, width: 150}}>Go</Button>
    </Layout>
  )
}

const CampaignSystem = () => {
  return (
    <Layout style={{padding: 20}}>
      <Text>
      You have requested to reach out customers directly (campaign system link and credentials emailed)
      </Text>
      <Button style={{margin: 20, width: 150}}>Go</Button>
    </Layout>
  )
}

const SocialData = () => {
  return (
    <Layout style={{padding: 20}}>
      <Text>
      Our team is working to get you the data, wou will get the notification once done
      </Text>
      <Button style={{margin: 20, width: 150}}>Go</Button>
    </Layout>
  )
}

const Summary = () => {
  return (
    <Layout style={{padding: 20}}>
      <Text>
      Thank you for submitting the data.
      </Text>
      <Button style={{margin: 20, width: 150}}>Go</Button>
    </Layout>
  )
}

const BulbIcon = (props) => (
  <Icon {...props} name='bulb-outline'/>
);

const PersonIcon = (props) => (
  <Icon {...props} name='person-outline'/>
);

const BellIcon = (props) => (
  <Icon {...props} name='bell-outline'/>
);

const EmailIcon = (props) => (
  <Icon {...props} name='email-outline'/>
);

const LeadCxResultComponents = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  return (
    <Layout style={{margin: 30, padding: 30, width:'80%' }}>  
    <TabView
      selectedIndex={selectedIndex}
      onSelect={index => setSelectedIndex(index)}>
      <Tab icon={BulbIcon} title='Summary'>
        <Summary {...props} />
      </Tab>  
      <Tab icon={PersonIcon} title='Customer Profile'>
        <CustomerProfile {...props} />
      </Tab>  
      <Tab icon={BellIcon} title='Campaign Systems'>
        <CampaignSystem {...props} />
      </Tab>  
      <Tab icon={EmailIcon} title='Social Data'>
        <SocialData {...props} />
      </Tab>  
    </TabView>
    </Layout>
  );
};

export default LeadCxResultComponents;