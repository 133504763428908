import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, FlatList, Text, View, Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../../utils/color';
import MobileHeader from './../../components/MobileHeader';
import MenuSettings from './../../components/MenuSettings';
import SideMenu from './../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../components/Label';
import { WebView } from 'react-native-webview';
import Iframe from 'react-iframe'
import { ScrollView } from 'react-native-gesture-handler';
import { isIphoneX } from './../../utils/isIphone-x'
const HEADER_SIZE = isIphoneX() ? 100 : 70;
import { Constants } from './../../utils/constants';
import { Analytics } from '../../components/Analytics';
import { Card } from '@ui-kitten/components';
import { getInsightData } from '../../services/Dashboard'
import config from '../../config';

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }), isOpen: false,
            originalWidth: Dimensions.get("window").width,
            fullName: "",
            reportsList: [],
            insightNumber: 0,
            selectedReport: "",
            isOpenReport: false,
            dropX: Dimensions.get("window").width - 220,
            dropY: 140,
            selectedUrl: "",
            authCode: "",
            email:null,
            organizationID:null,
            responseJson: []
        }
    }
    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        const authCode = await AsyncStorage.getItem('authCode');

        this.setState({
            authCode: authCode
        })


        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.getReportsList();
            this.getInsightNumber();
        }

        const fullName = await AsyncStorage.getItem('fullName');

        this.setState({
            fullName: fullName
        })

    }

    getReportsList = async () => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');
        const email = await AsyncStorage.getItem('email');
        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/data`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            // console.log("==== responseJson =====", responseJson)

            if (typeof responseJson != "undefined") {
                if (typeof responseJson.engage_types != "undefined") {

                    let data = responseJson.reports || []

                    if (data.length > 0) {
                        this.setState({
                            reportsList: responseJson.reports || [],
                            selectedUrl: responseJson.reports[0].url.trim(),
                            selectedReport: responseJson.reports[0].title || "Report",
                            organizationID,
                            email,
                            responseJson //saving json response in local state to refer for resetting to first report
                        })
                    }


                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            console.error("====== error =====");
        }
    }

    getInsightNumber = async () => {
        const userToken = await AsyncStorage.getItem('userToken');
        const org_id = await AsyncStorage.getItem('organizationID');
        const email = await AsyncStorage.getItem('email');
        const response = await getInsightData({ org_id, userToken });
        if (response && response['Total Insights']) {
            this.setState({ insightNumber: response['Total Insights'] });
        }
    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })
        if (width >= 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }
        this.setState(() => ({ isWide: width >= 767 }));

    };

 //local control to reset    
    resetReportToHome = async()=>{
        this.setState({
            selectedReport: this.state.responseJson.reports[0].title,
            selectedUrl: this.state.responseJson.reports[0].url.trim()
        })
    }

  //exterior/parent trigger to control child component without state management
    async componentDidUpdate(prevProps){
        if(this.props.navigation.state.params !== prevProps.navigation.state.params){
            console.log('change detected in nav')
            this.resetReportToHome()
        }
        // console.log('prevprops', prevProps.navigation.state.params)
        // console.log('currprops', this.props.navigation.state.params)
    }

    render() {

        var nameC = ""

        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        // console.log("== This is auth code ==", this.state.selectedUrl + "/login?auth_code=" + this.state.authCode)

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>

                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Dashboard" />}

                <View style={{ flexDirection: 'row', flex: 1 }}>


                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Dashboard</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}

                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings
                            {...this.props}
                            isOpen={this.state.isOpen}
                            myWidth={this.state.myWidth}
                            onClick={() => this.setState({ isOpen: false })}
                        />
                        <View style={{ flexDirection: 'row' }}>
                            {false && <View>
                                <Card
                                    style={{
                                        margin: 20,
                                        width: 200,
                                        borderRadius: 10, shadowRadius: 10, shadowColor: 'grey', shadowOpacity: 5
                                    }}
                                    status='danger'
                                    header={<Header image={'insight.png'} />}
                                >
                                    <Label Barlow_SemiBold font18Large color={Color.DarkBlackGrey}>
                                        {this.state.insightNumber}</Label>
                                </Card>
                            </View>}
                            <View style={{ padding: 18, width: '99%' }}>
                                <TouchableOpacity onPress={(evt) => {

                                    this.setState({
                                        dropX: evt.nativeEvent.pageX - evt.nativeEvent.locationX,
                                        dropY: evt.nativeEvent.pageY - evt.nativeEvent.locationY + 40
                                    }, () => {
                                        this.setState({
                                            isOpenReport: true
                                        })
                                    })

                                }} style={[styles.languageBtn, {
                                    alignSelf: (this.state.originalWidth < 767 ? 'center' : 'flex-end'),
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: 5,

                                }]}>
                                    <Label Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{this.state.selectedReport != "" ? this.state.selectedReport : "Select"}</Label>
                                    <View pointerEvents='none' style={{position: "absolute", alignItems: "center", flexDirection: 'row', justifyContent: 'flex-end', width: '80%'}}>
                                        <Image style={{ height: 7, width: 11, tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <ScrollView
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                                contentContainerStyle={{
                                    width: this.state.myWidth,
                                    height: (this.state.originalWidth < 767 ? Dimensions.get("window").height - HEADER_SIZE - 150 + (Platform.OS == 'android' ? 50 : 0) : Dimensions.get("window").height - 150),
                                }}>
                                {/* <iframe height="300px" width="100%" src="http://192.168.1.125:19006/#/App/SideMenu1/Dashboard/"></iframe> */}
                                {/* 
                            {(Platform.OS !== 'ios' && Platform.OS != 'android' && this.state.selectedUrl != "" && this.state.authCode != "") && <Iframe
                                url={this.state.selectedUrl + "/login?auth_code=" + this.state.authCode}
                                position="fixed"
                                allowfullscreen
                                width={this.state.myWidth}
                                id="myId"
                                className="myClassname"
                                height={Dimensions.get("window").height - 160}
                                styles={{ height: "25px" }} />} */}

                                {(Platform.OS !== 'web' && this.state.selectedUrl != "" && this.state.authCode != "" && !this.state.selectedUrl.includes('360')) && <WebView
                                    useWebKit={true}
                                    javaScriptEnabled={true}
                                    source={{ uri: (this.state.selectedUrl.trim()) }}
                                    style={{ width: '100%', height: Dimensions.get("window").height - 150 }}
                                    startInLoadingState={true}
                                />}

                                {(Platform.OS !== 'web' && this.state.selectedUrl != "" && this.state.authCode != "" && this.state.selectedUrl.includes('360')) && <WebView
                                    useWebKit={true}
                                    javaScriptEnabled={true}
                                    source={{ uri: (this.state.selectedUrl.trim() + "/" + this.state.organizationID) }}
                                    style={{ width: '100%', height: Dimensions.get("window").height - 150 }}
                                    startInLoadingState={true}
                                />}


                                {(Platform.OS === 'web' && this.state.selectedUrl != "" && this.state.authCode != "" &&
                                    !this.state.selectedUrl.includes('360')) && <Iframe
                                        url={this.state.selectedUrl.includes('telengana') ? this.state.selectedUrl : `${Constants.SUPERSET_URL}/login` + "?auth_code=" + this.state.authCode + "&redirect=" + this.state.selectedUrl}
                                        position="fixed"
                                        allowfullscreen
                                        id="myId"
                                        className="myClassname"
                                        width={this.state.myWidth}
                                        height="100%"
                                    />}

                                {(Platform.OS === 'web' && this.state.selectedUrl != "" && this.state.authCode != "" &&
                                    this.state.selectedUrl.includes('360')) && <Iframe
                                        url={(this.state.selectedUrl.trim() + "/" + this.state.organizationID)}
                                        position="fixed"
                                        allowfullscreen
                                        id="myId"
                                        className="myClassname"
                                        width={this.state.myWidth}
                                        height="100%"
                                    />}

                            </ScrollView>
                        </View>
                    </View>


                    {/* <Iframe url="http://www.youtube.com/embed/xDMP3i36naA"
                        position="fixed"
                        backgroundColor='red'
                        width="80%"
                        id="myId"
                        className="myClassname"
                        height="80%"
                        styles={{ height: "25px" }} /> */}



                    {/* <WebView
                        useWebKit={true}
                        javaScriptEnabled={true}
                        source={{ uri: 'http://34.236.243.45:9088/superset/explore/druid/2/?r=7' }}
                        style={{ backgroundColor: 'red', width: '100%', height: '100%' }}
                        startInLoadingState={true}
                    /> */}
                </View>

                {(this.state.isOpenReport) && 
                <TouchableOpacity onPress={() => {
                    this.setState({
                        isOpenReport: !this.state.isOpenReport
                    })
                }} activeOpacity={1} style={styles.bgOverly}>
                </TouchableOpacity>}
                {this.state.isOpenReport && 
                 <View style={{ width: 400, height: "100%"}}>
                 <View style={[styles.languageDropdownMain, { top: this.state.dropY, left: this.state.dropX }]}>
                     <FlatList
                         scrollEnabled={true}
                         style={{height: '55vh'}}
                         showsVerticalScrollIndicator={true}
                         data={this.state.reportsList}
                         extraData={this.state}
                         renderItem={({ item, index }) => {

                             return (
                                 <TouchableOpacity
                                     onPress={() => {
                                         this.setState({
                                             selectedReport: (item.title == "" ? "Report" : item.title),
                                             isOpenReport: false,
                                             selectedUrl: item.url.trim()
                                         })
                                     }}
                                     style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                     <Text>{(item.title.length != 0 ? item.title : "Report")}</Text>
                                 </TouchableOpacity>
                             )
                         }}
                     />
                 </View>
             </View>
                }

            </View >

        )
    }
}


const Header = (props) => {
    try {
        return <View style={{ alignItems: 'center', padding: 10 }}>
            <Image
                style={{ height: 60, width: 60, marginLeft: 10, marginTop: 10, }}
                source={require(`./../../assets/images/${props.image}`)} />
        </View>
    } catch (error) {
        return <View style={{ alignItems: 'center', padding: 10 }}>
            <Image
                style={{ height: 100, width: 100, marginLeft: 10, marginTop: 10, }}
                source={require(`./../../assets/images/financial.png`)} />
        </View>
    }
}

Dashboard.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    languageBtn: {
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 250,
        borderRadius: 5,
        backgroundColor: Color.White,
        height: 40,
        marginTop: 5,
        marginRight: 20
    },
    bgOverly: {
        position: 'absolute',
        width: '100%',
        height: "100%",
        // opacity: .6,
        // backgroundColor: 'yellow'
    },
    languageDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 250,
        position: 'absolute',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 10,
    },



});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);





