//Email Validation
export const validateEmail = (email) => {
    let regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    return email.match(regex);
}

// Password Validation
export const validatePassword = (password) => {

    // var alpha = /^([a-zA-Z0-9]+)$/
    let length = password.length >= 6
    // return alpha.test(password) && length

    let regex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$_&-+-()/="':;?,.<>%^&*])[a-zA-Z0-9!@#$_&-+-()/="':;?,.<>%^&*]{8,50}$/
    return password.match(regex);


    // if (/^([a-zA-Z0-9]+)$/.test(password) && /\d/.test(password) &&
    //     /[A-Z]/i.test(password) && length) {
    //     return true
    // } else {
    //     return false
    // }

}

// OTP Validation
export const validateOtp = (otp) => {
    var regex = /^[a-zA-Z0-9]+$/
    let length = otp.length >= 6
    return regex.test(otp) && length
}

//Full Name Validation
export const validateCharacter = (fullName) => {
    let length = fullName.length <= 8
    if (/^([a-zA-Z0-9]+)$/.test(fullName) && length) {
        return true
    } else {
        return false
    }

}

export const validNumber = (fullName) => {
    if (/^([0-9]+)$/.test(fullName)) {
        return true
    } else {
        return false
    }
}

export const validMonthYear = (num) => {
    let regex = /^([0-9]+)$/
    let length = num.length == 2
    return regex.test(num) && length
}

export const validMonth = (num) => {
    let regex = /^([0-9]+)$/
    let length = num.length == 2
    return regex.test(num) && length && num <= 12 && num > 0

}

export const validCVV = (cvv) => {
    let regex = /^([0-9]+)$/
    let length = cvv.length == 3
    return regex.test(cvv) && length

}

//Phone Validation
export const validationPhone = (phone) => {
    let regex = /^(\+\d{1,3}[- ]?)?\d{10,13}$/
    let length = phone.length <= 13
    return regex.test(phone) && length
}

export const validName = (name) => {
    var regex = /^[a-zA-Z ]+$/
    return regex.test(name)

}

export const occupationName = (name) => {
    var regex = /^[a-zA-Z ]*$/
    return regex.test(name)
}


export const isSamePassword = (pass1, pass2) => {
    return pass1 === pass2
}

export const isValidValue = (value) => {
    if (typeof (value) == "undefined" || value == "" || value == null) {
        return false
    }
    return true
}