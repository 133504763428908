import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator
} from 'react-native';
import Label from '../components/Label'
import TextBox from '../components/textbox';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Color } from '../utils/color';
import { validateEmail } from '../utils/validation';
import { StdAlert } from './../components/Alert.web'
import { Constants } from "../utils/constants";
import { Analytics } from '../components/Analytics';
//robert.pars@mailinator.com
//Robert@123
export default class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      emailID: "",
      isLoadning: false,
      originalWidth: Dimensions.get("window").width,

    }
  }

  handleText = (name) => {
    return (text) => {
      this.setState({ [name]: text.replace(/\s/g, '') })
    }
  }

  validation = () => {
    var message = "";
    if (!validateEmail(this.state.emailID)) {
      message = "Please enter valid emailid"
    }
    return message;

  }

  forgotPasswordAPI = async () => {

    let messageAlert = this.validation()

    if (messageAlert.length > 0) {
      const alertTitle = 'CXSphere'
      const alertText = messageAlert
      if (Platform.OS === 'web') {
        StdAlert(alertTitle, alertText, () => {
          console.log('OK Pressed');
        })
      } else {

        Alert.alert(
          alertTitle,
          alertText,
          [
            { text: 'OK', onPress: () => console.log('OK Pressed') },
          ],
          { cancelable: false },
        );
      }
      return;
    }

    this.setState({
      isLoadning: true
    })

    try {

      let url = `${Constants.BASE_URL}${Constants.FORGOT_PASSWORD}`

      let response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": this.state.emailID
        }),
      });

      this.setState({
        isLoadning: false
      })

      if (response.ok) {
        let responseJson = await response.json();

        if (responseJson && responseJson.code == "Success") {

          if (Platform.OS === 'web') {
            StdAlert('CXSphere', responseJson.message, () => {
              this.props.navigation.goBack()
            })
          } else {
            Alert.alert(
              'CXSphere',
              responseJson.message,
              [
                {
                  text: 'OK', onPress: () => {
                    this.props.navigation.goBack()
                  }
                },
              ],
              { cancelable: false },
            );
          }

        } else {
          if (Platform.OS === 'web') {
            alert(responseJson.message)
          } else {
            Alert.alert("CXSphere", responseJson.message)
          }
        }

      } else {
        alert("User is not found");
      }

    } catch (error) {
      this.setState({
        isLoadning: false
      })
    }

  }

  _handleLayout = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;
    this.setState({
      originalWidth: width
    })
    this.setState(() => ({ isWide: width >= 991 }));
  };


  render() {
    return (
      <SafeAreaView onLayout={this._handleLayout} style={{ flex: 1 }}>
        <Analytics />
        <View style={[styles.Container, { backgroundColor: this.state.originalWidth > 991 ? Color.WhiteLight : Color.White }]}>
          {this.state.originalWidth > 991 && <View style={{
            width: '100%',
            backgroundColor: Color.White,
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: '10%',
          }}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}
          {this.state.originalWidth < 991 && <View style={styles.mobileHeader}>
            <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
          </View>}

          <Label Barlow_SemiBold style={[styles.title, { display: (this.state.originalWidth < 991 ? 'none' : 'flex'), alignSelf: 'center', }]}>Forgot Password</Label>
          <View style={styles.boxRow}>
            <View style={[styles.login, { paddingTop: (this.state.originalWidth < 991 ? 20 : 0) }]}>
              <TextBox
                textStyle={{ backgroundColor: Color.White }}
                onChangeText={this.handleText("emailID")}
                onSubmitEditing={() => {
                  this.forgotPasswordAPI()
                }}
                placeholder="Email" />
              <TouchableOpacity onPress={this.forgotPasswordAPI} style={styles.button} >
                {!this.state.isLoadning && <Label style={{ fontSize: 14 }} Barlow_Regular color={Color.White}>Send</Label>}
                {this.state.isLoadning && <ActivityIndicator size="small" color="white" />}
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.props.navigation.goBack() }} style={styles.BackBtn} >
                <Image style={{ width: 14, height: 14, tintColor: Color.DarkGreen, marginRight: 2, }} source={require("../assets/images/back.png")} />
                <Label mt={(Platform.OS == 'android' ? -2 : 0)} font14Normal Barlow_Regular color={Color.DarkGreen}>Back to Login</Label>
              </TouchableOpacity>
            </View>
          </View>
        </View>

      </SafeAreaView >
    );
  }

}

ForgotPassword.navigationOptions = {
  header: null,
};
const base = StyleSheet.create({
  Container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mobileHeader: {
    paddingTop: (Platform.OS == 'ios' ? 20 : 40),
    paddingBottom: 20,
    width: '100%',
    justifyContent: 'center',
    alignItems: "center"
  },
  logoDesktop: {
    width: '100%',
    backgroundColor: Color.White,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '10%',
  },
  logoImgMobile: {
    display: 'none',
  },

  button: {
    alignItems: 'center',
    justifyContent: "center",
    backgroundColor: '#005961',
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    height: 45,
  },
  BackBtn: {
    marginTop: 25,
    flexDirection: 'row',
    alignItems: "center",
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
  },
  login: {
    width: 400,
    paddingLeft: 0,
    marginLeft: 'auto',
  },
  title: {
    fontSize: 36,
    color: Color.DarkGreen,
    textAlign: 'center',
    marginBottom: 50,
    marginTop: 50,
  },
  boxRow: {
    alignItems: 'center',
  },

});

const styles = createStyles(
  base,
  maxWidth(1024, {
    logoDesktop: {
      width: '100%',
      backgroundColor: Color.White,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: '10%',
    },
    title: {
      fontSize: 30,
      color: Color.DarkGreen,
      textAlign: 'center',
      marginBottom: 32,
    },

  }),

  maxWidth(991, {

    logoImgMobile: {
      alignItems: 'center',
      paddingBottom: 25,
      paddingTop: 50,
    },
    title: {
      // display: 'none',
    },
    login: {
      width: Dimensions.get("window").width - 30,
      paddingLeft: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);