import { Constants } from "../utils/constants";


export const postData = async(params)  => {
    let queryString = `query=${params.keyword}&`;
    queryString += `country=${params.country}&`;
    queryString += `lang=${params.language}&`;
    queryString += `resolution=${params.resolution}&`;
    queryString += `geo_state=${params.geo_state}&`;
    queryString += `geo_country=${params.geo_country}&`;
    queryString += `from_date=${params.startDate}&`;
    queryString += `to_date=${params.endDate}`


    return await fetch(
        `${Constants.CONTEXT_API}/context/keywords/${params.organization_id}?${queryString}`, {
      method: 'POST',
      body: JSON.stringify({
          query: params.keyword,
          country: params.country,
          lang:params.language,
          resolution: params.resolution,
          geo_state:params.geo_state,
          geo_country: params.geo_country,
          from_date: params.startDate,
          to_date: params.endDate
      })
    });
}

export const scraperData = async (params, search_id) => {
  return await fetch(
    `${Constants.BASE_URL}/data-request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + params.userToken
    },
    body: JSON.stringify({
      keywords: params.keyword && params.keyword.split(','),
      download_limit: parseInt(params.download_limit),
      country: params.country,
      language: params.language,
      search_id
    })
  });
}

export const getReportData = async (options) => {
  const userToken = options.userToken;
  delete options.userToken;
  let response = await fetch(`${Constants.CONTEXT_API}/context/keywords/${options.org_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + userToken
    }
  });
  let responseJson = await response.json();
  return responseJson;
}
