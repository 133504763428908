import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, FlatList, Text, View,
    Image, Dimensions, TouchableOpacity, Platform, TextInput } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import MobileHeader from '../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from '../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../components/Label';
import Upload from '../components/Upload';
import UploadFraudDetection from '../components/UploadFraudDetection';
import * as FileSystem from 'expo-file-system';
import { ScrollView } from 'react-native-gesture-handler';
import {Button} from '@ui-kitten/components';
import { Analytics } from '../components/Analytics';
import moment from 'moment'
import { FontAwesome } from '@expo/vector-icons';
import { DataTable } from 'react-native-paper';
import transcriptionData from '../data/transcriptionHistory.json'


export default class FraudDetectionScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null,
            data: [],
            authCode: '',
            result: null,
            uploadTime: '',
            finishTime: '',
            fileName: ''
        }
    }
    
    async componentWillMount() {
        const authCode = await AsyncStorage.getItem('authCode');
        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName,
            data: transcriptionData,
            authCode
        })

    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

 downloadText = () => {
    const blob = new Blob([this.state.result], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    
    a.href = url;
    a.download = `${this.state.fileName}-cxsphere-Fraud Analysis.txt`;

    a.click();

    URL.revokeObjectURL(url);
  };

  saveResultData = (e) =>{
    // Save time in state instead of recalculating on render updates
    // const uploadTime = moment(e.result.upload_time).local().format('ddd, DD MMM YYYY HH:mm:ss A')
    // const finishTime = moment(e.result.finish_time).local().format('ddd, DD MMM YYYY HH:mm:ss A')

    this.setState({
        result: e.result,
        uploadTime: null,
        finishTime: null, 
        fileName: e.fileName
    })
  }

  bytesToMB = (bytes) =>{
    const MB = 1024 * 1024
    const sizeinMB = (bytes / MB).toFixed(2)

    return sizeinMB
  }


    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((a) => a[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>

                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Fraud Detection" />}
                    <View style={{ flexDirection: 'row', flex: 1}}>
                        {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                            <SideMenu {...this.props} />
                        </View>}
                    
                        <View style={[styles.containBox, { width: this.state.myWidth }]}>
                            {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                                width: this.state.myWidth,
                                justifyContent: "center",
                                alignItems: 'center'
                            }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Fraud Detection</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                            </View>}
                            <MenuSettings 
                                {...this.props} 
                                isOpen={this.state.isOpen} 
                                myWidth={this.state.myWidth} 
                                onClick={() =>this.setState({ isOpen: false})} 
                            />
                            <View style={{margin:15, width: 100}}>
                                <Button size={'tiny'} 
                                onPress={() => this.props.navigation.navigate("Engage")}
                                style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                            </View>

                            <View style={styles.buttonContainer}>
                                <Text style={{ paddingLeft: 15, fontSize: 20, color: Color.DarkGreen}}> Upload a Report </Text>
                                    <UploadFraudDetection type={'fraud-uploader'} authCode={this.state.authCode} getData={(e)=>this.saveResultData(e)}/>
                                <View style={{ paddingLeft: 20, flex:1, flexDirection:'column'}}>

                                {this.state.result &&
                                    <>
                                        <View style={{flexDirection: 'row', width: 700, justifyContent: 'space-between', marginRight: 10, alignItems: 'flex-end', marginBottom: 10}}>
                                            <Text style={{paddingLeft: 10, fontSize: 18, color: Color.DarkGreen}}>Result</Text>
                                            <Text style={{paddingLeft: 20, fontSize: 13, color: Color.DarkGreen, width: 300, alignItems: 'center', textAlign: 'center'}} numberOfLines={1} lineBreakMode='middle'>{this.state.fileName}</Text>
                                            <Button 
                                                size='tiny'
                                                onPress={() => this.downloadText()}>
                                                Download
                                            </Button>
                                        </View>

                                        {/* 
                                        <View style={{backgroundColor: 'white', flexWrap: 'wrap', borderWidth: 1, borderColor: 'lightgray', borderStyle: 'solid', padding: 15, marginLeft: 10, marginRight: 10, marginBottom: 10, borderRadius: 10, minHeight: 50, width: 700}}>
                                            <Text style={{fontSize: 13}}>File size: {this.bytesToMB(this.state.result.file_size)} MB</Text>
                                            <Text style={{fontSize: 13}}>Upload time: {this.state.uploadTime}</Text>
                                            <Text style={{fontSize: 13}}>Finish time: {this.state.finishTime}</Text>
                                        </View> 
                                        */}

                                        <TextInput
                                        multiline={true}
                                        value={this.state.result || 'No data to process.'}
                                        style={{backgroundColor: 'white', borderWidth: 1, borderColor: 'lightgray', borderStyle: 'solid', padding: 15, marginLeft: 0, marginRight: 10, borderRadius: 10, height: 500, width: 700}}
                                        />
                                    </>
                                }    

                                {/* <Text style={{paddingLeft: 20, fontSize: 30, color: Color.DarkGreen}}>Previous searches</Text>
                                <ScrollView style={{paddingLeft: 20}}>
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>sl no.</DataTable.Title>
                                        <DataTable.Title>File Name</DataTable.Title>
                                        <DataTable.Title>Date</DataTable.Title>
                                        <DataTable.Title style={{alignItems:'center'}}>Options selected</DataTable.Title>
                                        <DataTable.Title style={{alignItems:'center'}}>Result</DataTable.Title>
                                    </DataTable.Header>
                                    { this.state.data.map((item, index) => 
                                        <DataTable.Row>
                                            <DataTable.Cell>{item.id}</DataTable.Cell>
                                            <DataTable.Cell>{item.file_name}</DataTable.Cell>
                                            <DataTable.Cell>{item.date}</DataTable.Cell>
                                            <DataTable.Cell>
                                                <View style={{flexWrap: 'wrap'}}>
                                                {item.options?.map(option=>{
                                                    return (
                                                    <View style={{backgroundColor: 'royalblue', paddingLeft: 5, paddingRight: 5, alignSelf: 'flex-start', borderRadius: 10, margin: 5, alignItems: 'center'}}>
                                                        <Text style={{fontSize: 12, color:'white', fontWeight: '500'}}>
                                                            {option}
                                                        </Text>
                                                    </View>)
                                                })}
                                                </View>
                                            </DataTable.Cell>
                                            <DataTable.Cell>
                                                <Button 
                                                    size='tiny'
                                                    // style={{backgroundColor: Color.DarkGreen}} 
                                                    onPress={() => console.log('pressed', item.result)}>Download</Button>
                                            </DataTable.Cell>
                                        </DataTable.Row>
                                        )}
                                </DataTable>
                            </ScrollView>  */}

                                </View> 
                            </View>            
                        </View>
                    </View>
            </View >
        )
    }
}

FraudDetectionScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding: 0,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start'
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
