import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export class cusscoAdmin extends Component {
    render () {
        return (
            <div>
                <div className="aupanel" style={{ bottom: 0, right: 0}}>
                <Helmet>
                <script type='module' src="https://cdn.jsdelivr.net/gh/faizan124a/chf/cusscoAdmin.js" async defer ></script>
                </Helmet>
            </div>
            </div>
        );
      }
}

export default cusscoAdmin