import React, { Component } from 'react'
import { ScrollView, StyleSheet, FlatList, View,
    Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import { Select, SelectItem, Text as Label, Input, Button, Card, Spinner, Icon } from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment';
import { Color } from '../../../utils/color';
import MobileHeader from '../../../components/MobileHeader';
import MenuSettings from '../../../components/MenuSettings';
import SideMenu from '../../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import countryJson from '../../../data/country.json';
import countryNamesJson from '../../../data/countryNames.json';
import { Analytics } from '../../../components/Analytics';
import { getProfile, updateProfile, createProfile } from '../../../services/Profile';
const genderList = ['Male', 'FeMale', 'TransGender'];
const genderMap = {'M': 0, 'F': 1, 'T': 2};
const CalendarIcon = (props) => (
    <Icon {...props} name='calendar'/>
);

export default class ProfileScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            org_id: '',
            org_name: '',
            email:'',
            line1:'',
            line2:'',
            city: '',
            state:'',
            country:'',
            postal_code: '',
            isError: false,
            isLoaded: false,
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            isEdit: false,
            isSubmitLoading: false,
            isSubmitted: false,
            openFile : false,
            organization_id: null,
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null
        }
    }
    async componentWillMount() {

        const fullName = await AsyncStorage.getItem('fullName');
        const organization_id = await AsyncStorage.getItem('organizationID');
        const org_name = await AsyncStorage.getItem('organizationName');
        const email = await AsyncStorage.getItem('email');
        this.setState({
            fullName,
            organization_id,
            org_id: organization_id,
            org_name,
            email
        })
        
        const data = await getProfile({org_id: organization_id});
        if(data.isError === true) {
            this.setState({isLoaded: true, isError:data.isError});     
        } else {
            this.setState(data.profile, () => {
                this.setState({isLoaded: true})
            });     
        }
    }

    setComplete = (isSubmitLoading) => {
        this.setState({isSubmitLoading}, () => this.submitData());
    }

    submitData = async () => {
        const data = this.state;
        const response = this.state.id ? await updateProfile(data) :  await createProfile(data);
        if(response.isError === true) {
            this.setState({isLoaded: true, isError:data.isError});     
        } else {
            this.setState({isSubmitLoading:false, isSubmitted:true});
        }
    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    openSystem = () => {
        this.setState({
            openFile:true
        })
    }

    handleKycChange = (e) => {
        this.setState({gov_id: e.target.value});
    };

    handleLine1Change = (e) => {
        this.setState({line1: e.target.value});
    };

    handleLine2Change = (e) => {
        this.setState({line2: e.target.value});
    };

    handlePostalCodeChange = (e) => {
        this.setState({postal_code: e.target.value});
    };

    handleCityChange = (e) => {
        this.setState({city: e.target.value});
    };

    handleStateChange = (e) => {
        this.setState({state: e.target.value});
    };

    setSelectedCountryIndex = (index) => {
        const getCode = (name) => {
            const arrK = Object.keys(countryNamesJson);
            const arrV = Object.values(countryNamesJson);
            let code = '';
            for (let i=0;i<arrV.length;i++) {
                if (name === arrV[i]) {
                    code = arrK[i];
                }
            }
            return code;
        }
        let country = getCode(countryJson[index - 1]);
        this.setState({country});
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Profile" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Profile</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../../assets/images/small_down.png")} />
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        /> 
                            {this.state.isError === true && 
                                <Card><Label category='p1' status='danger'>
                                    There is a problem with profile Information.  
                                </Label></Card>
                            }
                            { this.state.isSubmitted === false && 
                            <ScrollView> 
                             <View style={{flexDirection:'row'}}>
                                {this.state.isLoaded === false && this.state.isEdit === false &&  <Card
                                    header={<Label category='h5'>My Profile Information</Label>}>
                                        <Spinner size={'30'} status={'primary'} ></Spinner>     
                                </Card> }
                                { this.state.isEdit === false && this.state.isLoaded === true && <Card 
                                header={
                                    <View style={{flexDirection:'row'}}>
                                            <Label category='h5'>
                                                    My Profile Information
                                                    <View style={{ marginLeft:100}}><Button style={{width:100}} status='danger' size='tiny' 
                                                    onPress={() => this.setState({isEdit: true})}>Edit Profile</Button></View>
                                            </Label>
                                            
                                    </View>
                                } 
                                style={{ margin: 40, width: '60%'}}>
                                <View style={{width:'30%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Organization Name</Label>
                                    <Label category='p2'>{this.state.org_name}</Label>
                                </View>
                                <View style={{width:'30%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>My Name</Label>
                                    <Label category='p2'>{this.state.fullName}</Label>
                                </View>
                                <View style={{width:'30%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Email</Label>
                                    <Label category='p2'>{this.state.email}</Label>
                                </View>
                                <View style={{width:'80%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Address Line 1</Label>
                                    <Label category='p2'>{this.state.line1 || '--'}</Label>    
                                </View>
                                <View style={{width:'80%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Address Line 2</Label>
                                    <Label category='p2'>{this.state.line2 || '--'}</Label>    
                                </View>
                                <View style={{width:'80%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>City</Label>
                                    <Label category='p2'>{this.state.city || '--'}</Label>    
                                </View>
                                <View style={{width:'80%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>State</Label>
                                    <Label category='p2'>{this.state.state || '--'}</Label>    
                                </View>
                                <View style={{width:'80%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Country</Label>
                                    <Label category='p2'>{this.state.country || '--'}</Label>    
                                </View>
                                <View style={{width:'80%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Postal Code</Label>
                                    <Label category='p2'>{this.state.postal_code || '--'}</Label>    
                                </View>
                                </Card> }
                                { this.state.isEdit ===  true && <Card 
                                header={<Label category='h5'>My Profile Information</Label>} 
                                style={{ margin: 40, width: '60%'}}
                                footer={<View style={{flexDirection:'row'}}>
                                    <Button style={{width:100, marginBottom: 10}} 
                                    onPress={() => { 
                                        this.setComplete(true);
                                    }
                                }>submit</Button>
                                {this.state.isSubmitLoading === true && <Spinner size={'20'}></Spinner> }
                                </View>}>
                                <View style={{width:'30%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:10}}>Organization Name</Label>
                                    <Label category='p2'>{this.state.org_name}</Label>
                                </View>
                                <View style={{width:'30%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>My Name</Label>
                                    <Label category='p2'>{this.state.fullName}</Label>
                                </View>
                                <View style={{width:'30%', padding:10}}>
                                    <Label category='s1' style={{marginBottom:5}}>Email</Label>
                                    <Label category='p2'>{this.state.email}</Label>
                                </View>
                                <View style={{width:'50%', padding:10}}>
                                    <Label style={{marginBottom:10}}>Address Line 1</Label>
                                    <Input value={this.state.line1} onChange={this.handleLine1Change} placeholder={'Address Line 1'} />    
                                </View>
                                <View style={{width:'50%', padding:10}}>
                                    <Label style={{marginBottom:10}}>Address Line 2</Label>
                                    <Input value={this.state.line2} onChange={this.handleLine2Change} placeholder={'Address Line 2'} />    
                                </View>
                                <View style={{width:'20%', padding:10}}>
                                    <Label style={{marginBottom:10}}>City</Label>
                                    <Input value={this.state.city} onChange={this.handleCityChange} placeholder={'City'} />    
                                </View>
                                <View style={{width:'20%', padding:10}}>
                                    <Label style={{marginBottom:10}}>State</Label>
                                    <Input value={this.state.state} onChange={this.handleStateChange} placeholder={'State in 2 Chars'} maxLength={2} />    
                                </View>
                                <View style={{width:'30%', padding:10}}>
                                    <Label style={{marginBottom:10}}>Country</Label>
                                        <Select
                                            value={this.state.country}
                                            placeholder={'Select Country'}
                                            onSelect={index => this.setSelectedCountryIndex(index)}>
                                            {countryJson.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                </View>
                                <View style={{width:'30%', padding:10}}>
                                    <Label style={{marginBottom:10}}>Postal Code</Label>
                                    <Input value={this.state.postal_code} onChange={this.handlePostalCodeChange} placeholder={'Postal Code'} />    
                                </View>
                                </Card> }
                              </View>
                            </ScrollView> 
                            }
                            {this.state.isSubmitted === true && 
                            <Card 
                            header={<Label category='h5'>My Profile Information</Label>} 
                            style={{ margin: 40, width: '60%'}}>
                                <View style={{flexDirection:'row'}}>
                                    <Label> Profile updated successfully</Label>
                                    <View style={{ marginLeft:100}}>
                                        <Button style={{width:100}} status='danger' size='tiny' 
                                            onPress={() => window.location.reload()}>Go Back</Button>
                                    </View>
                                </View>
                            </Card>
                            }
                    </View>
                </View>
            </View >
        )
    }
}

ProfileScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
