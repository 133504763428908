import React from 'react'
import { StyleSheet, FlatList, View, Image, Dimensions, TouchableOpacity, Platform, Alert } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../../utils/color';
import MobileHeader from './../../components/MobileHeader';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import SideMenu from './../../components/SideMenu';
import MenuSettings from '../../components/MenuSettings';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import { Select, SelectItem, Input, List, Divider, ListItem, Card, Text } from '@ui-kitten/components';
import Label from './../../components/Label';
import TextBox from './../../components/textbox';
import { isIphoneX } from './../../utils/isIphone-x'
const HEADER_SIZE = isIphoneX() ? 100 : 70;
import { StdAlert } from './../../components/Alert.web'
import DateTimePicker from "react-native-modal-datetime-picker";
import DateInput from './../../components/Input.web';
import { Constants } from './../../utils/constants';
import { Entypo } from '@expo/vector-icons';
import { Analytics } from '../../components/Analytics';
import {Button} from '@ui-kitten/components';
var moment = require('moment');



const groupDetails = [
    {
        "id": 1,
        "group_id": 1,
        "name": "All"
    }

]

export default class AddInformationRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: ["1", "2", "3", "4",],
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            isOpen: false,
            isOpenAge: false,
            isOpenGender: false,
            isOpenLanguage: false,
            isGroupContainDetail: false,
            isOpenLocation: false,
            isOpenLanguageContains: false,
            isGroupContains: false,


            isOpenLocationContainsFirst: false,
            isOpenLocationContainsSecond: false,
            isOpenFilter: false,
            isOpenFilterSourceFirst: false,
            isOpenFilterContainsSecond: false,
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),
            originalWidth: Dimensions.get("window").width,
            popUpX: 0,
            popUpY: 0,
            popUpWidth: 0,
            popUpHeight: 100,


            regionsData: [],
            countryData: [],
            ageRangeData: [],
            genderData: [],
            languagesData: [],
            groupData: [],
            groupDetails: [],
            income:[],
            behaviourData: [],
            behaviourName:'',
            behaviour: [],
            containBehaviourProfile:'Contains',
            valueBehaviourProfile: '',
            incomeRange: '',
            contextualProfile:[],
            contextualProfileData:[],
            level1ContextualProfile: {id:'', name:'', items:[]},
            level2ContextualProfile: {id:'', name:'', items:[]},
            valueContextualProfile: '',
            containContextualProfile:'Contains',
            containsData: ["Contains", "Does not Contain", "Equals", "Does not Equal", "geq", "leq"],
            groupDetaisView:[],
            groupDetailsValues:'',
            valueGroupProfile: '',
            containGroupProfile:'Contains',
            selectedLocationData: [],
            selectedAgeData: [],
            selectedGenderData: [],

            selectedLanguageValues: [],
            languageOperator: "Contains",

            groupName: "",
            groupID: 0,
            selectedGroupValues: [],
            groupProfile:[],
            locationType: "Country",
            locationOperator: "Contains",
            locationOperatorTwo: "Contains",
            selectedIndex: 0,
            sampleNo: '',
            title: '',
            description: '',

            source: [],

            fullName: "",

            isDateTimePickerVisible: false,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(10,'M').format('YYYY-MM-DD'),
            closingDate: moment().add(11, 'M').format('YYYY-MM-DD'),

            requestData: {
                "ageRange": [],
                "gender": [],
                "languages": {
                    "operator": "",
                    "value": ""
                },
                "contextualProfile": [],
                "groups": [],
                "income": "",
                "behaviour": [],
                "locations": {
                    "locationType": "",
                    "condition": {
                        "operator": "",
                        "value": ""
                    }
                },
                "filters": {
                    "conditionalOperator": "",
                    "source": [
                        {
                            "name": "",
                            "condition": {
                                "operator": "",
                                "value": ""
                            }
                        }
                    ]
                }
            }
        }
    }

    logWelcomeLayout(ox, oy, width, height, px, py) {

        this.setState({
            popUpX: px,
            popUpY: py,
            popUpWidth: width
        })
    }
    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));

    };

    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.getCountryData()
            this.getRegions()

        }

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName: fullName
        })
    }



    // API CALLING

    getRegions = async () => {

        const userToken = await AsyncStorage.getItem('userToken');

        try {

            let url = `${Constants.BASE_URL}${Constants.REGIONS}`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            if (typeof responseJson != "undefined") {
                if (responseJson.length > 0) {
                    this.setState({
                        regionsData: responseJson
                    }, () => {
                        console.log("******** regionsData ********")
                    })
                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            //console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }
    }

    getCountryData = async () => {

        const userToken = await AsyncStorage.getItem('userToken');

        try {

            let url = `${Constants.BASE_URL}${Constants.INFO_REQUEST}`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            if (typeof responseJson != "undefined") {

                this.setState({
                    countryData: responseJson.countries,
                    ageRangeData: responseJson.ageRange,
                    languagesData: responseJson.languages,
                    income:responseJson.income,
                    contextualProfileData: responseJson.contextualProfile,
                    behaviourData: responseJson.behavior,
                    genderData: responseJson.gender,
                    groupData: responseJson.groups || [],
                    groupDetails: responseJson.groupDetails || []
                }, () => {
                    console.log("******** countryData ********")
                })

            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }
    }

    //["Contains", "Does not Contain", "Equals", "Does not Equal"]
    enumForContains = (item) => {
        switch (item) {
            case "Contains":
                return "contains"
            case "Does not Contain":
                return "not_contains"
            case "Equals":
                return "equals"
            case "Does not Equal":
                return "not_equals"
            default:
                return ""
                break;
        }
    }

    enumForLocation = (item) => {
        switch (item) {
            case "Country":
                return "country"
            default:
                return "region"
                break;
        }
    }

    submitClick = async () => {


        let mainSourceData = this.state.source.map((item) => {
            return {
                "name": item.name,
                "condition": {
                    "operator": this.enumForContains(item.condition.operator),
                    "value": (item.condition.operator == "Contains" || item.condition.operator == "Does not Contain") ? item.condition.value : [item.condition.value]
                }
            }
        })

        let requestData = {};

        if (this.state.selectedAgeData.length > 0) {
            requestData["ageRange"] = this.state.selectedAgeData
        }
        if (this.state.selectedGenderData.length > 0) {
            requestData["gender"] = this.state.selectedGenderData
        }
        if (this.state.selectedLanguageValues.length > 0) {
            requestData["languages"] = {
                "operator": this.enumForContains(this.state.languageOperator),
                "value": (this.state.languageOperator == "Contains" || this.state.languageOperator == "Does not Contain" ? this.state.selectedLanguageValues[0] : this.state.selectedLanguageValues) || ""
            }
        }
        if (this.state.selectedLocationData.length > 0) {
            requestData["locations"] = {
                "locationType": this.enumForLocation(this.state.locationType),
                "condition": {
                    "operator": this.enumForContains(this.state.locationOperator),
                    "value": (this.state.locationOperator == "Contains" || this.state.locationOperator == "Does not Contain" ? this.state.selectedLocationData[0] : this.state.selectedLocationData) || ""
                }
            }
        }
        if (this.state.sampleNo.length > 0) {
            requestData["sampleSize"] = this.state.sampleNo
        }
        if (mainSourceData.length > 0) {
            requestData["filters"] = {
                "conditionalOperator": (mainSourceData.length == 0 ? "" : "AND"),
                "source": mainSourceData
            }
        }
        if (this.state.selectedGroupValues.length > 0) {
            requestData["groups"] = [
                {
                    "name": this.state.groupName,
                    "details": this.state.selectedGroupValues
                }
            ]
        }

        var message = ""


        console.log("===== requestData =====", requestData)

        if (this.state.startDate.length > 0) {
            requestData["startDate"] = this.state.startDate
        } else {
            message = 'Please provide start Date';
        }

        if (this.state.endDate.length > 0) {
            requestData["endDate"] = this.state.endDate
        } else {
            message = 'Please provide end Date';
        }

        if (this.state.closingDate.length > 0) {
            requestData["closingDate"] = this.state.closingDate
        } else {
            message = 'Please provide closing Date';
        }


        if (this.state.sampleNo.length == 0) {
            message = "Please enter Sample number."
        }

        if (this.state.title.trim().length == 0) {
            message = "Please enter Title."
        } else{
            requestData['title'] = this.state.title.trim();
        }

        
        requestData['description'] = this.state.description;

        requestData.income = this.state.incomeRange;
        requestData.behaviour = this.state.behaviour, 
        requestData.contextualProfile = this.state.contextualProfile;
        requestData.groups = this.state.groupProfile;
        if (message.length > 0) {

            if (Platform.OS === 'web') {
                alert(message)
            } else {
                Alert.alert("CXSphere", message)
            }
            return;

        }


        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/information-request`

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify(requestData),
            });
            let responseJson = await response.json();

            if (responseJson == 200) {

                if (Platform.OS === 'web') {
                    StdAlert('CXSphere', "Your information request has been successfully submitted. We will get back to you", () => {
                        this.props.navigation.navigate("InformationRequest")
                    })
                } else {
                    Alert.alert(
                        'CXSphere',
                        "Your information request has been successfully submitted. We will get back to you",
                        [
                            {
                                text: 'OK', onPress: () => {
                                    this.props.navigation.goBack(null)
                                }
                            },
                        ],
                        { cancelable: false },
                    );
                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }
        } catch (error) {
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }

    }

    handleInputChange = (text) => {
        if (/^\d+$/.test(text) || text === '') {
            this.setState({
                sampleNo: text
            });
        }
    }

    handleTitleInputChange = (text) => {
            this.setState({
                title: text
            });
    }

    handleDescriptionInputChange = (text) => {
        this.setState({
            description: text
        });
}

    showDateTimePicker = () => {
        this.setState({ isDateTimePickerVisible: true });
    };

    hideDateTimePicker = () => {
        this.setState({ isDateTimePickerVisible: false });
    };

    handleDatePickedStart = date => {
        this.setState({
            startDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    };

    handleDatePickedClosing = date => {
        this.setState({
            closingDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    }

    handleDatePickedEnd = date => {
        this.setState({
            endDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    };

    setgroupIndex = index => {
        const data = this.state.groupData[index - 1];
        this.setState({
            groupName: data.name,
            groupID:data.id,
            groupDetailsValues: ''
        })
    }

    setIncomeIndex = index => {
        const data = this.state.income[index - 1];
        this.setState({
            incomeRange: data.income
        })
    }

    setLevel1ContextualProfileIndex = index => {
        const data = this.state.contextualProfileData[index - 1];
        this.setState({
            level1ContextualProfile: {
              ...this.state.level1ContextualProfile,
              ...data
            },
            level2ContextualProfile: {
                id: '', name: '', items: []
            }
        });
    }

    setLevel2ContextualProfileIndex = index => {
        let data = this.state.level1ContextualProfile.items[index - 1];
        data = data.name ? data : { name:data, id:'', items:[] }
        this.setState({
            level2ContextualProfile: {
              ...this.state.level2ContextualProfile,
              ...data
            }
        });
    }

    handleContextualChange = (e) => {
        const valueContextualProfile = e.target.value;
        this.setState({valueContextualProfile});
    };

    handleBehaviourChange = (e) => {
        const valueBehaviourProfile = e.target.value;
        this.setState({valueBehaviourProfile});
    };

    handleGroupChange = (e) => {
        const valueGroupProfile = e.target.value;
        this.setState({valueGroupProfile});
    };

    handleContextualClick = () => {
        let data = this.state.contextualProfile;
        if(!this.state.valueContextualProfile.trim() || !this.state.containContextualProfile.trim()) return;
        const object = { 
            "condition" : {
            "operator" : this.enumForContains(this.state.containContextualProfile),
            "value" : this.state.valueContextualProfile
            }
        }

        const level2 = {
            [this.state.level2ContextualProfile.name|| 'default'] : object
        }
        
        const level1 = {
            [this.state.level1ContextualProfile.name|| 'default'] : level2
        }

        data.push(level1);

        this.setState({
            contextualProfile: data 
        })
    }

    handleBehaviourClick = () => {
        let data = this.state.behaviour;
        data.push({
            'name' : this.state.behaviourName,
            'condition': {
                'operator': this.enumForContains(this.state.containBehaviourProfile),
                'value': this.state.valueBehaviourProfile
            }
        });

        this.setState({
            behaviour: data 
        })
    }

    handleGroupClick = () => {
        let data = this.state.groupProfile;
        const object = {
            "name": this.state.groupName, 
            "details": [this.state.groupDetailsValues],
        }
        data.push(object);
        this.setState({
            groupProfile: data 
        })
    }

    handleGroupProfileRemove = (index) => {
        const {groupProfile} = this.state;
        let list = [...groupProfile];
        list.splice(index,1);
        this.setState({groupProfile:list});
    }

    renderGroupProfileAccessory = (index) => (
        <Entypo name="trash" size={24} color="black" onPress={() => this.handleGroupProfileRemove(index)}/>
    )

    renderGroupProfileItem = ({ item, index }) => (
        <ListItem
          title={`${JSON.stringify(item)}`}
          accessoryRight={() => this.renderGroupProfileAccessory(index)}
        />
    );

    handleContextualProfileRemove = (index) => {
        const {contextualProfile} = this.state;
        let list = [...contextualProfile];
        list.splice(index,1);
        this.setState({contextualProfile:list});
    }

    handleBehaviourProfileRemove = (index) => {
        const {behaviour} = this.state;
        let list = [...behaviour];
        list.splice(index,1);
        this.setState({behaviour:list});
    }

    renderContextualProfileAccessory = (index) => (
        <Entypo name="trash" size={24} color="black" onPress={() => this.handleContextualProfileRemove(index)}/>
    )

    renderContextualProfileItem = ({ item, index }) => (
        <ListItem
          title={`${JSON.stringify(item)}`}
          accessoryRight={() => this.renderContextualProfileAccessory(index)}
        />
    );
    
    renderBehaviourProfileAccessory = (index) => (
        <Entypo name="trash" size={24} color="black" onPress={() => this.handleBehaviourProfileRemove(index)}/>
    )

    renderBehaviourProfileItem = ({ item, index }) => (
        <ListItem
          title={`${JSON.stringify(item)}`}
          accessoryRight={() => this.renderBehaviourProfileAccessory(index)}
        />
    );
    
    setContainGroupProfileIndex = index => {
        let data = this.state.containsData[index - 1];
        this.setState({
          containGroupProfile: data
        });
    }

    setContainContextualProfileIndex = index => {
        let data = this.state.containsData[index - 1];
        this.setState({
          containContextualProfile: data
        });
    }

    setContainBehaviourProfileIndex = index => {
        let data = this.state.containsData[index - 1];
        this.setState({
          containBehaviourProfile: data
        });
    }

    setBehaviourIndex = index => {
        const data = this.state.behaviourData[index - 1];
        this.setState({
            behaviourName: data.behavior 
        })
    }

    setSelectedContainDetailsIndex = index => {
        const groupDetails = [];
        this.state.groupDetails.map((item) => {
            if(item.group_id === this.state.groupID) {
                groupDetails.push(item);
            }
        });
        const data = groupDetails[index - 1];
        this.setState({
            groupDetailsValues: data.name
        })
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Information Request" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={{ width: this.state.myWidth }}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Information Request</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}

                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>

                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.props.navigation.navigate("InformationRequest")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                        <View style={{
                            width: this.state.originalWidth >= 767 ? this.state.myWidth : this.state.myWidth,
                            alignSelf: "center",
                            // paddingTop: (this.state.originalWidth >= 767 ? 20 : 0),
                            // paddingBottom: (this.state.originalWidth >= 767 ? 20 : 0),
                            height: (this.state.originalWidth >= 767 ? Dimensions.get("window").height - 170 : Platform.select({
                                web: Dimensions.get("window").height - 0,
                                default: Dimensions.get("window").height - 250,
                            })),
                            // backgroundColor: "red"
                        }}>



                            <KeyboardAwareScrollView
                                showsVerticalScrollIndicator={true}>
                                <View style={{
                                    width: this.state.originalWidth >= 767 ? this.state.myWidth : this.state.myWidth,
                                    alignSelf: "center",
                                    paddingLeft: 20,
                                    paddingTop: (this.state.originalWidth >= 767 ? 20 : 0),
                                    paddingBottom: (this.state.originalWidth >= 767 ? 20 : 0),
                                }}>
                                
                                <Card header={<Text category='label'>Request Information</Text>} style={{ marginBottom:20, width: '95%'}}>    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Title*</Label>
                                        <TextBox
                                            placeholder="Enter Title"
                                            value={this.state.title}
                                            onChangeText={this.handleTitleInputChange}
                                            viewStyle={{ alignSelf: "flex-start", }} textStyle={{
                                                alignSelf: "flex-start",
                                                borderWidth: 1,
                                                borderColor: Color.DarkGreen,
                                                borderRadius: 5,
                                                backgroundColor: Color.White,
                                                marginBottom: 0,
                                                width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2))
                                            }} />
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Description</Label>
                                        <TextBox
                                            placeholder="Description"
                                            value={this.state.description}
                                            numberOfLines={8}
                                            multiline
                                            onChangeText={this.handleDescriptionInputChange}
                                            viewStyle={{ alignSelf: "flex-start", }} textStyle={{
                                                alignSelf: "flex-start",
                                                borderWidth: 1,
                                                borderColor: Color.DarkGreen,
                                                borderRadius: 5,
                                                backgroundColor: Color.White,
                                                marginBottom: 0,
                                                width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2))
                                            }} />
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                        }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Sample Size*</Label>

                                        <TextBox
                                            placeholder="Enter Sample Size"
                                            keyboardType='numeric'
                                            value={this.state.sampleNo}
                                            onChangeText={this.handleInputChange}
                                            viewStyle={{ alignSelf: "flex-start", }} textStyle={{
                                                alignSelf: "flex-start",
                                                borderWidth: 1,
                                                borderColor: Color.DarkGreen,
                                                borderRadius: 5,
                                                backgroundColor: Color.White,
                                                marginBottom: 0,
                                                width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2))
                                            }} />

                                    </View>

                                    {/***  Date Picker ***/}
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Start Date*</Label>

                                        {Platform.OS == 'web' && <DateInput
                                            width={(this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2))}
                                            value={this.state.startDate}
                                            inputProps={{ readOnly: true }}
                                            handleDayChange={(date) => this.handleDatePickedStart(date)} />}


                                        {Platform.OS != 'web' && <TouchableOpacity onPress={this.showDateTimePicker} style={{
                                            borderWidth: 1,
                                            borderColor: Color.DarkGreen,
                                            borderRadius: 5,
                                            backgroundColor: Color.White,
                                            width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2)),
                                            height: 40,
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}>

                                            <Label
                                                font14Normal
                                                Barlow_Medium
                                                color={Color.BlackLight}
                                                style={{ width: 120 }}>{this.state.startDate}</Label>
                                        </TouchableOpacity>}


                                        {Platform.OS != 'web' && <DateTimePicker
                                            isVisible={this.state.isDateTimePickerVisible}
                                            onConfirm={this.handleDatePickedStart}
                                            onCancel={this.hideDateTimePicker}
                                        />}

                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>End Date*</Label>

                                        {Platform.OS == 'web' && <DateInput
                                            width={(this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2))}
                                            value={this.state.endDate}
                                            inputProps={{ readOnly: true }}
                                            handleDayChange={(date) => this.handleDatePickedEnd(date)} />}

                                        {Platform.OS != 'web' && <TouchableOpacity onPress={this.showDateTimePicker} style={{
                                            borderWidth: 1,
                                            borderColor: Color.DarkGreen,
                                            borderRadius: 5,
                                            backgroundColor: Color.White,
                                            width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2)),
                                            height: 40,
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}>

                                            <Label
                                                font14Normal
                                                Barlow_Medium
                                                color={Color.BlackLight}
                                                style={{ width: 120 }}>{this.state.endDate}</Label>
                                        </TouchableOpacity>}


                                        {Platform.OS != 'web' && <DateTimePicker
                                            isVisible={this.state.isDateTimePickerVisible}
                                            onConfirm={this.handleDatePickedStart}
                                            onCancel={this.hideDateTimePicker}
                                        />}

                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Closing Date*</Label>

                                        {Platform.OS == 'web' && <DateInput
                                            width={(this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2))}
                                            value={this.state.closingDate}
                                            inputProps={{ readOnly: true }}
                                            handleDayChange={(date) => this.handleDatePickedClosing(date)} />}

                                        {Platform.OS != 'web' && <TouchableOpacity onPress={this.showDateTimePicker} style={{
                                            borderWidth: 1,
                                            borderColor: Color.DarkGreen,
                                            borderRadius: 5,
                                            backgroundColor: Color.White,
                                            width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2)),
                                            height: 40,
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}>

                                            <Label
                                                font14Normal
                                                Barlow_Medium
                                                color={Color.BlackLight}
                                                style={{ width: 120 }}>{this.state.closingDate}</Label>
                                        </TouchableOpacity>}


                                        {Platform.OS != 'web' && <DateTimePicker
                                            isVisible={this.state.isDateTimePickerVisible}
                                            onConfirm={this.handleDatePickedClosing}
                                            onCancel={this.hideDateTimePicker}
                                        />}

                                    </View>
                                </Card> 
                                <Card header={<Text category='label'>Demographic Information</Text>} style={{ marginBottom:20, width: '95%'}}>                
                                    {/** AGE  - GENDER **/}
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'column' : 'row'),
                                        justifyContent: "space-around",
                                        alignItems: (this.state.originalWidth >= 767 ? "flex-start" : "center"),

                                    }}>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: (this.state.originalWidth >= 767 ? 10 : 0)
                                        }}>
                                            <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                                alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                                marginTop: (this.state.originalWidth >= 767 ? 0 : 12),
                                                marginBottom: (this.state.originalWidth >= 767 ? 0 : 8),
                                                width: 110,
                                                textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                                marginRight: 20,
                                            }}>Age</Label>
                                            <TouchableOpacity ref="ageRefs" onPress={() => {

                                                this.refs.ageRefs.measure((ox, oy, width, height, px, py) => {

                                                    this.setState({
                                                        popUpX: (this.state.originalWidth >= 767 ? ox : px),
                                                        popUpY: (this.state.originalWidth >= 767 ? py : py - 70),
                                                        popUpWidth: width,
                                                        popUpHeight: height
                                                    })
                                                });

                                                this.setState({
                                                    isOpenAge: true
                                                })
                                            }} style={[styles.ageBtn, { justifyContent: "center", width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2)) }]}>
                                                <Label numberOfLines={1} sigleLine={true} mr={20} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{this.state.selectedAgeData.length > 0 ? this.state.selectedAgeData.map(item => item).join(", ") : "Select"}</Label>
                                                <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                            </TouchableOpacity>
                                        </View>

                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: (this.state.originalWidth >= 767 ? 10 : 0)


                                        }}>
                                            <Label
                                                font14Normal
                                                Barlow_Medium
                                                color={Color.BlackLight}
                                                style={{
                                                    alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                                    marginTop: (this.state.originalWidth >= 767 ? 0 : 12),
                                                    marginBottom: (this.state.originalWidth >= 767 ? 0 : 8),
                                                    width: 110,
                                                    textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                                    marginRight: 20,
                                                }}>Gender</Label>
                                            <TouchableOpacity ref="genderRefs" onPress={() => {

                                                this.refs.genderRefs.measure((ox, oy, width, height, px, py) => {


                                                    this.setState({
                                                        popUpX: (this.state.originalWidth >= 767 ? ox : px),
                                                        popUpY: (this.state.originalWidth >= 767 ? py : py - 70),
                                                        popUpWidth: width,
                                                        popUpHeight: height
                                                    })
                                                });
                                                this.setState({
                                                    isOpenGender: true
                                                })
                                            }} style={[styles.ageBtn, { justifyContent: "center", width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2)) }]}>
                                                <Label numberOfLines={1} sigleLine={true} mr={20} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{this.state.selectedGenderData.length > 0 ? this.state.selectedGenderData.map(item => item).join(", ") : "Select"}</Label>
                                                <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                            </TouchableOpacity>
                                        </View>

                                    </View>

                                    {/* Language*/}

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: (this.state.originalWidth >= 767 ? 'center' : 'flex-start'),
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{ textAlign: (this.state.originalWidth >= 767 ? 'right' : 'left'), marginRight: 20, width: 110, marginTop: (this.state.originalWidth >= 767 ? 0 : 12), paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10), paddingRight: (this.state.originalWidth >= 767 ? 0 : 10) }}>Language</Label>

                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                            <TouchableOpacity ref="containLanguage" onPress={() => {

                                                this.refs.containLanguage.measure((ox, oy, width, height, px, py) => {


                                                    this.setState({
                                                        popUpX: (this.state.originalWidth >= 767 ? 370 : ox),
                                                        popUpY: (this.state.originalWidth >= 767 ? py : py),
                                                        popUpWidth: width,
                                                        popUpHeight: height
                                                    }, () => {

                                                        this.setState({
                                                            isOpenLanguageContains: true
                                                        })
                                                    })



                                                });


                                            }} style={styles.containsBtn}>
                                                <Label Barlow_Medium x12Small color={Color.GreyDarkColor}>{this.state.languageOperator}</Label>
                                                <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                            </TouchableOpacity>


                                            <TouchableOpacity ref="languageRefs" onPress={() => {

                                                this.refs.languageRefs.measure((ox, oy, width, height, px, py) => {

                                                    this.setState({
                                                        popUpX: (this.state.originalWidth >= 767 ? 300 + width : ox),
                                                        popUpY: (this.state.originalWidth >= 767 ? py : py),
                                                        popUpWidth: width,
                                                        popUpHeight: height
                                                    }, () => {

                                                        this.setState({
                                                            isOpenLanguage: true
                                                        })
                                                    })
                                                });

                                                this.setState({
                                                    isOpenLanguage: true
                                                })
                                            }} style={[styles.ageBtn, { justifyContent: "center", width: (this.state.originalWidth >= 767 ? 230 : ((this.state.myWidth - 30) / 2)) }]}>
                                                <Label numberOfLines={1} sigleLine={true} mr={20} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{this.state.selectedLanguageValues.length > 0 ? this.state.selectedLanguageValues.map(item => item).join(", ") : "Select"}</Label>
                                                <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                            </TouchableOpacity>
                                        </View>


                                    </View>

                                    {/* Income */}
                                    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: (this.state.originalWidth >= 767 ? 'center' : 'flex-start'),
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                    }}>
                                        <Label
                                            font14Normal
                                            Barlow_Medium
                                            color={Color.BlackLight}
                                            style={{
                                                textAlign: (this.state.originalWidth >= 767 ? 'right' : 'left'),
                                                marginRight: 20,
                                                width: (this.state.originalWidth >= 767 ? 110 : 150),
                                                marginTop: (this.state.originalWidth >= 767 ? 0 : 12),
                                                paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                                paddingRight: (this.state.originalWidth >= 767 ? 0 : 10)
                                            }}>Income</Label>

                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        <Select
                                            style={styles.containsBtn} 
                                            value={this.state.incomeRange}
                                            placeholder={'Income'}
                                            onSelect={index => this.setIncomeIndex(index)}>
                                            {this.state.income.map((item) => <SelectItem title={item.income} />)}        
                                        </Select>
                                        </View>
                                    </View>

                                    {/* LOCATION*/}

                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: (this.state.originalWidth >= 767 ? 'center' : 'flex-start'),
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{ textAlign: (this.state.originalWidth >= 767 ? 'right' : 'left'), marginRight: 20, width: 110, marginTop: (this.state.originalWidth >= 767 ? 0 : 12), paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10), paddingRight: (this.state.originalWidth >= 767 ? 0 : 10) }}>Location</Label>

                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 991 ? 'row' : 'column'),
                                            justifyContent: (this.state.originalWidth >= 991 ? 'center' : 'flex-start'),
                                            alignItems: (this.state.originalWidth >= 991 ? 'center' : 'flex-start'),
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                            <View style={{
                                                flexDirection: 'row',
                                                marginBottom: (this.state.originalWidth >= 991 ? 0 : 10),
                                            }}>
                                                <TouchableOpacity ref="containLocation" onPress={() => {

                                                    this.refs.containLocation.measure((ox, oy, width, height, px, py) => {

                                                        // console.log("ox: " + ox);
                                                        // console.log("oy: " + oy);
                                                        // console.log("width: " + width);
                                                        // console.log("height: " + height);
                                                        // console.log("px: " + px);
                                                        // console.log("py: " + py);

                                                        this.setState({
                                                            popUpX: (this.state.originalWidth >= 767 ? 370 : ox + 10),
                                                            popUpY: (this.state.originalWidth >= 767 ? py : py),
                                                            popUpWidth: width,
                                                            popUpHeight: height
                                                        }, () => {

                                                            this.setState({
                                                                isOpenLocationContainsFirst: true
                                                            })
                                                        })


                                                    });


                                                }} style={styles.containsBtn}>
                                                    <Label Barlow_Medium x12Small color={Color.GreyDarkColor}>{this.state.locationType}</Label>
                                                    <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                                </TouchableOpacity>

                                                <TouchableOpacity ref="containSecondLocation" onPress={() => {

                                                    this.refs.containSecondLocation.measure((ox, oy, width, height, px, py) => {

                                                        this.setState({
                                                            popUpX: (this.state.originalWidth >= 767 ? 390 + width : ox + 10),
                                                            popUpY: (this.state.originalWidth >= 767 ? py : py),
                                                            popUpWidth: width,
                                                            popUpHeight: height
                                                        }, () => {

                                                            this.setState({
                                                                isOpenLocationContainsSecond: true
                                                            })
                                                        })


                                                    });


                                                }} style={styles.containsBtn}>
                                                    <Label Barlow_Medium x12Small color={Color.GreyDarkColor}>{this.state.locationOperator}</Label>
                                                    <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 19, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                                </TouchableOpacity>
                                            </View>
                                            <TouchableOpacity ref="locationRefs" onPress={() => {

                                                this.refs.locationRefs.measure((ox, oy, width, height, px, py) => {

                                                    // console.log("ox: " + ox);
                                                    // console.log("oy: " + oy);
                                                    // console.log("width: " + width);
                                                    // console.log("height: " + height);
                                                    // console.log("px: " + px);
                                                    // console.log("py: " + py);


                                                    this.setState({
                                                        popUpX: (this.state.originalWidth >= 767 ? px : ox),
                                                        popUpY: (this.state.originalWidth >= 767 ? py : py),
                                                        popUpWidth: width,
                                                        popUpHeight: height
                                                    }, () => {

                                                        this.setState({
                                                            isOpenLocation: true
                                                        })
                                                    })
                                                });

                                                this.setState({
                                                    isOpenLocation: true
                                                })
                                            }} style={[styles.ageBtn, { justifyContent: "center", width: (this.state.originalWidth >= 767 ? 230 : 280) }]}>
                                                <Label numberOfLines={1} sigleLine={true} mr={20} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{this.state.selectedLocationData.length > 0 ? this.state.selectedLocationData.map(item => item).join(", ") : "Select"}</Label>
                                                <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </Card>     
                                <Card header={<Text category='label'>Behaviour Information</Text>} style={{ marginBottom:20, width: '95%'}}>           
                                    {/* Contextual Profile  */}
                                    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: (this.state.originalWidth >= 767 ? 'center' : 'flex-start'),
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                    }}>
                                        <Label
                                            font14Normal
                                            Barlow_Medium
                                            color={Color.BlackLight}
                                            style={{
                                                textAlign: (this.state.originalWidth >= 767 ? 'right' : 'left'),
                                                marginRight: 20,
                                                width: (this.state.originalWidth >= 767 ? 110 : 150),
                                                marginTop: (this.state.originalWidth >= 767 ? 0 : 12),
                                                paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                                paddingRight: (this.state.originalWidth >= 767 ? 0 : 10)
                                            }}>Contextual Profile</Label>

                                        <View style={{flexDirection:'column'}}>    
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        <Select
                                            style={styles.containsProfileBtn} 
                                            value={this.state.level1ContextualProfile.name}
                                            placeholder={'Level 1 Profiles'}
                                            onSelect={index => this.setLevel1ContextualProfileIndex(index)}>
                                            {this.state.contextualProfileData.map((item) => <SelectItem title={item.name} />)}        
                                        </Select>
                                        
                                        {this.state.level1ContextualProfile.items.length > 0 && <Select
                                            style={styles.containsProfileBtn} 
                                            value={this.state.level2ContextualProfile.name}
                                            placeholder={'Level 2 Profiles'}
                                            onSelect={index => this.setLevel2ContextualProfileIndex(index)}>
                                            {this.state.level1ContextualProfile.items.map((item) => <SelectItem title={item.name ? item.name : item} />)}        
                                        </Select>}
                                        {this.state.level1ContextualProfile.name.length > 0 && <Select
                                            style={styles.containsProfileBtn} 
                                            value={this.state.containContextualProfile}
                                            placeholder={'Contains'}
                                            onSelect={index => this.setContainContextualProfileIndex(index)}>
                                            {this.state.containsData.map((item) => <SelectItem title={item} />)}        
                                        </Select>}    
                                        {this.state.level1ContextualProfile.name.length > 0 && <Input onChange={this.handleContextualChange} placeholder={'probability'} />}
                                        { this.state.level1ContextualProfile.name.length > 0 && <Entypo name="add-to-list" size={24} color="black" style={{padding:10}} onPress={this.handleContextualClick}/> }   
                                        </View>
                                        <View style={{marginTop:10}}>
                                        <List
                                            data={this.state.contextualProfile}
                                            ItemSeparatorComponent={Divider}
                                            renderItem={this.renderContextualProfileItem}
                                        />
                                        </View>
                                        </View>
                                    </View>

                                    {/* Behaviour */}
                                    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: (this.state.originalWidth >= 767 ? 'center' : 'flex-start'),
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                    }}>
                                        <Label
                                            font14Normal
                                            Barlow_Medium
                                            color={Color.BlackLight}
                                            style={{
                                                textAlign: (this.state.originalWidth >= 767 ? 'right' : 'left'),
                                                marginRight: 20,
                                                width: (this.state.originalWidth >= 767 ? 110 : 150),
                                                marginTop: (this.state.originalWidth >= 767 ? 0 : 12),
                                                paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                                paddingRight: (this.state.originalWidth >= 767 ? 0 : 10)
                                            }}>Behaviour</Label>
                                        <View style={{flexDirection:'column'}}>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        <Select
                                            style={styles.containsBtn} 
                                            value={this.state.behaviourName}
                                            placeholder={'Behaviour'}
                                            onSelect={index => this.setBehaviourIndex(index)}>
                                            {this.state.behaviourData.map((item) => <SelectItem title={item.behavior} />)}        
                                        </Select>
                                        {this.state.behaviourName.length > 0 && <Select
                                            style={styles.containsProfileBtn} 
                                            value={this.state.containBehaviourProfile}
                                            placeholder={'Contains'}
                                            onSelect={index => this.setContainBehaviourProfileIndex(index)}>
                                            {this.state.containsData.map((item) => <SelectItem title={item} />)}        
                                        </Select>}    
                                        {this.state.behaviourName.length > 0 && <Input onChange={this.handleBehaviourChange} placeholder={'value'} />}
                                        { this.state.behaviourName.length > 0 && <Entypo name="add-to-list" size={24} color="black" style={{padding:10}} onPress={this.handleBehaviourClick}/> } 
                                        </View>
                                        <View style={{marginTop:10}}>
                                        <List
                                            data={this.state.behaviour}
                                            ItemSeparatorComponent={Divider}
                                            renderItem={this.renderBehaviourProfileItem}
                                        />
                                        </View>
                                        </View>
                                    </View>
                                </Card>            
                                <Card header={<Text category='label'>Information Request</Text>} style={{ marginBottom:20, width: '95%'}}>            
                                    {/* GROUPS */}
                                    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: (this.state.originalWidth >= 767 ? 'center' : 'flex-start'),
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                    }}>
                                        <Label
                                            font14Normal
                                            Barlow_Medium
                                            color={Color.BlackLight}
                                            style={{
                                                textAlign: (this.state.originalWidth >= 767 ? 'right' : 'left'),
                                                marginRight: 20,
                                                width: (this.state.originalWidth >= 767 ? 110 : 150),
                                                marginTop: (this.state.originalWidth >= 767 ? 0 : 12),
                                                paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                                paddingRight: (this.state.originalWidth >= 767 ? 0 : 10)
                                            }}>Information Required</Label>
                                        <View style={{flexDirection:'column'}}>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        <Select
                                            style={styles.containsBtn} 
                                            value={this.state.groupName}
                                            placeholder={'Category'}
                                            onSelect={index => this.setgroupIndex(index)}>
                                            {this.state.groupData.map((item) => <SelectItem title={item.name} />)}        
                                        </Select>
                                        <Select
                                            style={styles.containsDetailBtn} 
                                            value={this.state.groupDetailsValues}
                                            placeholder={'Fields'}
                                            onSelect={index => this.setSelectedContainDetailsIndex(index)}>
                                            {this.state.groupDetails.map((item) => 
                                            {
                                                return item.group_id == this.state.groupID && (
                                                    <SelectItem title={item.name} />
                                                ) 
                                            } )}        
                                        </Select>
                                        { this.state.groupID > 0 && <Entypo name="add-to-list" size={24} color="black" style={{padding:10}} onPress={this.handleGroupClick}/> }
                                        </View>
                                        <View style={{marginTop:10}}>
                                        <List
                                            data={this.state.groupProfile}
                                            ItemSeparatorComponent={Divider}
                                            renderItem={this.renderGroupProfileItem}
                                        />
                                        </View>
                                        </View>
                                    </View>
                                </Card>

                                    {this.state.source.map((item, index) => {
                                        return (
                                            <View style={[styles.filterBoxMain, { marginLeft: (this.state.originalWidth >= 767 ? 140 : 10), marginTop: (this.state.originalWidth >= 767 ? 20 : 15), }]}>
                                                <View style={[styles.listRow, { flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column') }]}>

                                                    <View style={{ flexDirection: "row" }}>
                                                        <TouchableOpacity onPress={(evt) => {


                                                            let mainHeight = (this.state.originalWidth >= 767 ? Dimensions.get("window").height - 170 : Platform.select({
                                                                web: Dimensions.get("window").height - 150,
                                                                default: Dimensions.get("window").height - 250,
                                                            }))

                                                            // console.log(" === Total Height ===", Dimensions.get("window").height);

                                                            // console.log(" === Main Height ===", mainHeight);

                                                            // console.log("=== Page Y ===", evt.nativeEvent.pageY);

                                                            // console.log(" === Location Y ===", evt.nativeEvent.locationY);

                                                            let paddingPoup = evt.nativeEvent.pageY - evt.nativeEvent.locationY > (Dimensions.get("window").height - HEADER_SIZE - Platform.select({
                                                                web: 100,
                                                                default: 84,
                                                            }) - 100) ? 100 : 0


                                                            this.setState({
                                                                popUpX: this.state.originalWidth >= 767 ? 140 : 10,
                                                                popUpY: (this.state.originalWidth >= 767 ? evt.nativeEvent.pageY - 100 + (44 - evt.nativeEvent.locationY) : Platform.select({
                                                                    web: evt.nativeEvent.pageY - 170 + (44 - evt.nativeEvent.locationY),
                                                                    default: evt.nativeEvent.pageY - 170 + (15 - evt.nativeEvent.locationY),
                                                                })) - paddingPoup,
                                                                popUpWidth: 140,
                                                                popUpHeight: 100
                                                            }, () => {
                                                                this.setState({
                                                                    isOpenFilterSourceFirst: true,
                                                                    selectedIndex: index
                                                                })
                                                            })



                                                        }} style={styles.containsBtn}>
                                                            <Label Barlow_Regular font14Normal style={{ color: '#6E6E6E', }}>{item.name.length > 0 ? item.name : "Source"}</Label>
                                                            <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                                        </TouchableOpacity>

                                                        <TouchableOpacity onPress={(evt) => {



                                                            let mainHeight = (this.state.originalWidth >= 767 ? Dimensions.get("window").height - 170 : Platform.select({
                                                                web: Dimensions.get("window").height - 150,
                                                                default: Dimensions.get("window").height - 250,
                                                            }))

                                                            // console.log(" === Total Height ===", Dimensions.get("window").height);

                                                            // console.log(" === Main Height ===", mainHeight);

                                                            // console.log("=== Page Y ===", evt.nativeEvent.pageY);

                                                            // console.log(" === Location Y ===", evt.nativeEvent.locationY);

                                                            let paddingPoup = evt.nativeEvent.pageY - evt.nativeEvent.locationY > (Dimensions.get("window").height - HEADER_SIZE - Platform.select({
                                                                web: 100,
                                                                default: 84,
                                                            }) - 100) ? 200 : 0



                                                            this.setState({
                                                                popUpX: this.state.originalWidth >= 767 ? 300 : 170,
                                                                popUpY: (this.state.originalWidth >= 767 ? evt.nativeEvent.pageY - 100 + (44 - evt.nativeEvent.locationY) :
                                                                    Platform.select({
                                                                        web: evt.nativeEvent.pageY - 170 + (44 - evt.nativeEvent.locationY),
                                                                        default: evt.nativeEvent.pageY - 170 + (15 - evt.nativeEvent.locationY),
                                                                    })
                                                                ) - paddingPoup,
                                                                popUpWidth: 140,
                                                                popUpHeight: 100
                                                            }, () => {
                                                                this.setState({
                                                                    isOpenFilterContainsSecond: true,
                                                                    selectedIndex: index

                                                                })
                                                            })


                                                        }} style={styles.containsBtn}>
                                                            <Label Barlow_Regular font14Normal style={{ color: '#6E6E6E', }}>{item.condition.operator}</Label>
                                                            <Image style={{ height: 7, width: 11, position: "absolute", right: 10, top: 18, alignItems: "center", tintColor: Color.DarkBlackGrey }} source={require("./../../assets/images/small_down.png")} />
                                                        </TouchableOpacity>

                                                    </View>

                                                    <View style={{ flexDirection: "row", alignItems: 'center', height: 44, marginTop: (this.state.originalWidth >= 767 ? 0 : 10), }}>
                                                        <TextBox onChangeText={(text) => {
                                                            this.state.source[index].condition.value = text
                                                        }} textStyle={[styles.inputBox, { width: (this.state.originalWidth >= 767 ? 200 : 140), marginBottom: 0, alignSelf: 'center' }]} />

                                                        <TouchableOpacity style={styles.btnBox}><View style={{ width: 20, height: 2, backgroundColor: Color.DarkBlackGrey }}></View></TouchableOpacity>
                                                        <TouchableOpacity style={styles.btnBox}><Label Barlow_Regular x12Small>OR</Label></TouchableOpacity>
                                                        <TouchableOpacity style={styles.btnBox}><Label Barlow_Regular x12Small>AND</Label></TouchableOpacity>
                                                    </View>
                                                </View>
                                            </View>
                                        )
                                    })}



                                </View>
                            </KeyboardAwareScrollView>
                        </View>



                        <TouchableOpacity onPress={this.submitClick} style={[styles.submitBtn, {
                            justifyContent: this.state.originalWidth >= 767 ? 'center' : 'center',
                            marginTop: 15,
                            marginLeft: this.state.originalWidth >= 767 ? 140 : 10
                        }]}>
                            <Label Barlow_SemiBold font14Normal color={Color.White}>Submit</Label>
                        </TouchableOpacity>

                        {/** AGE **/}

                        {(this.state.isOpenAge) && <TouchableOpacity onPress={() => {
                            this.setState({
                                isOpenAge: false
                            })
                        }} activeOpacity={1} style={styles.bgOverly}>
                            <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                                <FlatList
                                    style={{ height: 200 }}
                                    showsVerticalScrollIndicator={true}
                                    data={this.state.ageRangeData}
                                    extraData={this.state}
                                    renderItem={({ item, index }) => {

                                        let isSelected = this.state.selectedAgeData.filter(item1 => {
                                            return item1 == item.range
                                        }).length > 0

                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (isSelected) {
                                                        let filteredArray = this.state.selectedAgeData.filter(item1 => {
                                                            return item1 != item.range
                                                        })
                                                        this.state.selectedAgeData = filteredArray
                                                    } else {
                                                        this.state.selectedAgeData.push(item.range)
                                                    }
                                                    this.setState({})
                                                }}
                                                style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', alignItem: "center", flexDirection: 'row' }}>
                                                <Image
                                                    style={{ height: 15, width: 15, marginRight: 10, marginTop: 1 }}
                                                    source={isSelected ? require("../../assets/images/checked.png") : require("../../assets/images/unchecked.png")} />
                                                <Text>{item.range}</Text>
                                            </TouchableOpacity>
                                        )
                                    }}

                                />
                            </View>

                        </TouchableOpacity>}

                        {/** GENDER **/}

                        {(this.state.isOpenGender) && <TouchableOpacity onPress={() => {
                            this.setState({
                                isOpenGender: false
                            })
                        }} activeOpacity={1} style={styles.bgOverly}>
                            <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                                <FlatList
                                    showsVerticalScrollIndicator={true}
                                    data={this.state.genderData}
                                    extraData={this.state}
                                    renderItem={({ item, index }) => {

                                        let isSelected = this.state.selectedGenderData.filter(item1 => {
                                            return item1 == item.gender
                                        }).length > 0

                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (isSelected) {
                                                        let filteredArray = this.state.selectedGenderData.filter(item1 => {
                                                            return item1 != item.gender
                                                        })
                                                        this.state.selectedGenderData = filteredArray
                                                    } else {
                                                        this.state.selectedGenderData.push(item.gender)
                                                    }
                                                    this.setState({})
                                                }}
                                                style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                                <Image
                                                    style={{ height: 15, width: 15, marginRight: 10, marginTop: 1, }}
                                                    source={isSelected ? require("../../assets/images/checked.png") : require("../../assets/images/unchecked.png")} />
                                                <Text>{item.gender}</Text>
                                            </TouchableOpacity>
                                        )
                                    }}
                                />
                            </View>
                        </TouchableOpacity>}


                        {(this.state.isOpenFilterSourceFirst) && <TouchableOpacity onPress={() => {
                            this.setState({
                                isOpenFilterSourceFirst: false
                            })
                        }} activeOpacity={1} style={styles.bgOverly}>
                            <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                                <FlatList
                                    showsVerticalScrollIndicator={true}
                                    data={["Facebook", "Linkedin",]}
                                    extraData={this.state}
                                    renderItem={({ item, index }) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.state.source[this.state.selectedIndex].name = item
                                                    this.setState({
                                                        isOpenFilterSourceFirst: false,
                                                        selectedIndex: index
                                                    })
                                                }}
                                                style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                                <Text>{item}</Text>
                                            </TouchableOpacity>
                                        )
                                    }}
                                />
                            </View>
                        </TouchableOpacity>}

                        {(this.state.isOpenFilterContainsSecond) && <TouchableOpacity onPress={() => {
                            this.setState({
                                isOpenFilterContainsSecond: false
                            })
                        }} activeOpacity={1} style={styles.bgOverly}>
                            <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                                <FlatList
                                    style={{ height: 200 }}
                                    showsVerticalScrollIndicator={false}
                                    data={this.state.containsData}
                                    extraData={this.state}
                                    renderItem={({ item, index }) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.state.source[this.state.selectedIndex].condition.operator = item
                                                    this.setState({
                                                        locationOperatorTwo: item,
                                                        isOpenFilterContainsSecond: false,
                                                        selectedIndex: index
                                                    })
                                                }}
                                                style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                                <Text>{item}</Text>
                                            </TouchableOpacity>
                                        )
                                    }}
                                />
                            </View>
                        </TouchableOpacity>}

                    </View>
                </View>


                {/** LANGUAGE Contain **/}

                {
                    (this.state.isOpenLanguageContains) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isOpenLanguageContains: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                style={{ height: 200 }}
                                showsVerticalScrollIndicator={true}
                                data={this.state.containsData}
                                extraData={this.state}
                                renderItem={({ item, index }) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({
                                                    languageOperator: item,
                                                    isOpenLanguageContains: false
                                                })
                                            }}
                                            style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Text>{item}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                }

                {/** GROUP **/}

                {
                    (this.state.isGroupContains) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isGroupContains: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                style={{ height: 100 }}
                                showsVerticalScrollIndicator={true}
                                data={this.state.groupData}
                                extraData={this.state}
                                renderItem={({ item, index }) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({
                                                    groupName: item.name,
                                                    groupID: item.id,
                                                    isGroupContains: false
                                                })
                                            }}
                                            style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Text>{item.name}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                }

                {/** LANGUAGE **/}

                {
                    (this.state.isOpenLanguage) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isOpenLanguage: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                style={{ height: 200 }}
                                showsVerticalScrollIndicator={true}
                                data={this.state.languagesData}
                                extraData={this.state}
                                renderItem={({ item, index }) => {

                                    let isSelected = this.state.selectedLanguageValues.filter(item1 => {
                                        return item1 == item.name
                                    }).length > 0

                                    return (
                                        <TouchableOpacity
                                            onPress={() => {

                                                if (this.state.languageOperator == "Contains" || this.state.languageOperator == "Does not Contain") {
                                                    this.state.selectedLanguageValues = [item.name]
                                                    this.setState({
                                                        isOpenLanguage: false
                                                    })
                                                } else {
                                                    if (isSelected) {
                                                        let filteredArray = this.state.selectedLanguageValues.filter(item1 => {
                                                            return item1 != item.name
                                                        })
                                                        this.state.selectedLanguageValues = filteredArray
                                                    } else {
                                                        this.state.selectedLanguageValues.push(item.name)
                                                    }
                                                }

                                                this.setState({})
                                            }}
                                            style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Image style={{ height: 15, width: 15, marginRight: 10, marginTop: 1, }}
                                                source={isSelected ? require("../../assets/images/checked.png") : require("../../assets/images/unchecked.png")} />
                                            <Text>{item.name}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />

                        </View>
                    </TouchableOpacity>
                }


                {/** Group Contain **/}

                {
                    (this.state.isGroupContainDetail) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isGroupContainDetail: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                style={{ height: 150 }}
                                showsVerticalScrollIndicator={true}
                                data={this.state.groupDetails.filter(item => {
                                    return item.group_id == this.state.groupID
                                })}
                                extraData={this.state}
                                renderItem={({ item, index }) => {

                                    let isSelected = this.state.selectedGroupValues.filter(item1 => {
                                        return item1 == item.name
                                    }).length > 0

                                    return (
                                        <TouchableOpacity
                                            onPress={() => {

                                                // if (this.state.languageOperator == "Contains" || this.state.languageOperator == "Does not Contain") {
                                                //     this.state.selectedGroupValues = [item.name]
                                                //     this.setState({
                                                //         isGroupContainDetail: false
                                                //     })
                                                // } else {
                                                if (isSelected) {
                                                    let filteredArray = this.state.selectedGroupValues.filter(item1 => {
                                                        return item1 != item.name
                                                    })
                                                    this.state.selectedGroupValues = filteredArray
                                                } else {
                                                    this.state.selectedGroupValues.push(item.name)
                                                }
                                                // }

                                                this.setState({})
                                            }}
                                            style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Image style={{ height: 15, width: 15, marginRight: 10, marginTop: 1, }}
                                                source={isSelected ? require("../../assets/images/checked.png") : require("../../assets/images/unchecked.png")} />
                                            <Text>{item.name}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />

                        </View>
                    </TouchableOpacity>
                }


                {
                    (this.state.isOpenLocation) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isOpenLocation: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                style={{ height: 200 }}
                                showsVerticalScrollIndicator={true}
                                data={(this.state.locationType == "Country" ? this.state.countryData : this.state.regionsData)}
                                extraData={this.state}
                                renderItem={({ item, index }) => {
                                    let isSelected = this.state.selectedLocationData.filter(item1 => {
                                        return item1 == item.name
                                    }).length > 0

                                    return (
                                        <TouchableOpacity
                                            onPress={() => {

                                                if (this.state.locationOperator == "Contains" || this.state.locationOperator == "Does not Contain") {
                                                    this.state.selectedLocationData = [item.name]
                                                    this.setState({
                                                        isOpenLocation: false
                                                    })
                                                } else {

                                                    if (isSelected) {
                                                        let filteredArray = this.state.selectedLocationData.filter(item1 => {
                                                            return item1 != item.name
                                                        })
                                                        this.state.selectedLocationData = filteredArray
                                                    } else {
                                                        this.state.selectedLocationData.push(item.name)
                                                    }
                                                }
                                                this.setState({})
                                            }}
                                            style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Image
                                                style={{ height: 15, width: 15, marginRight: 10, marginTop: 1, }}
                                                source={isSelected ? require("../../assets/images/checked.png") : require("../../assets/images/unchecked.png")} />
                                            <Text>{item.name}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                }

                {
                    (this.state.isOpenLocationContainsFirst) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isOpenLocationContainsFirst: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                showsVerticalScrollIndicator={true}
                                data={["Country", "Region"]}
                                extraData={this.state}
                                renderItem={({ item, index }) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({
                                                    selectedLocationData: [],
                                                    locationType: item,
                                                    isOpenLocationContainsFirst: false
                                                })
                                            }}
                                            style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Text>{item}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                }
                {
                    (this.state.isOpenLocationContainsSecond) && <TouchableOpacity onPress={() => {
                        this.setState({
                            isOpenLocationContainsSecond: false
                        })
                    }} activeOpacity={1} style={styles.bgOverly}>
                        <View style={{ width: this.state.popUpWidth, marginLeft: this.state.popUpX, marginTop: this.state.popUpY + this.state.popUpHeight, backgroundColor: Color.White, }}>
                            <FlatList
                                showsVerticalScrollIndicator={true}
                                data={this.state.containsData}
                                extraData={this.state}
                                renderItem={({ item, index }) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({
                                                    locationOperator: item,
                                                    isOpenLocationContainsSecond: false
                                                })
                                            }}
                                            style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2', flexDirection: "row", alignItems: "center" }}>
                                            <Text>{item}</Text>
                                        </TouchableOpacity>
                                    )
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                }

            </View >

        )
    }
}

AddInformationRequest.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
        overflow: "hidden"
    },

    mobileRowAge: {
        flexDirection: 'column',
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRowMain: {
        flexDirection: "row",
        alignSelf: 'flex-start',
        alignItems: "baseline",
        marginBottom: 20,
    },
    ageBtn: {
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        borderRadius: 5,
        backgroundColor: Color.White,
        padding: 10,
        height: 44,
    },
    languageBtn: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 304,
        borderRadius: 5,
        backgroundColor: Color.White,
    },
    containsBtn: {
        paddingLeft: 10,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 180,
        borderRadius: 5,
        backgroundColor: Color.White,
        marginRight: 20,
        height: 44,
        justifyContent: 'center',
    },
    containsProfileBtn: {
        paddingLeft: 10,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 180,
        borderRadius: 5,
        backgroundColor: Color.White,
        marginRight: 20,
        height: 44,
        justifyContent: 'center',
    },
    containsDetailBtn: {
        paddingLeft: 10,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 200,
        borderRadius: 5,
        backgroundColor: Color.White,
        marginRight: 20,
        height: 44,
        justifyContent: 'center',
    },
    sideBerLeft: {
        width: 230,
    },
    ageDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        position: 'absolute',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    genderDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 230,
        position: 'absolute',
        left: 128,
        top: 256,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    languageDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 304,
        position: 'absolute',
        left: 256,
        top: 319,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    locationDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 304,
        position: 'absolute',
        left: 384,
        top: 382,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    languageContainsDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 110,
        position: 'absolute',
        left: 128,
        top: 320,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    locationContainsFirst: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 110,
        position: 'absolute',
        left: 128,
        top: 382,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    locationContainsSecond: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 110,
        position: 'absolute',
        left: 256,
        top: 382,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    filterBtn: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 100,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.White,
    },
    filterBoxMain: {
        width: '100%',
        marginTop: 20,
    },
    btnMain: {
        flexDirection: 'row',
        alignSelf: "flex-start",
        marginBottom: 15,
    },
    btn: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 92,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.White,
        marginRight: 10,
    },
    listRow: {
        marginBottom: 5,
        width: '100%',
        flexDirection: 'row',
    },
    listBtn: {
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: Color.BORDERCOLOR,
        width: 92,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.White,
        marginRight: 10,
        height: 44,
    },
    inputBox: {
        backgroundColor: Color.White,
    },
    btnBox: {
        width: 36,
        height: 26,
        backgroundColor: Color.White,
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: "center",
        marginBottom: 0,
    },
    submitMain: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    bgOverly: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        width: '100%',
        height: '100%',
    },
});
const styles = createStyles(
    base,
    maxWidth(991, {
        languageBtn: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 12,
            paddingBottom: 12,
            borderWidth: 1,
            borderColor: Color.DarkGreen,
            width: 250,
            borderRadius: 5,
            backgroundColor: Color.White,
            marginLeft: 18,
        },
        languageDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 250,
            position: 'absolute',
            left: 256,
            top: 319,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },

    }),
    maxWidth(767, {
        mobileRowAge: {
            flexDirection: 'row',
            alignItems: "center",
            justifyContent: "space-between",
        },
        boxRowMain: {
            flexDirection: "column"
        },
        listRow: {
            marginBottom: 5,
            width: '100%',
            flexDirection: 'row',
            // justifyContent: "flex-end"
        },
        inputBox: {
            backgroundColor: Color.White,
        },
        ageDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            position: 'absolute',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        ageBtn: {
            borderWidth: 1,
            borderColor: Color.DarkGreen,
            borderRadius: 5,
            backgroundColor: Color.White,
            padding: 10,
        },
        languageBtn: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 12,
            paddingBottom: 12,
            borderWidth: 1,
            borderColor: Color.DarkGreen,
            width: '100%',
            borderRadius: 5,
            backgroundColor: Color.White,
            marginLeft: 0,
            marginBottom: 10,
        },

        genderDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: "70%",
            position: 'absolute',
            right: -110,
            top: 102,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        languageDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 247,
            right: 0,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        locationDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 304,
            position: 'absolute',
            left: 0,
            top: 435,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        languageContainsDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 110,
            position: 'absolute',
            left: 0,
            top: 194,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        locationContainsFirst: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 110,
            position: 'absolute',
            left: 0,
            top: 330,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        locationContainsSecond: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 110,
            position: 'absolute',
            left: 0,
            top: 382,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
    }),

    maxWidth(480, {
        listRow: {
            marginBottom: 5,
            width: '100%',
            flexDirection: 'row',
            justifyContent: "flex-start"
        },
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        }
    })
);





