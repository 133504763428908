import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  View,
  StyleSheet,
  SafeAreaView,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  FlatList,
  ScrollView
} from 'react-native'; 
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Color } from './../../../utils/color';
import MobileHeader from './../../../components/MobileHeader';
import MenuSettings from '../../../components/MenuSettings';
import SideMenu from './../../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../../components/Label';
import Button from './../../../components/button';
import { Constants } from './../../../utils/constants';


export default class InviteUsers extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      inviteUserData: [{
        "email": "",
        "fullUserName": "",
        "screen": [],
        "product": [],
        "location": [],
        "account": []
      }],
      isOpen: false,
      selectedIndex: -1,
      product: "",
      dropX: 0,
      dropY: 0,
      mainWidth: 0,
      mainHeight: 0,
      isWide: false,
      originalWidth: Dimensions.get("window").width,
      myWidth: Platform.select({
        web: Dimensions.get("window").width * 0.45,
        default: Dimensions.get("window").width,
      }),

      navWidth: Platform.select({
        web: Dimensions.get("window").width - 150,
        default: Dimensions.get("window").width - 30,
      }),
      isOpenProfile: false,
      fullName: "",

      arrProducts: [{
        "id": 0,
        "name": "All"
      }],
      arrLocation: [{
        "id": 0,
        "name": "All"
      }],
      arrAccount: [{
        "id": 0,
        "name": "All"
      }],
      arrScreen: [{
        "id": 0,
        "name": "All"
      }]
    }
  }

  async componentWillMount() {

    const userToken = await AsyncStorage.getItem('userToken');
    if (userToken == "null") {
      this.props.navigation.navigate('LoginScreen');
    }

    const fullName = await AsyncStorage.getItem('fullName');
    this.setState({
      fullName: fullName
    })

  }

  componentDidMount() {
    this.getlistDropdown()
    this.getScreenList()
  }

  _handleLayout = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;

    this.setState({
      originalWidth: width
    })


    if (width >= 767) {

      this.setState({
        navWidth:
          Platform.select({
            web: width - 150,
            default: Dimensions.get("window").width - 30,
          })
      })

    } else {
      this.setState({
        navWidth:
          Platform.select({
            web: width,
            default: Dimensions.get("window").width - 30,
          })
      })
    }




    const layout = nativeEvent.layout;

    this.setState({
      mainWidth: layout.width,
      mainHeight: layout.height,
    })


    if (width >= 767) {
      this.setState({
        myWidth: Platform.select({
          web: layout.width * 0.45,
          default: Dimensions.get("window").width,
        })

      })
    } else {
      this.setState({
        myWidth: Platform.select({
          web: layout.width,
          default: Dimensions.get("window").width,
        })
      })
    }

    this.setState(() => ({ isWide: width >= 767 }));

  };

  getScreenList = async () => {

    const userToken = await AsyncStorage.getItem('userToken');

    try {

      let url = `${Constants.BASE_URL}${Constants.INFO_REQUEST}`

      let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken
        },
      });
      let responseJson = await response.json();

      if (typeof responseJson != "undefined") {

        if (typeof responseJson.screens != "undefined") {
          var myArrayNew1 = responseJson.screens.filter(function (el) {
            return el.name != null && el.name != "";
          });

          this.setState({
            arrScreen: myArrayNew1
          })

          this.state.arrScreen.unshift({
            "id": 0,
            "name": "All"
          })
        }

      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }

    } catch (error) {
      console.error("====== error =====", error);
      if (Platform.OS === 'web') {
        alert(responseJson.message)
      } else {
        Alert.alert("CXSphere", responseJson.message)
      }
    }
  }



  getlistDropdown = async () => {

    const userToken = await AsyncStorage.getItem('userToken');
    const organizationID = await AsyncStorage.getItem('organizationID');


    try {

      let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/data`

      let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken
        },
      });
      let responseJson = await response.json();


      if (typeof responseJson != "undefined") {

        if (typeof responseJson.products != "undefined") {
          var myArrayNew1 = responseJson.products.filter(function (el) {
            return el.name != null && el.name != "";
          });

          this.setState({
            arrProducts: myArrayNew1
          })

          this.state.arrProducts.unshift({
            "id": 0,
            "name": "All"
          })
        }
        if (typeof responseJson.accounts != "undefined") {
          var myArrayNew2 = responseJson.accounts.filter(function (el) {
            return el.name != null && el.name != "";
          });

          this.setState({
            arrAccount: myArrayNew2
          })

          this.state.arrAccount.unshift({
            "id": 0,
            "name": "All"
          })

        }
        if (typeof responseJson.locations != "undefined") {
          var myArrayNew3 = responseJson.locations.filter(function (el) {
            return el.name != null && el.name != "";
          });
          this.setState({
            arrLocation: myArrayNew3
          })

          this.state.arrLocation.unshift({
            "id": 0,
            "name": "All"
          })
        }

      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }

    } catch (error) {
      console.error("====== error =====", error);
      if (Platform.OS === 'web') {
        alert(responseJson.message)
      } else {
        Alert.alert("CXSphere", responseJson.message)
      }
    }
  }



  inviteUser = async () => {

    // let messageAlert = this.validation()

    // if (messageAlert.length > 0) {
    //   const alertTitle = 'CXSphere'
    //   const alertText = messageAlert
    //   if (Platform.OS === 'web') {
    //     alert(alertText)
    //   } else {
    //     Alert.alert(alertTitle, alertText)
    //   }
    //   return;
    // }


    let bodyData = this.state.inviteUserData.map(item => {

      return {
        "email": item.email,
        "fullUserName": item.fullUserName,
        "screens": {
          "allowAll": item.screen.map(x => x.name).includes("All") ? true : false,
          "selection": item.screen.map(x => x.name).includes("All") ? [] : item.screen.map(x => x.id)
        },
        "accounts": {
          "allowAll": item.account.map(x => x.name).includes("All") ? true : false,
          "selection": item.account.map(x => x.name).includes("All") ? [] : item.account.map(x => x.id)
        },
        "products": {
          "allowAll": item.product.map(x => x.name).includes("All") ? true : false,
          "selection": item.product.map(x => x.name).includes("All") ? [] : item.product.map(x => x.id)
        },
        "locations": {
          "allowAll": item.location.map(x => x.name).includes("All") ? true : false,
          "selection": item.location.map(x => x.name).includes("All") ? [] : item.location.map(x => x.id)
        },
      }
    })

    const userToken = await AsyncStorage.getItem('userToken');
    const organizationID = await AsyncStorage.getItem('organizationID');



    try {

      let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/invite-users`

      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken
        },
        body: JSON.stringify(bodyData),
      });
      let responseJson = await response.json();

      // console.log("******** responseJson ********", responseJson)

      if (responseJson && responseJson.code == "Success") {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      } else {
        if (Platform.OS === 'web') {
          alert(responseJson.message)
        } else {
          Alert.alert("CXSphere", responseJson.message)
        }
      }
    } catch (error) {
      console.error("====== error =====", error);
      if (Platform.OS === 'web') {
        alert(responseJson.message)
      } else {
        Alert.alert("CXSphere", responseJson.message)
      }
    }

  }

  renderSelectedItemCompoentScreen = (item, index, product) => {
    return (
      <View style={{ width: ((this.state.myWidth - 70) * 0.65) * 0.25, marginLeft: 8 }} key={index}>
        <TouchableOpacity
          onPress={(evt) => {

            this.setState({
              isOpen: true,
              selectedIndex: index,
              product: product,
            })

            // console.log("selectedIndex And Products", index, product);

            if ((evt.nativeEvent.pageX + (((this.state.myWidth - 70) * 0.65) * 0.25) + 40 > this.state.mainWidth) && evt.nativeEvent.pageY > 600) {

              this.setState({
                dropX: evt.nativeEvent.pageX - evt.nativeEvent.locationX - 60,
                dropY: evt.nativeEvent.pageY - evt.nativeEvent.locationY - 80

              })
            }
            else if (evt.nativeEvent.pageX + (((this.state.myWidth - 70) * 0.65) * 0.25) + 40 > this.state.mainWidth) {

              this.setState({
                dropX: evt.nativeEvent.pageX - evt.nativeEvent.locationX - 60,
                dropY: evt.nativeEvent.pageY - evt.nativeEvent.locationY + 35

              })
            }
            else if (evt.nativeEvent.pageY > 600) {

              this.setState({
                dropX: evt.nativeEvent.pageX - evt.nativeEvent.locationX,
                dropY: evt.nativeEvent.pageY - evt.nativeEvent.locationY - 80

              })
            }
            else {

              this.setState({
                dropX: evt.nativeEvent.pageX - evt.nativeEvent.locationX,
                dropY: evt.nativeEvent.pageY - evt.nativeEvent.locationY + 35

              })

            }

          }} style={[styles.btnSelect, { width: ((this.state.myWidth - 70) * 0.65) * 0.25, outline: "none" }]}>
          <Label
            numberOfLines={1}
            sigleLine={true}
            style={{
              borderWidth: 1, borderColor: Color.BORDERCOLOR, borderRadius: 3, paddingTop: 5, paddingBottom: 5,
              width: ((this.state.myWidth - 70) * 0.65) * 0.25,
              textAlign: 'center'
            }} font14Normal Barlow_Regular color={Color.DarkGrey}>{item.length > 0 ? item[0].name : "Select"}</Label>
        </TouchableOpacity>

      </View>
    )
  }

  renderItem = ({ item, index }) => {
    return (
      <View style={[styles.listRow, { width: this.state.myWidth - 30 }]}>

        <TextInput
          style={[styles.inviteInput, { width: 100, height: 30, outline: "none", borderRadius: 4, borderWidth: 1, borderColor: Color.BORDERCOLOR, marginLeft: 5, fontSize: 12 }]}
          placeholder={"Name"}
          placeholderTextColor={'gray'}
          onChangeText={(text) => {
            // console.log("Text", text);
            this.state.inviteUserData[index].fullUserName = text
            // console.log("inviteUserData", this.state.inviteUserData);
          }}
          autoCapitalize="none"
        />

      <TextInput
          style={[styles.inviteInput, { width: 100, height: 30, outline: "none", borderRadius: 4, borderWidth: 1, borderColor: Color.BORDERCOLOR, marginLeft: 5, fontSize: 12 }]}
          placeholder={'User email'}
          placeholderTextColor={'gray'}
          onChangeText={(text) => {
            // console.log("Text", text);
            this.state.inviteUserData[index].email = text
            // console.log("inviteUserData", this.state.inviteUserData);
          }}
          autoCapitalize="none"
        />

        {this.renderSelectedItemCompoentScreen(item.screen, index, "screen")}
        {this.renderSelectedItemCompoentScreen(item.product, index, "product")}
        {this.renderSelectedItemCompoentScreen(item.location, index, "location")}
        {this.renderSelectedItemCompoentScreen(item.account, index, "account")}

      </View>
    );
  }

  applyAllPermission = () => {
    console.log("");
  }

  render() {

    var nameC = ""
    if (this.state.fullName != "" && this.state.fullName != null) {
      const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
      nameC = allCharacters.substring(0, 2)
    }

    let listArray = []

    if (this.state.product == "screen") {
      listArray = this.state.arrScreen;
    } else if (this.state.product == "product") {
      listArray = this.state.arrProducts;
    }
    else if (this.state.product == "location") {
      listArray = this.state.arrLocation;
    }
    else if (this.state.product == "account") {
      listArray = this.state.arrAccount;
    }

    return (
      <View onLayout={this._handleLayout} style={styles.Container}>
        {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="InviteUsers" />}
        <View style={{ flexDirection: 'row', flex: 1, justifyContent: "center", backgroundColor: Color.WhiteLight }}>
          {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
            <SideMenu {...this.props} />
          </View>}
          <View style={[styles.containBox, { width: this.state.navWidth }]}>
            {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, { width: this.state.navWidth, justifyContent: "center", alignItems: 'center' }]}>
              <Label Barlow_SemiBold style={[styles.title, {
                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center', alignSelf: 'center',
              }]}>InviteUsers</Label>
              <TouchableOpacity onPress={() => {
                this.setState({
                  isOpenProfile: true
                })
              }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("../../../assets/images/small_down.png")} />
                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("../../../assets/images/profile_img.png")} /> */}
                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                  <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                </View>
              </TouchableOpacity>
            </View>}

            <MenuSettings 
                {...this.props} 
                isOpen={this.state.isOpenProfile} 
                myWidth={this.state.navWidth} 
                onClick={() =>this.setState({ isOpenProfile: false})} 
            />
            <View style={[styles.boxRow, {
              width: this.state.navWidth
            }]}>

              <View style={[styles.boxContainer, { alignSelf: 'center' }]}>


                <TouchableOpacity onPress={this.applyAllPermission} style={[styles.titleBoxUserAll, {
                  width: Platform.select({
                    web: this.state.myWidth - 30,
                    default: Dimensions.get("window").width - 30,
                  }),
                  alignSelf: 'flex-start'
                }]}>
                  <View style={styles.circle}></View>
                  <Label Barlow_SemiBold color={Color.DarkGrey} style={styles.font18}>Apply first user role & permission to all</Label>
                </TouchableOpacity>


                <Label style={styles.usersAllowTitle} font14Normal Barlow_SemiBold color={Color.DarkGreen}>Allow access to users</Label>
                <View style={{ flexDirection: 'row', width: this.state.myWidth - 30}}>
                {/* <View style={[styles.listHeadingEmail, { width: (this.state.myWidth - 70) * 0.09 }]}><Label font14Normal Barlow_Regular>Name</Label></View>
                  <View style={[styles.listHeadingEmail, { width: (this.state.myWidth - 70) * 0.25 }]}><Label font14Normal Barlow_Regular>Email Address</Label></View> */}
                  <View style={[styles.listHeading, { width: ((this.state.myWidth - 70) * 0.65) * 0.25, marginLeft: 200 }]}><Label sigleLine={true} numberOfLines={1} style={{ textAlign: "center" }} font14Normal Barlow_Regular>Screen</Label></View>
                  <View style={[styles.listHeading, { width: ((this.state.myWidth - 70) * 0.65) * 0.25 }]}><Label sigleLine={true} numberOfLines={1} style={{ textAlign: "center" }} font14Normal Barlow_Regular>Product</Label></View>
                  <View style={[styles.listHeading, { width: ((this.state.myWidth - 70) * 0.65) * 0.25 }]}><Label sigleLine={true} numberOfLines={1} style={{ textAlign: "center" }} font14Normal Barlow_Regular>Location</Label></View>
                  <View style={[styles.listHeading, { width: ((this.state.myWidth - 70) * 0.65) * 0.25 }]}><Label sigleLine={true} numberOfLines={1} style={{ textAlign: "center" }} font14Normal Barlow_Regular>Account</Label></View>
                </View>

                <View>
                  <FlatList
                    //no styling , height fits to item and increases as items populate
                    inverted={true}
                    showsVerticalScrollIndicator={true}
                    data={this.state.inviteUserData}
                    extraData={this.state}
                    renderItem={this.renderItem}
                    style={{maxHeight: 400}}
                  />
                </View>


                <View style={styles.InviteBtn}>
                  <Button btnStyle={{ width: 140, marginRight: 5 }} onClick={() => {
                    this.state.inviteUserData.push({
                      "email": "",
                      "screen": [],
                      "product": [],
                      "location": [],
                      "account": []
                    })
                    this.setState({
                    })
                  }}
                    backgroundColor={Color.DarkGreen}
                    titleColor={Color.White}
                    txtStyle={styles.InviteTxt} title="Add">
                  </Button>

                  <Button btnStyle={{ width: 140, marginLeft: 5 }} onClick={() => {
                    this.inviteUser()
                  }}
                    backgroundColor={Color.DarkGreen}
                    titleColor={Color.White}
                    txtStyle={styles.InviteTxt} title="Invite">
                  </Button>

                </View>
              </View>
            </View>
          </View>
        </View>

        {
          this.state.isOpen && <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              this.setState({
                isOpen: false
              })
            }}
            style={{
              height: Dimensions.get("window").height,
              width: Dimensions.get("window").width,
              backgroundColor: 'transparent',
              position: "absolute"
            }}>

            <ScrollView style={{
              borderRadius: 4,
              backgroundColor: Color.WHITE,
              shadowColor: 'black',
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 2,
              elevation: 2,
              position: "absolute",
              width: 120,
              height: 120,
              top: this.state.dropY,
              left: this.state.dropX
            }}>

              {listArray.map(item => {

                let isSelected = this.state.inviteUserData[this.state.selectedIndex][this.state.product].filter(item1 => {
                  return item1.id == item.id
                }).length > 0

                return (
                  <TouchableOpacity
                    activeOpacity={1}
                    onPress={() => {
                      // this.state.inviteUserData[this.state.selectedIndex][this.state.product] = (item == "All" ? item : item.name)
                      // this.setState({
                      //   isOpen: false
                      // })

                      if (isSelected) {
                        let filteredArray = this.state.inviteUserData[this.state.selectedIndex][this.state.product].filter(item1 => {
                          return item1.id != item.id
                        })

                        let isAllSelected = this.state.inviteUserData[this.state.selectedIndex][this.state.product].filter(item1 => {
                          return item1.name == "All"
                        }).length > 0

                        if (item.name == "All") {
                          this.state.inviteUserData[this.state.selectedIndex][this.state.product] = []
                        } else {
                          if (isAllSelected) {
                            filteredArray.shift()
                            this.state.inviteUserData[this.state.selectedIndex][this.state.product] = filteredArray
                          } else {
                            this.state.inviteUserData[this.state.selectedIndex][this.state.product] = filteredArray
                          }
                        }
                      } else {

                        if (item.name == "All") {
                          this.state.inviteUserData[this.state.selectedIndex][this.state.product] = listArray
                        } else {
                          this.state.inviteUserData[this.state.selectedIndex][this.state.product].push(item)
                          if (this.state.inviteUserData[this.state.selectedIndex][this.state.product].length == listArray.length - 1) {
                            this.state.inviteUserData[this.state.selectedIndex][this.state.product] = listArray
                          }
                        }
                      }
                      this.setState({})
                    }}
                    style={{ backgroundColor: "white", flexDirection: "row", alignItems: "center" }}>
                    <Image style={{ height: 15, width: 15, marginLeft: 5 }} source={isSelected ? require("../../../assets/images/checked.png") : require("../../../assets/images/unchecked.png")} />
                    <Label style={{ padding: 5, width: 85 }} font14Normal Barlow_Regular color={Color.Black}>{item == "All" ? item : item.name}</Label>
                  </TouchableOpacity>
                )
              })}

            </ScrollView>

          </TouchableOpacity>
        }
      </View >

    )
  }
}

InviteUsers.navigationOptions = {
  header: null,
};
const base = StyleSheet.create({
  Container: {
    flex: 1,
  },
  boxContainer: {
    alignItems: 'center',
  },
  titleBoxUserAll: {
    backgroundColor: Color.White,
    paddingTop: 18,
    paddingBottom: 18,
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  logoDesktop: {
    backgroundColor: Color.White,
    height: 60,
    borderBottomWidth: 1,
    borderColor: "#005057",
  },
  font18: {
    fontSize: 18
  },
  listRow: {
    flexDirection: 'row',
    backgroundColor: "white",
    height: 50,
    borderRadius: 3,
    alignItems: "center",
    marginTop: 8
  },
  title: {
    fontSize: 30,
    color: Color.DarkGreen,
    marginTop: 0,
    marginBottom: 0,
  },
  boxRow: {
    width: Dimensions.get("window").width,
  },
  sideBerLeft: {
    width: 230,
  },
  containBox: {
    backgroundColor: Color.WhiteLight
  },
  circle: {
    width: 12,
    height: 12,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: Color.BorderColor,
    marginLeft: 25,
    marginRight: 10
  },
  usersAllowTitle: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    alignSelf: "flex-start"
  },
  listHeadingEmail: {
    color: Color.DarkGrey,
    paddingTop: 10,
    paddingBottom: 10,
  },
  listHeading: {
    color: Color.DarkGrey,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 8
  },
  ScrollViewMain: {
    height: Dimensions.get("window").height - 500,
  },
  InviteBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 50,
  },
  inviteInput: {
    fontSize: 18,
    paddingLeft: 5,
    marginBottom: 0,
    height: 40,
    borderRadius: 0,
    borderWidth: 0,
    color: 'black',
    marginLeft: 5
  },
  btnSelect: {
    height: 40,
    borderRadius: 3,
    backgroundColor: "white",
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileDropdown: {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    elevation: 1,
    width: 155,
    position: 'absolute',
    right: 20,
    top: 80,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    zIndex: 999999,
    flex: 1,
  },
  JohnTitle: {
    fontSize: 14,
    color: "#4D4F5C"
  },

});
const styles = createStyles(
  base,
  maxWidth(767, {
    boxRow: {
      alignItems: 'center',
      alignSelf: 'center'
    },
    InviteBtn: {
      alignSelf: 'center',
      justifyContent: 'space-between',
      paddingLeft: 0,
      flexDirection: 'row'
    },
    btnSelect: {
      height: 40,
      borderRadius: 3,
      backgroundColor: "white",
      alignItems: 'center',
      justifyContent: 'center'
    },
    usersAllowTitle: {
      fontSize: 12,
      alignSelf: "flex-start",
      marginBottom: 10,
      marginTop: 10,
    },
    font18: {
      fontSize: 14
    },
    circle: {
      width: 10,
      height: 10,
      borderWidth: 1,
      borderRadius: 6,
      borderColor: Color.BorderColor,
      marginLeft: 15,
      marginRight: 10
    },
    inviteInput: {
      fontSize: 11,
      color: Color.White,
      paddingLeft: 5,
      marginBottom: 0,
      height: 40,
      borderRadius: 0,
      borderWidth: 0,
      marginRight: 0,
      marginLeft: 0,
      color: 'black'
    },
    listRow: {
      flexDirection: 'row',
      height: 50,
      backgroundColor: "white",
      alignItems: "center",
      marginTop: 8
    },
    ScrollViewMain: {
      height: Dimensions.get("window").height - 400,
    },
    titleBoxUserAll: {
      backgroundColor: Color.White,
      paddingTop: 18,
      paddingBottom: 18,
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 20,
      marginTop: 20,
    },
    listHeading: {
      color: Color.DarkGrey,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 8

    },
    listHeadingEmail: {
      color: Color.DarkGrey,
      paddingTop: 10,
      paddingBottom: 10,
    },
    Container: {
      flex: 1,
    },
    containBox: {
      width: Dimensions.get("window").width,
      backgroundColor: Color.WhiteLight

    }
  }),
  minWidth(768, {
    sideBerLeft: {
      height: '100vh'
    },
  })
);

